export default {
  submit: 'Жіберу',
  email: 'Эл. пошта',
  password: 'Құпия сөз',
  title: 'Тақырып',
  workingArea: 'Жұмыс алауы',
  country: 'Ел',
  legalAddress: 'Қанағаттандыру мекен-жайы',
  bin: 'БИН',
  website: 'Веб-сайт',
  phoneNumber: 'Телефон нөмірі',
  fullName: 'Толық аты-жөні',
  position: 'Мекенжай',
  firstName: 'Аты',
  lastName: 'Тегі',
  patronymic: 'Әкесінің аты',
  confirmPassword: 'Растау',
  newPassword: 'Жаңа құпия сөз',
  createAccount: 'Аккаунт құру',
  forgotPassword: 'Құпия сөзді ұмыттым',
  city: 'Қала',
  description: 'Сипаттама',
  category: 'Санат',
  subcategory: 'Байланыстырылған санат',
  region: 'Аймақ',
  industry: 'Салалық',
  showOtherCompanies: 'Менің аймағымда жоқ компанияларды көрсету',
  companyName: 'Компания аты',
  cooperationPurpose: 'Кооперация мақсаты',
  offer: 'Тексеру',
  expectedBenefits: 'Құқықтарды күтімі',
  searchByIdTitle: 'Тауар атауы, ID немесе артикул бойынша іздеу',
  selectColumnName: 'Бағандықтың атауын таңдау',
  positionName: 'Позиция атауы',
  width: 'Ұзындығы, {{unit}}',
  height: 'Биіктігі, {{unit}}',
  length: 'Ұзақтығы, {{unit}}',
  weight: 'Салмағы, {{unit}}',
  orderFrom: 'Тапсырыс беру кезінде',
  keyPhrases: 'Алып тастайтын алғашқы сөздерді енгізіңіз, сатып алушылар тауарыңызды іздесе',
  contactPerson: 'Байланыс адамы',
  code: 'Код',
  street: 'Көше',
  writeMessage: 'Хабарлама жазыңыз',
  branchType: 'Ұйым түрі',
};
