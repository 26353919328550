import { combineReducers, configureStore } from '@reduxjs/toolkit';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';

import { authReducer } from './auth';
import { registrationReducer } from './registration';
import { settingsReducer } from './settings';

const persistedAuthReducer = persistReducer(
  {
    key: 'auth',
    storage,
    whitelist: ['authToken'],
  },
  authReducer
);

const persistedSettingsReducer = persistReducer(
  {
    key: 'settings',
    storage,
    blacklist: ['mobileNavbar'],
  },
  settingsReducer
);

const rootReducer = combineReducers({
  auth: persistedAuthReducer,
  registration: registrationReducer,
  settings: persistedSettingsReducer,
});

export const setupStore = () => {
  return configureStore({
    reducer: rootReducer,
    middleware: (getDefaultMiddleware) =>
      getDefaultMiddleware({
        serializableCheck: false,
      }),
  });
};

export type RootState = ReturnType<typeof rootReducer>;
export type AppStore = ReturnType<typeof setupStore>;
export type AppDispatch = AppStore['dispatch'];
