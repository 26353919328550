import React from 'react';

import cl from './404.module.scss';

const NotFound = () => {
  return (
    <div className={cl['not-found']}>
      <p className={cl['not-found__number']}>404</p>
      <p className={cl['not-found__text']}>Не найдено</p>
    </div>
  );
};

export default NotFound;
