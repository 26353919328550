import React from 'react';
import { useTranslation } from 'react-i18next';
import { ReactComponent as HomeIcon } from 'assets/icons/home-full.svg';
import { ReactComponent as PinIcon } from 'assets/icons/pin.svg';
import { ReactComponent as UserIcon } from 'assets/icons/user.svg';
import PRButton from 'components/PRButton';
import PRTitle from 'components/PRTitle';

import cl from './cooperation.module.scss';

interface Props {
  cooperation: string | null;
  onSelect: (val: string) => void;
  onNext: () => void;
}

const BecomePartnerCooperation: React.FC<Props> = ({ cooperation, onSelect, onNext }) => {
  const { t } = useTranslation();

  const onSubmit = () => {
    if (!cooperation) return;
    onNext();
  };

  return (
    <div className={cl.cooperation}>
      <label
        className={`${cl['cooperation__item']} ${
          cooperation === 'tradingAgency' ? cl['active'] : ''
        }`}
      >
        <div className={cl['cooperation__image']}>
          <UserIcon />
        </div>
        <div className={cl['cooperation__body']}>
          <PRTitle tag="h3">{t('common.tradingAgency')}</PRTitle>
          <p>{t('message.tradingAgency')}</p>
        </div>
        <input
          name="cooperation"
          type="radio"
          value="tradingAgency"
          onChange={(e) => onSelect(e.target.value)}
        />
      </label>

      <label
        className={`${cl['cooperation__item']} ${
          cooperation === 'saleOfPoint' ? cl['active'] : ''
        }`}
      >
        <div className={cl['cooperation__image']}>
          <PinIcon />
        </div>
        <div className={cl['cooperation__body']}>
          <PRTitle tag="h3">{t('common.saleOfPoint')}</PRTitle>
          <p>{t('message.saleOfPoint')}</p>
        </div>
        <input
          name="cooperation"
          type="radio"
          value="saleOfPoint"
          onChange={(e) => onSelect(e.target.value)}
        />
      </label>

      <label
        className={`${cl['cooperation__item']} ${
          cooperation === 'tradingHouse' ? cl['active'] : ''
        }`}
      >
        <div className={cl['cooperation__image']}>
          <HomeIcon />
        </div>
        <div className={cl['cooperation__body']}>
          <PRTitle tag="h3">{t('common.tradingHouse')}</PRTitle>
          <p>{t('message.tradingHouse')}</p>
        </div>
        <input
          name="cooperation"
          type="radio"
          value="tradingHouse"
          onChange={(e) => onSelect(e.target.value)}
        />
      </label>

      <div className={cl['cooperation__button']}>
        <PRButton onClick={onSubmit}>{t('action.next')}</PRButton>
      </div>
    </div>
  );
};

export default BecomePartnerCooperation;
