import React from 'react';
import { useTranslation } from 'react-i18next';
import { ReactComponent as PenIcon } from 'assets/icons/pen.svg';
import PRButton from 'components/PRButton';

import { BecomePartnerForm } from '../../dto/partner-form.dto';

import cl from './third.module.scss';

interface Props {
  form: BecomePartnerForm;
  onSubmit: () => void;
  onEdit: () => void;
}

const BecomePartnerThird: React.FC<Props> = ({ form, onEdit, onSubmit }) => {
  const { t } = useTranslation();

  return (
    <div className={cl['third']}>
      <div className={cl['third__two-col']}>
        <div className={cl['third__item']}>
          <p className={cl['third__label']}>{t('form.fullName')}</p>
          <p className={cl['third__text']}>{form.fullName}</p>
        </div>
        <div className={cl['third__item']}>
          <p className={cl['third__label']}>{t('form.companyName')}</p>
          <p className={cl['third__text']}>{form.companyName}</p>
        </div>
      </div>

      <div className={cl['third__item']}>
        <p className={cl['third__label']}>{t('form.workingArea')}</p>
        <p className={cl['third__text']}>{form.workingArea?.label}</p>
      </div>

      <div className={cl['third__three-col']}>
        <div className={cl['third__item']}>
          <p className={cl['third__label']}>{t('form.email')}</p>
          <p className={cl['third__text']}>{form.email}</p>
        </div>
        <div className={cl['third__item']}>
          <p className={cl['third__label']}>{t('form.website')}</p>
          <p className={cl['third__text']}>{form.website}</p>
        </div>
        <div className={cl['third__item']}>
          <p className={cl['third__label']}>{t('form.phoneNumber')}</p>
          <p className={cl['third__text']}>{form.phone}</p>
        </div>
      </div>

      <div className={cl['third__item']}>
        <p className={cl['third__label']}>{t('form.cooperationPurpose')}</p>
        <p className={cl['third__text']}>{form.purpose?.map((p) => p.label).join(', ')}</p>
      </div>

      <div className={cl['third__item']}>
        <p className={cl['third__label']}>{t('form.offer')}</p>
        <p className={cl['third__text']}>{form.offer}</p>
      </div>

      <div className={cl['third__item']}>
        <p className={cl['third__label']}>{t('form.expectedBenefits')}</p>
        <p className={cl['third__text']}>{form.benefits}</p>
      </div>

      {form.files.length ? (
        <div className={cl['third__files']}>
          {form.files.map((f) => (
            <p className={cl['third__file']} key={f.lastModified}>
              {f.name}
            </p>
          ))}
        </div>
      ) : null}

      <div className={cl['third__footer']}>
        <PRButton type="button" onClick={onSubmit}>
          {t('action.sendRequest')}
        </PRButton>
        <PRButton
          variant="text"
          type="button"
          prefix={<PenIcon className={cl['third__pen-icon']} />}
          onClick={onEdit}
        >
          {t('action.edit')}
        </PRButton>
      </div>
    </div>
  );
};

export default BecomePartnerThird;
