export default {
  change: 'Değiştir',
  delete: 'Sil',
  find: 'Bul',
  reset: 'Sıfırla',
  save: 'Kaydet',
  edit: 'Düzenle',
  cancel: 'İptal',
  accept: 'Kabul Et',
  login: 'Giriş Yap',
  logout: 'Çıkış Yap',
  signup: 'Hesap Oluştur',
  signin: 'Oturum Aç',
  next: 'İleri',
  seekManufacturer: 'Üretici Arıyorum',
  seekProduct: 'Ürün Arıyorum',
  back: 'Geri',
  forward: 'İleri',
  becomeAPartner: 'Ortak Ol',
  contactWithManufacturer: 'Üretici ile İletişime Geç',
  uploadFile: 'Dosya Yükle',
  sendRequest: 'Talep Gönder',
  importProducts: 'Ürünleri İçe Aktar',
  addProduct: 'Ürün Ekle',
  continue: 'Devam Et',
  addPrice: 'Fiyat Ekle',
  removePrice: 'Fiyatı Kaldır',
  saveChanges: 'Değişiklikleri Kaydet',
  leaveAsDraft: 'Taslak Olarak Kaydet',
  close: 'Kapat',
  ready: 'Hazır',
  addBranches: 'Şubeler Ekle',
  resendActivationCode: 'Aktivasyon Kodunu Tekrar Gönder',
  sendForVerification: 'Doğrulama İçin Gönder',
  deleteAccount: 'Hesabı Sil',
  changePassword: 'Parolayı Değiştir',
  hide: 'Gizle',
  expand: 'Genişlet',
  orderProduct: 'Ürün Siparişi Ver',
  writeToManufacturer: 'Üreticiye Yaz',
  goToCatalog: 'Kataloga Git',
  confirm: 'Onayla',
  yes: 'Evet',
  no: 'Hayır',
  selectChat: 'Sohbet Seç',
  signIn: 'Oturum Aç',
  recover: 'Kurtar',
  create: 'Oluştur',
  uploadNewPhoto: 'Yeni Fotoğraf Yükle',
  verifyCompany: 'Şirketi Doğrula',
};
