export default {
  submit: 'Submit',
  email: 'Email',
  password: 'Password',
  title: 'Title',
  workingArea: 'Business Activity',
  country: 'Country',
  legalAddress: 'Legal Address',
  bin: 'BIN (Business Identification Number)',
  website: 'Website',
  phoneNumber: 'Phone Number',
  fullName: 'Full Name',
  position: 'Position',
  firstName: 'First Name',
  lastName: 'Last Name',
  patronymic: 'Patronymic',
  confirmPassword: 'Confirm Password',
  newPassword: 'New Password',
  createAccount: 'Create Account',
  forgotPassword: 'Forgot Password',
  city: 'City',
  description: 'Description',
  category: 'Category',
  subcategory: 'Subcategory',
  region: 'Region',
  industry: 'Industry',
  showOtherCompanies: 'Show companies not in my region',
  companyName: 'Company Name',
  cooperationPurpose: 'Purpose of Cooperation',
  offer: 'Offer',
  expectedBenefits: 'Expected Benefits',
  searchByIdTitle: 'Search by Name, ID, or Product Code',
  selectColumnName: 'Select Column Name',
  positionName: 'Position Name',
  width: 'Width, {{unit}}',
  height: 'Height, {{unit}}',
  length: 'Length, {{unit}}',
  weight: 'Weight, {{unit}}',
  orderFrom: 'Order From',
  keyPhrases: 'Enter key phrases for buyers to find your product',
  contactPerson: 'Contact Person',
  code: 'Code',
  street: 'Street',
  writeMessage: 'Enter your message',
  branchType: 'Organization Type',
};
