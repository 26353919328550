import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export const settingsSlice = createSlice({
  name: 'settings',
  initialState: {
    lang: 'ru',
    miniNavbar: false,
    mobileNavbar: false,
  },
  reducers: {
    toggleNavbar: (state) => {
      state.miniNavbar = !state.miniNavbar;
    },
    toggleMobileNavbar: (state) => {
      state.mobileNavbar = !state.mobileNavbar;
    },
    setCurrentLang: (state, action: PayloadAction<string>) => {
      state.lang = action.payload;
    },
  },
});

export const settingsReducer = settingsSlice.reducer;
