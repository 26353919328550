import React, { useEffect, useState } from 'react';
import PRPagination from 'components/PRPagination';
import PRProduct from 'components/PRProduct';
import { fetchProducts } from 'api/repositories/products.repository';
import { Product as ProductEntity } from 'entities/Product';

import { FilterParamsDto } from '../../dto/filter-params.dto';

import cl from './product.module.scss';

interface Props {
  params: FilterParamsDto;
  onPageChange: (page: number) => void;
}

const Product: React.FC<Props> = ({ params, onPageChange }) => {
  const [products, setProducts] = useState<ProductEntity[]>([]);
  const [lastPage, setLastPage] = useState(1);

  useEffect(() => {
    fetchProducts(params.page, {
      ...(params.region && {
        manufacturer: {
          countryId: params.region,
        },
      }),
      ...(params.area && { industryId: params.area }),
      ...(params.category && { categoryId: params.category }),
      ...(params.subcategory && { subcategoryId: params.subcategory }),
      ...(params.name && { name: params.name }),
      ...(params.trade && { trade: params.trade }),
    }).then((res) => {
      setProducts(res.products);
      setLastPage(res.meta.lastPage);
    });
  }, [params]);

  const toggleBookmark = (id: number) => {
    const updatedProducts = products.map((p) => {
      if (p.id === id) {
        p.bookmarked = !p.bookmarked;
      }
      return p;
    });
    setProducts(updatedProducts);
  };

  return (
    <div className={cl.product}>
      <div className={cl['product__list']}>
        {products.map((product) => (
          <PRProduct key={product.id} product={product} toggleBookmark={toggleBookmark} />
        ))}
      </div>

      <PRPagination current={params.page} lastPage={lastPage} onChange={onPageChange} />
    </div>
  );
};

export default Product;
