export default {
  signTo: 'Iniciar sesión',
  signUp: 'Registrarse',
  passwordHelper: 'Mínimo 8 caracteres, 1 mayúscula, 1 número.',
  lastUpdate: 'Última actualización: {{date}}',
  tradingAgency:
    'Una agencia de comercio que asume el compromiso de establecer la distribución en una región específica según reglas predefinidas.',
  saleOfPoint:
    'Venta local de productos, presencia territorial del producto en un vendedor específico.',
  tradingHouse:
    'Casa de comercio, empresa autorizada por el fabricante para representar y establecer reglas de distribución en una región específica.',
  uploadPresentationOrFile:
    'Si es necesario, cargue documentos adicionales, presentaciones o archivos.',
  usingForSEO:
    'Se utilizan para SEO y ayudan a aumentar la visibilidad del producto en los motores de búsqueda.',
  noBranchesYet: 'Todavía no tienes sucursales agregadas.',
  branchBenefits:
    'La lista de sucursales ayuda a construir la confianza y mejorar la visibilidad en el sitio web, y también permite a otras empresas ver y ponerse en contacto con las sucursales.',
  activationCodeSend:
    'Se ha enviado un código a su correo electrónico. Para completar el registro, ingrese el código en el campo.',
  resendCodeAfter: 'Reenviar el código después de {{seconds}} segundos',
  codeSent: 'Código enviado',
  allDataWillBeDeleted: 'Todos sus datos se eliminarán junto con su cuenta.',
  changePasswordImproveAccount: 'Cambiar la contraseña para mejorar la seguridad de la cuenta.',
  writeEmailForRecovery: 'Por favor, ingrese el correo electrónico asociado a su cuenta.',
  codeForRecovery:
    'Se ha enviado un código para restablecer la contraseña a su correo electrónico.',
  enterCodeToAccessAccount: 'Ingrese el código a continuación para acceder a su cuenta.',
  createStrongPassword: 'Cree una contraseña segura para su cuenta.',
  infoAboutYourCompany: 'Proporcione información sobre su empresa.',
  iWantCatalogAndMakeSale: 'Quiero listar y vender productos en el catálogo.',
  iWantSearchManufacturers: 'Estoy buscando fabricantes para establecer asociaciones.',
  tradingAgencyDescription:
    'Las agencias de comercio son organizaciones que representan los intereses y productos de otras compañías en una región específica.',
  tradingHouseDescription:
    'Las casas de comercio son grandes organizaciones comerciales que agrupan varias tiendas o puntos de venta bajo una única empresa de gestión.',
  tradingPointDescription:
    'Los puntos de venta son lugares donde los productos o servicios se venden directamente a los consumidores.',
};
