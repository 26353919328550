export default {
  main: 'Башкы',
  loading: 'Жүктөлүүдө...',
  listEmpty: 'Тизмеси бош',
  attention: 'Экинчи',
  services: 'Услугалар',
  applications: 'Өтүмдөр',
  partnership: 'Биргелештируу',
  branches: 'Филиалдар',
  catalog: 'Каталог',
  faq: 'Кыймыл алгылар',
  companyData: 'Компаниянын маалыматтары',
  employeeData: 'Агент маалыматтары',
  accountActivation: 'Аккаунтты активдөө',
  aboutPlatform: 'Платформа жөнүндө',
  manageCompany: 'Компанияны башкаруу',
  tradingAgency: 'Сатуу агенттиги',
  saleOfPoint: 'Сатылыш нүктеси',
  tradingHouse: 'Сатуу ий',
  manufacturerName: 'Иске үйрөтүчүнүн аты',
  productName: 'Товардын аты',
  priceFrom: '{{from}} дайындоо баасы',
  aboutManufacturer: 'Иске үйрөтүчү жөнүндө',
  contacts: 'Байланыш',
  manufacturers: 'Иске үйрөтүчүлөр',
  summary: 'Башкаруу жөнүндө',
  certificates: 'Сертификаттар',
  yearsAgo: '{{num}} жыл мурун',
  title: 'Аталышы',
  brandName: 'Бренд аты',
  legalAddress: 'Жасалуу дареги',
  jurisdiction: 'Журисдикция',
  regNumberBin: 'Бүтүтүлгөн номери',
  registrationDate: 'Тизмелетүү күнү',
  sinceOnSite: 'Сайтта сакталган убакыты',
  product: 'Товар',
  vendorCode: 'Артикул',
  date: 'Күнү',
  display: 'Көрсөтүү',
  inStock: 'Сатуучу бар',
  actions: 'Өтүмдөр',
  required: 'Керек',
  optional: 'Опциялуу',
  productList: 'Товар тизмеси',
  barcode: 'Баркод же UPC/EAN',
  color: 'Түс',
  material: 'Материал',
  currency: 'Валюта',
  unitType: 'Өлчөм бирими',
  status: 'Статус',
  wholesalePrice: 'Опт сатуу баасы',
  from: 'кайтуу',
  mainInfo: 'Башкаруу маалымат',
  productDescription: 'Товардын тайырыбы',
  dimensions: 'Өлчөмдөр',
  characteristics: 'Карактеристикалар',
  price: 'Баа',
  createAndUpdate: 'Түзүү жана жаңыртуу',
  created: 'Түзүлдү',
  updated: 'Жаңыртылды',
  searchTerms: 'Издөө сөздөрү',
  supportedFormats: 'Колдонуу жактыруу форматтары: {{formats}}',
  maxSize: 'Максималдуу көлөм: {{size}}',
  images: 'Сүрөттөр',
  agency: 'Агенттик',
  password: 'Сыр сөз',
  addingBranch: 'Филиал кошуу',
  questionAndAnswer: 'Суроо жана жооптар',
  profile: 'Профиль',
  settings: 'Орнотуулар',
  requirementsForVerification: 'Тексерүү үчүн талаптар',
  submitFollowingDocuments: 'Алдын ала, көрсөткөн документтерди тапшырыңыз',
  companyRegCertificate: 'Компаниянын тизмеси',
  companyCharter: 'Компаниянын эрежелери',
  certificateTaxReg: 'Салыктардын тизмеси',
  productionDoc: 'Өндөрүү тууралуу документтер',
  qualityCertificate: 'Сапат жана мунукалептүү сертификаттары',
  licensesAndCertificates: 'Лицензиялар жана сертификаттар',
  suppliersAndCustomersContracts: 'Тапшырычылар менен клиенттердеги келишимдер',
  accountDeleting: 'Аккаунтты өчүрүү',
  passwordChanging: 'Сыр сөздү өзгөртүү',
  today: 'бүгүн',
  weight: 'Салуу',
  availableOnOrder: 'Товар заказ аркылуу жеткирилет',
  manufacturerOtherProducts: 'Иске үйрөтүчүнүн башка товарлары',
  lastActivity: 'Активдүү соңку',
  passwordRecovery: 'Сыр сөздү кайра келтирүү',
  forgotPassword: 'Сыр сөздү унуттуңузбу?',
  haveNotAccount: 'Аккаунтуңуз жокпу?',
  createPassword: 'Сыр сөз түзүңүз',
  registration: 'Тизме',
  haveAnAccount: 'Аккаунтуңуз барбу?',
  iAmManufacturer: 'Мен иске үйрөтүчүм',
  iAmUser: 'Мен пайдалануучум',
  aboutCompany: 'Компания жөнүндө',
  logo: 'Логотип',
  personalData: 'Жеке маалымат',
  notifications: 'Билдирүүлөр',
  todayAt: 'Бүгүн {{date}} күнү',
  yesterdayAt: 'Кече {{date}} күнү',
  all: 'Бардыгы',
  system: 'Система',
  notSpecified: 'Аныкталган жок',
};
