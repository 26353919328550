import React from 'react';
import { Outlet } from 'react-router-dom';
import PRHeader from 'components/PRHeader';
import PRSidebar from 'components/PRSidebar';

const Default = () => {
  return (
    <>
      <PRHeader />
      <main className="main">
        <PRSidebar />
        <Outlet />
      </main>
    </>
  );
};

export default Default;
