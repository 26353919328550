import React, { useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { ReactComponent as SearchIcon } from 'assets/icons/search.svg';
import PRAsyncSelect from 'components/PRAsyncSelect';
import PRButton from 'components/PRButton';
import PRCheckbox from 'components/PRCheckbox';
import { usePartialState } from 'hooks/partial-state';
import {
  fetchCategories,
  fetchCategoryById,
  fetchCountries,
  fetchCountryById,
  fetchSubcategories,
  fetchSubcategoryById,
  fetchWorkingAreaById,
  fetchWorkingAreas,
} from 'api/repositories/handbooks.repository';
import { Option } from 'entities/Option';

import { FilterParamsDto } from '../../dto/filter-params.dto';

import cl from './home-filter.module.scss';

interface Props {
  params: FilterParamsDto;
  onSubmit: (params: Partial<FilterParamsDto>) => void;
}

const HomeFilter: React.FC<Props> = ({ params, onSubmit }) => {
  const { t } = useTranslation();

  const [localFilter, setLocalFilter] = usePartialState({
    toggle: params.section === 'product',
    region: null as Option | null,
    area: null as Option | null,
    category: null as Option | null,
    subcategory: null as Option | null,
    showOthers: params.showOthers,
  });

  const categoryKey = useMemo(() => {
    if (localFilter.area) {
      return localFilter.area.value;
    }
    return -1;
  }, [localFilter.area]);
  const subcategoryKey = useMemo(() => {
    if (localFilter.category) {
      return localFilter.category.value;
    }
    return -2;
  }, [localFilter.category]);

  useEffect(() => {
    initFilters();
  }, []);

  const initFilters = async () => {
    if (params.region !== null) {
      const res = await fetchCountryById(params.region);
      onRegionChange(res);
    }

    if (params.area !== null) {
      const res = await fetchWorkingAreaById(params.area);
      onAreaChange(res);
    }

    if (params.category) {
      const res = await fetchCategoryById(params.category);
      onCategoryChange(res);
    }

    if (params.subcategory) {
      const res = await fetchSubcategoryById(params.subcategory);
      onSubcategoryChange(res);
    }
  };

  const toggleFilter = (val: boolean) => {
    const section = val ? 'product' : 'manufacturer';

    setLocalFilter({ toggle: val });
    onSubmit({
      ...getLocalFilter(),
      section,
      page: 1,
    });
  };

  const onRegionChange = (val: unknown) => {
    const option = val as Option;
    setLocalFilter({
      region: option ? option : null,
    });
  };

  const onAreaChange = (val: unknown) => {
    const option = val as Option;
    if (option?.value === localFilter.area?.value) return;

    setLocalFilter({
      area: option,
    });
    onCategoryChange(null);
  };

  const onCategoryChange = (val: unknown) => {
    const option = val as Option;
    if (option?.value === localFilter.category?.value) return;

    setLocalFilter({
      category: option,
    });
    onSubcategoryChange(null);
  };

  const onSubcategoryChange = (val: unknown) => {
    const option = val as Option;
    setLocalFilter({
      subcategory: option,
    });
  };

  const onOthersChange = (val: boolean) => {
    setLocalFilter({
      showOthers: val,
    });
  };

  const onFilterAccept = (e: React.FormEvent) => {
    e.preventDefault();
    onSubmit(getLocalFilter());
  };

  const getLocalFilter = () => ({
    region: localFilter.region?.value || null,
    area: localFilter.area?.value || null,
    category: localFilter.category?.value || null,
    subcategory: localFilter.subcategory?.value || null,
    showOthers: localFilter.showOthers,
  });

  return (
    <div className={cl.filter}>
      <div className={cl.toggle}>
        <input
          checked={localFilter.toggle}
          className={cl['toggle__checkbox']}
          type="checkbox"
          onChange={(e) => toggleFilter(e.target.checked)}
        />

        <div className={cl['toggle__knobs']}>
          <span className={cl['toggle__manufacturer']}>{t('action.seekManufacturer')}</span>
          <span className={cl['toggle__product']}>{t('action.seekProduct')}</span>
        </div>
      </div>
      <form className={cl['filter__form']} onSubmit={onFilterAccept}>
        <PRAsyncSelect
          value={localFilter.region}
          fetchList={fetchCountries}
          label={t('form.region')}
          searchParams={{ page_size: 250 }}
          defaultOptions
          isClearable
          onChange={onRegionChange}
        />
        <PRAsyncSelect
          value={localFilter.area}
          fetchList={fetchWorkingAreas}
          label={t('form.industry')}
          defaultOptions
          isClearable
          onChange={onAreaChange}
        />
        {localFilter.toggle ? (
          <>
            <PRAsyncSelect
              key={categoryKey}
              value={localFilter.category}
              fetchList={fetchCategories}
              label={t('form.category')}
              searchParams={{ industry_id: localFilter.area?.value }}
              isDisabled={!localFilter.area}
              defaultOptions={!!localFilter.area}
              isClearable
              onChange={onCategoryChange}
            />
            <PRAsyncSelect
              key={subcategoryKey}
              value={localFilter.subcategory}
              fetchList={fetchSubcategories}
              label={t('form.subcategory')}
              searchParams={{ category_id: localFilter.category?.value }}
              isDisabled={!localFilter.category}
              defaultOptions={!!localFilter.category}
              isClearable
              onChange={onSubcategoryChange}
            />
          </>
        ) : null}

        <PRButton type="submit" prefix={<SearchIcon className={cl['filter__search-icon']} />}>
          {t('action.find')}
        </PRButton>
      </form>

      {/*<PRCheckbox*/}
      {/*  name="home-filter-show-others"*/}
      {/*  value={localFilter.showOthers}*/}
      {/*  onChange={onOthersChange}*/}
      {/*>*/}
      {/*  {t('form.showOtherCompanies')}*/}
      {/*</PRCheckbox>*/}
    </div>
  );
};

export default HomeFilter;
