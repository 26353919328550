import { useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';
import { usePartialState } from 'hooks/partial-state';

import { FilterParamsDto } from '../dto/filter-params.dto';

export const useFilter = (): [FilterParamsDto, (param: Partial<FilterParamsDto>) => void] => {
  const [query, setQuery] = useSearchParams();

  const section = query.get('section');
  const region = query.get('region');
  const area = query.get('area');
  const category = query.get('category');
  const subcategory = query.get('subcategory');
  const showOthers = query.get('showOthers');
  const page = query.get('page');

  const trade = query.get('trade');
  const name = query.get('name');

  const [filters, setFilters] = usePartialState({
    section: section ?? 'manufacturer',
    region: region ? +region : null,
    area: area ? +area : null,
    category: category ? +category : null,
    subcategory: subcategory ? +subcategory : null,
    showOthers: showOthers === 'true',
    page: page ? +page : 1,

    trade: trade ? +trade : null,
    name,
  });

  useEffect(() => {
    Object.entries(filters).forEach(([key, value]) => {
      if (value) {
        query.set(key, value.toString());
      } else {
        query.delete(key);
      }
    });

    setQuery(query);
  }, [filters]);

  return [filters, setFilters];
};
