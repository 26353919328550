import React from 'react';

import cl from './loading.module.scss';

const PRLoading = () => {
  return (
    <div className={cl['loading']}>
      <div className={cl['loading__circle']} />
    </div>
  );
};

export default PRLoading;
