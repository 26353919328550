export default {
  submit: 'Відправити',
  email: 'Ел. пошта',
  password: 'Пароль',
  title: 'Назва',
  workingArea: 'Вид діяльності',
  country: 'Країна',
  legalAddress: 'Юридична адреса',
  bin: 'БІН',
  website: 'Сайт',
  phoneNumber: 'Номер телефону',
  fullName: "Повне ім'я",
  position: 'Посада',
  firstName: "Ім'я",
  lastName: 'Прізвище',
  patronymic: 'По батькові',
  confirmPassword: 'Підтвердити',
  newPassword: 'Новий пароль',
  createAccount: 'Створити обліковий запис',
  forgotPassword: 'Забули пароль',
  city: 'Місто',
  description: 'Опис',
  category: 'Категорія',
  subcategory: 'Підкатегорія',
  region: 'Регіон',
  industry: 'Галузь',
  showOtherCompanies: 'Показати компанії, яких немає у моєму регіоні',
  companyName: 'Назва компанії',
  cooperationPurpose: 'Мета співпраці',
  offer: 'Пропозиція',
  expectedBenefits: 'Очікувані переваги',
  searchByIdTitle: 'Пошук за назвою, ID або артикулом товару',
  selectColumnName: 'Виберіть назву стовпця',
  positionName: 'Назва позиції',
  width: 'Ширина, {{unit}}',
  height: 'Висота, {{unit}}',
  length: 'Довжина, {{unit}}',
  weight: 'Вага, {{unit}}',
  orderFrom: 'При замовленні від',
  keyPhrases: 'Введіть ключові фрази, за якими покупці будуть шукати ваш товар',
  contactPerson: 'Контактна особа',
  code: 'Код',
  street: 'Вулиця',
  writeMessage: 'Введіть повідомлення',
  branchType: 'Тип організації',
};
