export default {
  main: 'Ana Sayfa',
  loading: 'Yükleniyor...',
  listEmpty: 'Liste Boş',
  attention: 'Dikkat',
  services: 'Hizmetler',
  applications: 'Başvurular',
  partnership: 'Ortaklık',
  branches: 'Şubeler',
  catalog: 'Katalog',
  faq: 'Sıkça Sorulan Sorular',
  companyData: 'Şirket Bilgileri',
  employeeData: 'Çalışan Bilgileri',
  accountActivation: 'Hesap Aktivasyonu',
  aboutPlatform: 'Platform Hakkında',
  manageCompany: 'Şirketi Yönet',
  tradingAgency: 'Ticaret Temsilciliği',
  saleOfPoint: 'Satış Noktası',
  tradingHouse: 'Ticaret Evi',
  manufacturerName: 'Üretici Adı',
  productName: 'Ürün Adı',
  priceFrom: 'Fiyat: {{from}} itibaren',
  aboutManufacturer: 'Üretici Hakkında',
  contacts: 'İletişim Bilgileri',
  manufacturers: 'Üreticiler',
  summary: 'Özet',
  certificates: 'Sertifikalar',
  yearsAgo: '{{num}} yıl önce',
  title: 'Başlık',
  brandName: 'Marka Adı',
  legalAddress: 'Hukuki Adres',
  jurisdiction: 'Yetki Alanı',
  regNumberBin: 'Kayıt Numarası',
  registrationDate: 'Kayıt Tarihi',
  sinceOnSite: 'Siteye Katılma Tarihi',
  product: 'Ürün',
  vendorCode: 'Ürün Kodu',
  date: 'Tarih',
  display: 'Görüntüle',
  inStock: 'Stokta',
  actions: 'İşlemler',
  required: 'Gerekli',
  optional: 'İsteğe Bağlı',
  productList: 'Ürün Listesi',
  barcode: 'Barkod veya UPC/EAN',
  color: 'Renk',
  material: 'Malzeme',
  currency: 'Para Birimi',
  unitType: 'Birim Türü',
  status: 'Durum',
  wholesalePrice: 'Toptan Fiyat',
  from: 'itibaren',
  mainInfo: 'Ana Bilgiler',
  productDescription: 'Ürün Açıklaması',
  dimensions: 'Boyutlar',
  characteristics: 'Özellikler',
  price: 'Fiyat',
  createAndUpdate: 'Oluşturma ve Güncelleme',
  created: 'Oluşturuldu',
  updated: 'Güncellendi',
  searchTerms: 'Arama Terimleri',
  supportedFormats: 'Desteklenen Formatlar: {{formats}}',
  maxSize: 'Maksimum Boyut: {{size}}',
  images: 'Resimler',
  agency: 'Temsilcilik',
  password: 'Parola',
  addingBranch: 'Şube Ekleme',
  questionAndAnswer: 'Soru ve Cevaplar',
  profile: 'Profil',
  settings: 'Ayarlar',
  requirementsForVerification: 'Doğrulama İçin Gereklilikler',
  submitFollowingDocuments: 'Aşağıdaki Belgeleri Gönderin',
  companyRegCertificate: 'Şirket Kayıt Belgesi',
  companyCharter: 'Şirket Ana Sözleşmesi',
  certificateTaxReg: 'Vergi Kayıt Belgesi',
  productionDoc: 'Üretim Belgeleri',
  qualityCertificate: 'Kalite ve Uygunluk Sertifikaları',
  licensesAndCertificates: 'Lisanslar ve Sertifikalar',
  suppliersAndCustomersContracts: 'Tedarikçi ve Müşteri Sözleşmeleri',
  accountDeleting: 'Hesap Silme',
  passwordChanging: 'Parola Değiştirme',
  today: 'bugün',
  weight: 'Ağırlık',
  availableOnOrder: 'Siparişle Mevcut',
  manufacturerOtherProducts: 'Üreticinin Diğer Ürünleri',
  lastActivity: 'Son Aktivite',
  passwordRecovery: 'Parola Kurtarma',
  forgotPassword: 'Parolanızı mı unuttunuz?',
  haveNotAccount: 'Hesabınız yok mu?',
  createPassword: 'Parola Oluştur',
  registration: 'Kayıt',
  haveAnAccount: 'Zaten hesabınız var mı?',
  iAmManufacturer: 'Üreticiyim',
  iAmUser: 'Kullanıcıyım',
  aboutCompany: 'Şirket Hakkında',
  logo: 'Logo',
  personalData: 'Kişisel Bilgiler',
  notifications: 'Bildirimler',
  todayAt: 'Bugün, {{date}}',
  yesterdayAt: 'Dün, {{date}}',
  all: 'Tümü',
  system: 'Sistem',
  notSpecified: 'Belirtilmemiş',
};
