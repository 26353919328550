import React, { forwardRef, useMemo, useState } from 'react';

import cl from './custom-textarea.module.scss';

interface Props {
  className?: string;
  name: string;
  label?: string;
  value: string;
  placeholder?: string;
  error?: string;
  maxLength?: number;
  required?: boolean;
  disabled?: boolean;
  onChange: (value: string) => void;
  onBlur?: () => void;
}

const PRTextarea = forwardRef<HTMLTextAreaElement, Props>(
  (
    {
      className = '',
      label,
      value,
      placeholder = '',
      name,
      error,
      maxLength = 0,
      required = false,
      disabled = false,
      onChange,
      onBlur = () => {},
    },
    ref
  ) => {
    const [isFocused, setFocus] = useState(false);

    const onInputBlur = () => {
      setFocus(() => false);
      onBlur();
    };

    const wrapper = useMemo(() => {
      let className = cl['textarea__wrapper'];
      if (isFocused || value) {
        className += ` ${cl['textarea__wrapper--focus']}`;
      }
      if (disabled) {
        className += ` ${cl['textarea__wrapper--disable']}`;
      }
      if (error) {
        className += ` ${cl['textarea__wrapper--error']}`;
      }

      return className;
    }, [isFocused, error, disabled, value]);

    const onInputChange = (val: string) => {
      if (!!maxLength && val.length >= maxLength) {
        onChange(val.substring(0, maxLength));
      } else {
        onChange(val);
      }
    };

    return (
      <div className={`${className} ${cl['textarea']}`}>
        <div className={wrapper}>
          {label ? (
            <p className={cl['textarea__label']}>
              {label} {required ? <span>*</span> : null}
            </p>
          ) : null}

          <textarea
            ref={ref}
            value={value}
            placeholder={placeholder}
            name={name}
            className={`${cl['textarea__field']} ${error ? cl['textarea__field--error'] : ''}`}
            onChange={(e) => onInputChange(e.target.value)}
            onBlur={onInputBlur}
            onFocus={() => setFocus(true)}
          ></textarea>
        </div>
        {!!error || maxLength ? (
          <div className={cl['textarea__helper']}>
            {!!error ? (
              <p className={cl['textarea__error']}>{error}</p>
            ) : (
              <p className={cl['textarea__text']}></p>
            )}
            {!!maxLength ? (
              <p className={cl['textarea__text']}>{`${value.length}/${maxLength}`}</p>
            ) : null}
          </div>
        ) : null}
      </div>
    );
  }
);

export default PRTextarea;
