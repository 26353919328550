import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import readXlsxFile, { Row } from 'read-excel-file';

import { useNotification } from './notification';

export const useReadExel = (file: File) => {
  const { t } = useTranslation();
  const notify = useNotification();
  const [rows, setRows] = useState<Row[]>([]);

  useEffect(() => {
    readXlsxFile(file)
      .then((res) => {
        setRows(res);
      })
      .catch(() => {
        notify.error(t('error.readFile'));
      });
  }, [file]);

  return rows;
};
