export default {
  notConnection: 'تحقق من الاتصال بالإنترنت',
  unknown: 'حدث خطأ ما',
  server: 'خطأ في الخادم. يجب الاتصال بالدعم الفني.',
  wrongAuth: 'بريد إلكتروني أو كلمة مرور غير صحيحة',
  required: 'حقل مطلوب للملء',
  invalidEmail: 'بريد إلكتروني غير صالح',
  minLength: 'يجب أن يكون أطول من {{len}} أحرف',
  maxLength: 'يجب أن لا يزيد عن {{len}} أحرف',
  passwordsNotMatch: 'كلمات المرور غير متطابقة',
  notCorrectPrice: 'سعر غير صحيح',
  notCorrectLink: 'رابط غير صحيح',
  forbidden: 'ليس لديك الصلاحيات الكافية لهذا الإجراء',
  phone: 'رقم هاتف غير صحيح',
  incorrectFormat: 'يجب أن يكون الملف بتنسيق {{format}}',
  selectRequiredColumns: 'لم يتم اختيار الأعمدة المطلوبة',
  readFile: 'خطأ أثناء قراءة الملف',
  emailNotAvailable: 'البريد الإلكتروني مسجل بالفعل في النظام',
};
