import { Meta } from 'entities/Meta';

import { MetaDao } from '../dao/Meta.dao';

export const mapMetaDaoToMetaEntity = (meta: MetaDao): Meta => ({
  page: meta.pageNumber,
  perPage: meta.pageSize,
  total: meta.totalElements,
  lastPage: meta.totalPages,
});
