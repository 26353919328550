import React from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import PRButton from 'components/PRButton';
import PRInput from 'components/PRInput';
import { EMAIL_REGEX } from 'types/constants/email.regex';
import useHandleError from 'hooks/handleError';
import { sendResetPasswordCode } from 'api/repositories/users.repository';
import { ErrorResponse } from 'entities/Error';

import cl from './first.module.scss';

interface Props {
  onSubmit: (val: string) => void;
}

const ResetFirstStep: React.FC<Props> = (props) => {
  const { t } = useTranslation();
  const { handleCommonError } = useHandleError();

  const {
    control,
    formState: { isSubmitting },
    handleSubmit,
  } = useForm({
    defaultValues: {
      email: '',
    },
  });

  const onSubmit = async ({ email }: { email: string }) => {
    try {
      await sendResetPasswordCode(email);
      props.onSubmit(email);
    } catch (err: unknown) {
      const error = err as ErrorResponse;
      handleCommonError(error);
    }
  };

  return (
    <form className={cl['first']} onSubmit={handleSubmit(onSubmit)}>
      <p className={cl['first__text']}>{t('message.writeEmailForRecovery')}</p>

      <Controller
        control={control}
        name="email"
        rules={{
          required: { message: t('error.required'), value: true },
          pattern: {
            message: t('error.invalidEmail'),
            value: EMAIL_REGEX,
          },
        }}
        render={({ field: { onChange, onBlur, value, name, ref }, fieldState: { error } }) => (
          <PRInput
            label={t('form.email')}
            ref={ref}
            name={name}
            value={value}
            error={error?.message}
            required
            onChange={onChange}
            onBlur={onBlur}
          />
        )}
      />

      <div className={cl['first__button']}>
        <PRButton loading={isSubmitting} type="submit">
          {t('action.continue')}
        </PRButton>
      </div>
    </form>
  );
};

export default ResetFirstStep;
