import React, { useState } from 'react';
import { ReactComponent as CheckIcon } from 'assets/icons/check.svg';
import { ReactComponent as CloseIcon } from 'assets/icons/close.svg';
import { ReactComponent as ExelIcon } from 'assets/icons/exel.svg';
import { ReactComponent as UploadIcon } from 'assets/icons/upload.svg';
import PRButton from 'components/PRButton';
import PRFileInput from 'components/PRFileInput';
import PRTitle from 'components/PRTitle';

import cl from './first.module.scss';

interface Props {
  onNext: (file: File) => void;
}

const ImportProductsFirst: React.FC<Props> = ({ onNext }) => {
  const [file, setFile] = useState<File | null>(null);
  const columns = [
    'Название товара',
    'Описание товара',
    'Категория товара',
    'Подкатегория товара',
    'Цена товара',
    'Цена от 100 штук',
    'Цена от 1000 штук',
    'Размер',
    'Вес',
    'Цвет',
    'Материал',
    'Артикул или SKU',
    'Баркод или UPC/EAN',
  ];

  return (
    <div className={cl['first']}>
      <div className={cl['first__card']}>
        <PRTitle tag="h4">Импорт товаров из документа</PRTitle>
        <div className={cl['first__document']}>
          {file ? (
            <>
              <div className={cl['first__preview']}>
                <p>
                  <ExelIcon />
                  <span>{file.name}</span>
                </p>
                <button onClick={() => setFile(null)}>
                  <CloseIcon />
                </button>
              </div>

              <PRButton onClick={() => onNext(file)}>Продолжить</PRButton>
            </>
          ) : (
            <PRFileInput format=".xls, .xlsx" setFile={setFile}>
              <div className={cl['first__file-hint']}>
                <p>Поддерживаемый формат: XLSX (Excel)</p>
                <div>
                  <UploadIcon />
                  <span>Загрузить файл</span>
                </div>
              </div>
            </PRFileInput>
          )}
        </div>
      </div>

      <div className={cl['first__card']}>
        <PRTitle tag="h4">Требования для импорта</PRTitle>
        <p className={cl['first__require-text']}>В файле должны быть следующие типы столбцов:</p>
        <div className={cl['first__require-list']}>
          {columns.map((str, index) => (
            <p key={index + 1}>
              <CheckIcon />
              <span>{str}</span>
            </p>
          ))}
        </div>
      </div>
    </div>
  );
};

export default ImportProductsFirst;
