import axios from 'axios';
import { baseUrl } from 'config/api.config';
import i18n from 'i18next';

let authToken = '';

export const injectAuthToken = (_token: string) => {
  authToken = _token;
};

const instance = axios.create({
  baseURL: baseUrl + '/api/v1',
});

instance.interceptors.request.use(
  function (config) {
    config.headers = {
      'Accept-Language': i18n.language,
      ...(authToken && {
        Authorization: `Bearer ${authToken}`,
      }),
    };

    return config;
  },
  function (error) {
    return Promise.reject(error);
  }
);

// instance.interceptors.response.use(
//   (response) => response,
//   (error) => {
//     const response = error.response;
//     if (response) {
//       if (response.status === 401) {
//         window.location.href = '/auth/login';
//       }
//     }
//     return Promise.reject(error);
//   }
// );

export default instance;
