import React, { useEffect, useMemo } from 'react';
import { registrationSlice } from 'store/registration';
import { useAppDispatch, useAppSelector } from 'hooks/redux';

import AuthSteps from '../../components/AuthSteps';
import RegistrationFirstStep from '../../components/RegistrationFirstStep';
import RegistrationFourthStep from '../../components/RegistrationFourthStep';
import RegistrationSecondStep from '../../components/RegistrationSecondStep';
import RegistrationStartStep from '../../components/RegistrationStartStep';
import RegistrationThirdStep from '../../components/RegistrationThirdStep';

import cl from './registration.module.scss';

const Registration = () => {
  const dispatch = useAppDispatch();
  const { step, start } = useAppSelector((state) => state.registration);

  const currentStep = useMemo(() => {
    if (step === 0) {
      return <RegistrationStartStep />;
    } else if (step === 1 && start.type === 'manufacturer') {
      return <RegistrationFirstStep />;
    } else if (step === 1 && start.type === 'user') {
      return <RegistrationSecondStep />;
    } else if (step === 2) {
      return <RegistrationThirdStep />;
    } else {
      return <RegistrationFourthStep />;
    }
  }, [step, start.type]);

  useEffect(() => {
    dispatch(registrationSlice.actions.resetState());
  }, []);

  return (
    <div className={cl.registration}>
      {currentStep}

      <AuthSteps current={step + 1} total={4} />
    </div>
  );
};

export default Registration;
