export default {
  notConnection: 'Check your internet connection',
  unknown: 'Something went wrong',
  server: 'Server error. Please contact technical support.',
  wrongAuth: 'Incorrect email or password',
  required: 'This field is required',
  invalidEmail: 'Invalid email',
  minLength: 'Should be at least {{len}} characters long',
  maxLength: 'Should not be longer than {{len}} characters',
  passwordsNotMatch: 'Passwords do not match',
  notCorrectPrice: 'Invalid price',
  notCorrectLink: 'Invalid link',
  forbidden: 'You do not have sufficient permissions for this action',
  phone: 'Invalid phone number',
  incorrectFormat: 'File must be in {{format}} format',
  selectRequiredColumns: 'Required columns are not selected',
  readFile: 'Error reading the file',
  emailNotAvailable: 'Email is already registered in the system',
};
