export default {
  submit: 'Адправіць',
  email: 'Эл. пошта',
  password: 'Пароль',
  title: 'Назва',
  workingArea: 'Від дзейнасці',
  country: 'Краіна',
  legalAddress: 'Юрыдычны адрас',
  bin: 'БІН',
  website: 'Сайт',
  phoneNumber: 'Нумар тэлефона',
  fullName: 'Поўнае імя',
  position: 'Пасада',
  firstName: 'Імя',
  lastName: 'Прозвішча',
  patronymic: 'Па бацьку',
  confirmPassword: 'Пацвердзіць',
  newPassword: 'Новы пароль',
  createAccount: 'Стварыць акаунт',
  forgotPassword: 'Забыў пароль',
  city: 'Горад',
  description: 'Апісанне',
  category: 'Катэгорыя',
  subcategory: 'Падкатэгорыя',
  region: 'Рэгіон',
  industry: 'Галузь',
  showOtherCompanies: 'Паказаць кампаніі, якіх няма ў маім рэгіёне',
  companyName: 'Назва кампаніі',
  cooperationPurpose: 'Мэта супрацоўніцтва',
  offer: 'Прапанова',
  expectedBenefits: 'Чаканыя перавагі',
  searchByIdTitle: 'Пошук па назве, ID або артыкуле тавару',
  selectColumnName: 'Выберыце назву слупка',
  positionName: 'Назва пазіцыі',
  width: 'Шырыня, {{unit}}',
  height: 'Вышыня, {{unit}}',
  length: 'Даўжыня, {{unit}}',
  weight: 'Вага, {{unit}}',
  orderFrom: 'Пры замове ад',
  keyPhrases: 'Увядзіце ключавыя фразы, па якіх пакупцы будуць шукаць ваш тавар',
  contactPerson: 'Кантактная асоба',
  code: 'Код',
  street: 'Вуліца',
  writeMessage: 'Увядзіце паведамленне',
  branchType: 'Тып арганізацыі',
};
