import { initReactI18next } from 'react-i18next';
import i18n from 'i18next';

import translationAr from './ar';
import translationBe from './be';
import translationEn from './en';
import translationEs from './es';
import translationFa from './fa';
import translationKk from './kk';
import translationKy from './ky';
import translationRu from './ru';
import translationTr from './tr';
import translationUk from './uk';
import translationUz from './uz';

let _defaultLang = 'ru';

const settings = localStorage.getItem('persist:settings');
if (settings) {
  try {
    _defaultLang = JSON.parse(settings).lang.substring(1, 3);
  } catch (err) {
    console.log('Can not parse settings');
  }
}

i18n.use(initReactI18next).init({
  resources: {
    kk: { translation: translationKk },
    ru: { translation: translationRu },
    ar: { translation: translationAr },
    be: { translation: translationBe },
    en: { translation: translationEn },
    es: { translation: translationEs },
    fa: { translation: translationFa },
    ky: { translation: translationKy },
    tr: { translation: translationTr },
    uk: { translation: translationUk },
    uz: { translation: translationUz },
  },
  lng: _defaultLang,
  fallbackLng: 'ru',
  interpolation: { escapeValue: false },
});

export default i18n;
