import { useEffect, useState } from 'react';

export const useTimer = (count = 59) => {
  const [seconds, setSeconds] = useState(count);

  useEffect(() => {
    let timerId = -1;

    if (seconds > 0) {
      timerId = window.setTimeout(() => {
        setSeconds((prev) => prev - 1);
      }, 1000);
    }

    return () => {
      clearTimeout(timerId);
    };
  }, [seconds]);

  const restartTimer = () => {
    setSeconds(count);
  };

  return { seconds, restartTimer };
};
