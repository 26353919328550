import { useEffect, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { ClusterPointDto } from 'types/dto/cluster-point.dto';
import { Branch } from 'entities/Branch';
import { CityOption, CountryOption, Option, TradeOption } from 'entities/Option';

export interface CreateBranchForm {
  title: string;
  trade: TradeOption | null;
  contactPerson: string;
  position: Option | null;
  phoneNumber: string;
  email: string;
  country: CountryOption | null;
  city: CityOption | null;
  address: string;
  coordinates: number[];
}

export const useCreateBranch = (branch?: Branch) => {
  const [cluster, setCluster] = useState<ClusterPointDto | null>(null);
  const [center, setCenter] = useState<[number, number]>([51.128315, 71.430648]);
  const [zoom, setZoom] = useState(2);

  const {
    control,
    formState: { isSubmitting },
    handleSubmit,
    setValue,
    reset,
    watch,
  } = useForm<CreateBranchForm>({
    defaultValues: {
      title: '',
      trade: null,
      contactPerson: '',
      position: null,
      phoneNumber: '',
      email: '',
      country: null,
      city: null,
      address: '',
      coordinates: [],
    },
  });

  useEffect(() => {
    if (branch) {
      setValue('title', branch.title);
      setValue('trade', branch.trade);
      setValue('contactPerson', branch.contactPerson);
      setValue('position', branch.position);
      if (branch.phone) {
        const phone = branch.phone.replace(branch.country.phoneCode + ' ', '');
        setValue('phoneNumber', phone);
      }
      setValue('email', branch.email);
      setValue('country', branch.country);
      setValue('city', branch.city);
      setValue('address', branch.address);
      setValue('coordinates', branch.coordinates);

      setTimeout(() => {
        setCenter(branch.coordinates);
        setZoom(16);
        setCluster({
          icon: branch.trade.logo?.link || 'islands#glyphCircleIcon',
          cords: branch.coordinates,
          point: {
            id: branch.id,
            title: branch.title,
            address: branch.address,
          },
        });
      }, 1000);
    }
  }, [branch]);

  const selectedTrade = watch('trade');

  const selectedCountry = watch('country');
  useEffect(() => {
    if (selectedCountry) {
      setCenter(selectedCountry.center);
      setZoom(selectedCountry.zoom);
    }
  }, [selectedCountry]);

  const selectedCity = watch('city');
  useEffect(() => {
    if (selectedCity) {
      setCenter(selectedCity.center);
      setZoom(selectedCity.zoom);
    }
  }, [selectedCity]);

  const cityKey = useMemo(() => {
    if (selectedCountry) {
      return selectedCountry.value;
    }
    return -1;
  }, [selectedCountry]);

  const resetCity = (option: Option) => {
    if (selectedCountry?.value === option?.value) return;

    setValue('city', null);
  };

  const onAddressSelect = (val: string, coords: [number, number]) => {
    setValue('address', val);
    setValue('coordinates', coords);
  };

  const resetOnClose = () => {
    reset();
    setCluster(null);
    setZoom(2);
    setCenter([51.128315, 71.430648]);
  };

  return {
    control,
    watch,
    isSubmitting,
    handleSubmit,
    setValue,
    reset: resetOnClose,

    cluster,
    center,
    setCenter,
    zoom,
    setZoom,

    selectedTrade,
    selectedCountry,
    cityKey,

    resetCity,
    onAddressSelect,
  };
};
