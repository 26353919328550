import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ReactComponent as ArrowDown } from 'assets/icons/chevron-down.svg';
import PRTitle from 'components/PRTitle';
import { fetchFaq } from 'api/repositories/handbooks.repository';
import { Faq } from 'entities/Faq';

import cl from './faq.module.scss';

const FrequentlyAskedQuestions = () => {
  const { t } = useTranslation();
  const [current, setCurrent] = useState(-1);
  const [questions, setQuestions] = useState<Faq[]>([]);

  useEffect(() => {
    fetchFaq().then((res) => {
      setQuestions(res);
    });
  }, []);

  const changeCurrentQuestion = (id: number) => {
    setCurrent((val) => (val === id ? -1 : id));
  };

  return (
    <div className={cl['faq']}>
      <PRTitle tag="h2">{t('common.faq')}</PRTitle>

      <div className={cl['faq__list']}>
        {questions.map((question) => (
          <div
            key={question.id}
            className={`${cl['item']} ${question.id === current ? cl['item--open'] : ''}`}
          >
            <button
              className={cl['item__header']}
              onClick={() => changeCurrentQuestion(question.id)}
            >
              <PRTitle tag="h3">{question.title}</PRTitle>
              <div className={cl['item__arrow']}>
                <ArrowDown />
              </div>
            </button>
            <div className={cl['item__body']}>
              <p>{question.answer}</p>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default FrequentlyAskedQuestions;
