export default {
  signTo: 'Системага кириш',
  signUp: 'Шахсий кабинет яратиш',
  passwordHelper: 'Минимум 8 та белги, 1 бош ҳарф, 1 рақам.',
  lastUpdate: 'Сўнгги янгилаш: {{date}}',
  tradingAgency:
    'Савдо вилоятлари: белгиланган ҳудудда махсус қоидалар бўйича дистрибуцияни яратиш вазифасини ўзлашган вакил.',
  saleOfPoint: 'Махсус сотувчининг товарни жойлашуви',
  tradingHouse:
    'Маишийнинг фирмаси томонидан маҳсулотни ташкил этиш ва дистрибуция қоидалари орқали белгиланган ҳудудда ҳамёнлаштиришга мослашган мулкчи компания.',
  uploadPresentationOrFile:
    'Керак бўлган қўшимча ҳужжатларни, презентацияни ёки файлни юклаб олинг',
  usingForSEO: 'Эсао учун фойдаланилади ва маҳсулотни излаш тизимларидаги кўринишни оширади',
  noBranchesYet: 'Сизнинг ҳали қћўшимча филиаллари мавжуд эмас',
  branchBenefits:
    'Филиаллар рўйхати ишончни оширади ва веб-сайтда кўринишни яхшилашга, шу жумладан, бошқа компанияларга филиалларни кўриб туриш ва улар билан боғланиш имконияти беради.',
  activationCodeSend:
    'Сизнинг почта манзилингизга рўйхатни тугатиш учун код юборилди, илтимос, кодни киритиш ўрнига кодни киритинг',
  resendCodeAfter: '{{seconds}} секунд ортгача кодни қайта юбориш',
  codeSent: 'Код юборилди',
  allDataWillBeDeleted: 'Барча маълумотларингиз аккаунт билан бирга ўчирилади',
  changePasswordImproveAccount: 'Аккаунтни хавфсизлигини ошириш максади билан кодни янгилаш',
  writeEmailForRecovery:
    'Илтимос, сизнинг аккаунтингизга боғланган электрон почта манзилингизни киритинг.',
  codeForRecovery: 'Паролни бекор қилиш учун код почта манзилингизга юборилди',
  enterCodeToAccessAccount: 'Аккаунтга киришни тозалаш учун киритилган кодни киритинг',
  createStrongPassword: 'Аккаунтингиз учун қаватлар паролни яратинг',
  infoAboutYourCompany: 'Фирмангиз тўғрисида маълумот киритинг',
  iWantCatalogAndMakeSale: 'Менинг каталогимни чоп этишни ва маҳсулот сотишни истайман',
  iWantSearchManufacturers: 'Мен производителарни излашни истайман',
  tradingAgencyDescription:
    'Савдо вилоятлари: буҳудудда бошқа компанияларнинг интереслари ва маҳсулотини тақдим этиш билан шугулланган ташкилотлардир. Улар мижозларни излаш, маҳсулотларни танитиш, музокаралар олиш ва компаниянинг номига келиб сотиш ҳамда тўғрилаш учун муҳим рол ойнайди. Савдо вилоятлари мисоллари: қуёш харид қилиш ва ҳокимият органлари томонидан етказилган сотиш агентликлари, автомобил сотиш агентликлари ва ҳ.к.',
  tradingHouseDescription:
    'Савдо уйлари: улар бир бошқа ҳодим фирмалари томонидан бошқа фирмаларни бир бошқа ҳудудда ҳамёнлаштиришни ўзлаштирган катта савдо ташкилотларидир. Савдо уйи мазкур организациялар бўйича бир нечта бўлимларни ва маҳсус бўлимларни ўз ичиг',
  tradingPointDescription:
    'Савдо нуқталари: буманзиллар, маҳсулотлар ёки хизматларни мустақил ишловчиларга сотиш ўрни. Бу мағазинлар, супермаркетлар, бозорлар, савдо мавлидлари ва ҳ.к. бўлиши мумкин. Савдо нуқталари оддийда физикавий жойга эга бўлади, унда мижозлар маҳсулотларни сотиш ёки хизматларни олиш мумкин.',
};
