export default {
  change: 'Change',
  delete: 'Delete',
  find: 'Find',
  reset: 'Reset',
  save: 'Save',
  edit: 'Edit',
  cancel: 'Cancel',
  accept: 'Accept',
  login: 'Log In',
  logout: 'Log Out',
  signup: 'Sign Up',
  signin: 'Sign In',
  next: 'Next',
  seekManufacturer: 'Seek Manufacturer',
  seekProduct: 'Seek Product',
  back: 'Back',
  forward: 'Forward',
  becomeAPartner: 'Become a Partner',
  contactWithManufacturer: 'Contact with Manufacturer',
  uploadFile: 'Upload File',
  sendRequest: 'Send Request',
  importProducts: 'Import Products',
  addProduct: 'Add Product',
  continue: 'Continue',
  addPrice: 'Add Price',
  removePrice: 'Remove Price',
  saveChanges: 'Save Changes',
  leaveAsDraft: 'Leave as Draft',
  close: 'Close',
  ready: 'Ready',
  addBranches: 'Add Branches',
  resendActivationCode: 'Resend Activation Code',
  sendForVerification: 'Send for Verification',
  deleteAccount: 'Delete Account',
  changePassword: 'Change Password',
  hide: 'Hide',
  expand: 'Expand',
  orderProduct: 'Order Product',
  writeToManufacturer: 'Write to Manufacturer',
  goToCatalog: 'Go to Catalog',
  confirm: 'Confirm',
  yes: 'Yes',
  no: 'No',
  selectChat: 'Select Chat',
  signIn: 'Sign In',
  recover: 'Recover',
  create: 'Create',
  uploadNewPhoto: 'Upload New Photo',
  verifyCompany: 'Verify Company',
};
