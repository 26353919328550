export default {
  signTo: 'Sign In',
  signUp: 'Create Account',
  passwordHelper: 'Minimum 8 characters, 1 uppercase letter, 1 digit.',
  lastUpdate: 'Last Update: {{date}}',
  tradingAgency:
    'A representative who takes the commitment to establish distribution in a specific region according to predetermined rules.',
  saleOfPoint: 'Territorial presence of the product at a specific seller.',
  tradingHouse:
    'Authorized company from the manufacturer for presenting the product and establishing distribution rules in a specific region.',
  uploadPresentationOrFile: 'If necessary, upload additional documents, presentations, or files.',
  usingForSEO: 'Used for SEO and help improve product visibility in search engines.',
  noBranchesYet: 'You have no added branches yet.',
  branchBenefits:
    'The list of branches will increase trust and visibility on the website, as well as allow other companies to see and contact the branches.',
  activationCodeSend:
    'A code has been sent to your email. To complete the registration, enter the code in the field.',
  resendCodeAfter: 'Resend the code after {{seconds}} seconds.',
  codeSent: 'Code sent.',
  allDataWillBeDeleted: 'All your data will be deleted along with the account.',
  changePasswordImproveAccount: 'Updating the access code to the account to enhance security.',
  writeEmailForRecovery: 'Please enter the email associated with your account.',
  codeForRecovery: 'The code for resetting the password has been sent to your email.',
  enterCodeToAccessAccount: 'Enter the code below to regain access to your account.',
  createStrongPassword: 'Create a strong password for your account.',
  infoAboutYourCompany: 'Provide information about your company.',
  iWantCatalogAndMakeSale: 'I want to list my catalog and sell products.',
  iWantSearchManufacturers: 'I am looking for manufacturers for partnership.',
  tradingAgencyDescription:
    'Trading agencies: These are organizations that represent the interests and products of other companies in a specific territory. They can search for customers, promote products, negotiate, and make deals on behalf of the represented company. Examples of trading agencies include real estate agencies, car sales agencies, etc.',
  tradingHouseDescription:
    'Trading houses: These are large trading organizations that unite multiple retail points or stores under one management company. A trading house may include various departments and specialized sections offering a wide range of products or services. Examples of trading houses include department stores, shopping centers, etc.',
  tradingPointDescription:
    'Trading points: These are places where products or services are sold directly to consumers. They can be shops, supermarkets, markets, trading pavilions, etc. Trading points usually have physical spaces where customers can purchase products or receive services.',
};
