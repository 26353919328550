import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { ReactComponent as PlusIcon } from 'assets/icons/plus.svg';
import { ReactComponent as SearchIcon } from 'assets/icons/search.svg';
import { ReactComponent as UploadIcon } from 'assets/icons/upload.svg';
import PRButton from 'components/PRButton';
import PRInput from 'components/PRInput';
import PRPagination from 'components/PRPagination';

import CatalogTable from '../../components/CatalogTable';
import { useProducts } from '../../hooks/products';

import cl from './catalog.module.scss';

const Catalog = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { products, filters, setFilters, onDeleteProduct } = useProducts();
  const [search, setSearch] = useState('');

  useEffect(() => {
    const timer = setTimeout(() => {
      setFilters({ search, page: 1 });
    }, 500);

    return () => clearTimeout(timer);
  }, [search]);

  const goToImportProducts = () => navigate('/company/catalog/import');

  const goToCreateProduct = () => navigate('/company/catalog/create');

  return (
    <div className={cl['catalog']}>
      <div className={cl['catalog__header']}>
        <PRInput
          name="catalogSearch"
          value={search}
          suffix={<SearchIcon className={cl['catalog__search-icon']} />}
          placeholder={t('form.searchByIdTitle')}
          onChange={setSearch}
        />

        <div className={cl['catalog__buttons']}>
          {/*<PRButton variant="outline" prefix={<UploadIcon />} onClick={goToImportProducts}>*/}
          {/*  {t('action.importProducts')}*/}
          {/*</PRButton>*/}
          <PRButton prefix={<PlusIcon />} onClick={goToCreateProduct}>
            {t('action.addProduct')}
          </PRButton>
        </div>
      </div>

      <CatalogTable products={products} onDelete={onDeleteProduct} />

      <PRPagination
        current={filters.page}
        lastPage={filters.lastPage}
        onChange={(page: number) => setFilters({ page })}
      />
    </div>
  );
};

export default Catalog;
