import React from 'react';
import { useTranslation } from 'react-i18next';
import { Navigate, Outlet } from 'react-router-dom';
import { ReactComponent as VerifiedIcon } from 'assets/icons/verified.svg';
import PRTabs from 'components/PRTabs';
import PRTitle from 'components/PRTitle';
import { useAppSelector } from 'hooks/redux';
import { Organization } from 'entities/User';

import cl from './organization.module.scss';

const OrganizationProfile = () => {
  const { t } = useTranslation();
  const user = useAppSelector((state) => state.auth.user) as Organization;

  const tabs = [
    {
      id: 1,
      action: `/company/profile`,
      title: t('common.profile'),
    },
    {
      id: 2,
      action: `/company/profile/settings`,
      title: t('common.settings'),
    },
  ];

  if (user.type !== 'ORGANIZATION') {
    return <Navigate to="/profile" />;
  }

  return (
    <div className={cl['profile']}>
      <div className={cl['profile__card']}>
        <div className={cl['profile__image']}>
          {user?.avatar ? <img src={user.avatar} alt={user?.title} /> : null}
        </div>

        <div>
          <div className={cl['profile__title']}>
            <PRTitle tag="h1">{user?.title}</PRTitle>
            {user.verified ? <VerifiedIcon /> : null}
          </div>
          <p className={cl['profile__description']}>{user.description}</p>
        </div>

        <PRTabs tabs={tabs} />
      </div>

      <Outlet />
    </div>
  );
};

export default OrganizationProfile;
