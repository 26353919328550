import React, { useMemo } from 'react';
import { ReactComponent as SpinnerIcon } from 'assets/icons/spinner.svg';

import { ButtonColorType, ButtonType } from '../PRButton';

import cl from './icon-button.module.scss';

interface Props {
  children: React.ReactNode;
  type?: ButtonType;
  onClick?: () => void;
  colorType?: ButtonColorType;
  disabled?: boolean;
  loading?: boolean;
  width?: string;
  height?: string;
}

const PRIconButton: React.FC<Props> = ({
  type = 'button',
  onClick = () => {},
  colorType = 'main',
  disabled = false,
  loading = false,
  width = '48px',
  height = '48px',
  children,
}) => {
  const btnClass = useMemo(
    () =>
      `
        ${cl.btn} 
        ${disabled ? cl[`btn__disable`] : cl[`btn__${colorType}`]} 
        ${loading ? cl[`btn--loading`] : ''}
      `,
    [colorType, loading, disabled]
  );
  return (
    <button className={btnClass} type={type} style={{ width, height }} onClick={onClick}>
      {loading ? (
        <span className={cl.loader}>
          <SpinnerIcon />
        </span>
      ) : null}

      {children}
    </button>
  );
};

export default PRIconButton;
