import { Conversation } from 'entities/Conversation';
import { Message } from 'entities/Message';

import { ConversationDao } from '../dao/Conversation.dao';
import { MessageDao } from '../dao/Message.dao';

export const mapConversationDaoToEntity = (conversation: ConversationDao): Conversation => ({
  id: conversation.id,
  title: conversation.title,
  avatar: conversation.avatar,
  online: conversation.online,
  newMessages: conversation.new_messages,
  lastMessage: {
    id: conversation.last_message.id,
    message: conversation.last_message.text,
    status: conversation.last_message.status,
    createdAt: conversation.last_message.created_at,
  },
  createdAt: conversation.created_at,
  updatedAt: conversation.updated_at,
});

export const mapMessageDaoToEntity = (message: MessageDao): Message => ({
  id: message.id,
  text: message.text,
  ownerId: message.owner_id,
  createdAt: message.created_at,
  updatedAt: message.updated_at,
});
