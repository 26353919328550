import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ReactComponent as ChevronDownIcon } from 'assets/icons/chevron-down.svg';
import { settingsSlice } from 'store/settings';
import { useAppDispatch, useAppSelector } from 'hooks/redux';

import PRDropdown from '../PRDropdown';

import cl from './lang-select.module.scss';

const PRLangSelect = () => {
  const { i18n } = useTranslation();
  const dispatch = useAppDispatch();
  const { lang } = useAppSelector((state) => state.settings);

  const [langTxt, setLang] = useState('Ру');

  // {
  //   "en": { "name": "English", "short": "Eng" },
  //   "ru": { "name": "Русский", "short": "Ру" },
  //   "es": { "name": "Español", "short": "Esp" },
  //   "tr": { "name": "Türkçe", "short": "Tür" },
  //   "ar": { "name": "عربي", "short": "عر" },
  //   "fa": { "name": "ایرانی", "short": "ایر" },
  //   "be": { "name": "Беларускі", "short": "Бел" },
  //   "uk": { "name": "Українська", "short": "Укр" },
  //   "kk": { "name": "Қазақ", "short": "Қаз" },
  //   "ky": { "name": "Кыргызча", "short": "Кыр" },
  //   "uz": { "name": "Oʻzbek", "short": "Oʻz" }
  // }

  const langOptions = [
    {
      id: 1,
      title: 'English',
      code: 'en',
      short: 'Eng',
      onClick: function () {
        setLang(this.short);
        changeLanguage(this.code);
      },
    },
    {
      id: 2,
      title: 'Русский',
      code: 'ru',
      short: 'Ру',
      onClick: function () {
        setLang(this.short);
        changeLanguage(this.code);
      },
    },
    {
      id: 3,
      title: 'Español',
      code: 'es',
      short: 'Esp',
      onClick: function () {
        setLang(this.short);
        changeLanguage(this.code);
      },
    },
    {
      id: 4,
      title: 'Türkçe',
      code: 'tr',
      short: 'Tür',
      onClick: function () {
        setLang(this.short);
        changeLanguage(this.code);
      },
    },
    {
      id: 5,
      title: 'عربي',
      code: 'ar',
      short: 'عر',
      onClick: function () {
        setLang(this.short);
        changeLanguage(this.code);
      },
    },
    {
      id: 6,
      title: 'ایرانی',
      code: 'fa',
      short: 'ایر',
      onClick: function () {
        setLang(this.short);
        changeLanguage(this.code);
      },
    },
    {
      id: 7,
      title: 'Беларускі',
      code: 'be',
      short: 'Бел',
      onClick: function () {
        setLang(this.short);
        changeLanguage(this.code);
      },
    },
    {
      id: 8,
      title: 'Українська',
      code: 'uk',
      short: 'Укр',
      onClick: function () {
        setLang(this.short);
        changeLanguage(this.code);
      },
    },
    {
      id: 9,
      title: 'Қазақ',
      code: 'kk',
      short: 'Қаз',
      onClick: function () {
        setLang(this.short);
        changeLanguage(this.code);
      },
    },
    {
      id: 10,
      title: 'Кыргызча',
      code: 'ky',
      short: 'Кыр',
      onClick: function () {
        setLang(this.short);
        changeLanguage(this.code);
      },
    },
    {
      id: 11,
      title: 'Oʻzbek',
      code: 'uz',
      short: 'Oʻz',
      onClick: function () {
        setLang(this.short);
        changeLanguage(this.code);
      },
    },
  ];

  useEffect(() => {
    const candidate = langOptions.find((l) => l.code === lang);
    if (candidate) {
      setLang(candidate.short);
    }
  }, []);

  const changeLanguage = (language: string) => {
    if (language === lang) return;

    i18n.changeLanguage(language).then(() => {
      dispatch(settingsSlice.actions.setCurrentLang(language));
      location.reload();
    });
  };

  return (
    <PRDropdown options={langOptions} position="right">
      <div className={cl['lang-select']}>
        <span>{langTxt}</span>
        <ChevronDownIcon />
      </div>
    </PRDropdown>
  );
};

export default PRLangSelect;
