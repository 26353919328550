import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Clusterer, Map, Placemark, ZoomControl } from '@pbe/react-yandex-maps';
import { YMapsApi } from '@pbe/react-yandex-maps/typings/util/typing';
import { ClusterPointDto, Point } from 'types/dto/cluster-point.dto';
import ymaps, { IEvent } from 'yandex-maps';

interface Props {
  center: [number, number];
  zoom: number;
  clusterOptions: ClusterPointDto[];
  searchInputId?: string;
  behaviors?: string[];
  changeZoom: (val: number) => void;
  selectAddress?: (val: string, coords: [number, number]) => void;
}

const PRMap: React.FC<Props> = ({
  center,
  zoom,
  searchInputId,
  behaviors = [],
  changeZoom,
  clusterOptions,
  selectAddress,
}) => {
  const map = useRef<ymaps.Map | null>(null);
  const [selected, setSelected] = useState<[number, number] | null>(null);

  const setRef = useCallback((node: ymaps.Map) => {
    if (map.current) {
      map.current.events.remove('boundschange', onBoundsChange);
    }

    if (node) {
      node.events.add('boundschange', onBoundsChange);
    }
    map.current = node;
  }, []);

  useEffect(() => {
    if (map.current) {
      map.current.setCenter(center);
    }
  }, [center]);

  useEffect(() => {
    if (map.current) {
      map.current.setZoom(zoom);
    }
  }, [zoom]);

  const onLoad = (api: YMapsApi) => {
    if (selectAddress) {
      map.current?.events.add('click', (e: IEvent | object) => {
        const event = e as IEvent;

        const coordinates = event.get('coords');
        setSelected(() => coordinates);

        api.geocode(coordinates).then((res) => {
          const firstGeoObject = res.geoObjects.get(0);

          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          const address: string = firstGeoObject.getAddressLine();

          selectAddress(address, coordinates);
        });
      });

      if (searchInputId) {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        const suggestView = new api.SuggestView(searchInputId);
        suggestView.events.add('select', (e: IEvent) => {
          const address = e.get('item').value;
          api.geocode(address).then((res) => {
            const firstGeoObject = res.geoObjects.get(0);

            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            const coords: [number, number] = firstGeoObject.geometry.getCoordinates();
            map.current?.setCenter(coords);
            map.current?.setZoom(16);
            selectAddress(address, coords);
            setSelected(() => coords);
          });
        });
      }
    }
  };

  const onBoundsChange = (e: IEvent | object) => {
    const event = e as IEvent;
    if (event.get('newZoom') !== event.get('oldZoom')) {
      changeZoom(event.get('newZoom') ?? zoom);
    }
  };

  const getBalloonContent = (point: Point) => {
    return [
      '<div class="map__balloon">',
      `<h3>${point.title}</h3>`,
      `<p>${point.address}</p>`,
      '</div>',
    ].join('');
  };

  return (
    <Map
      instanceRef={setRef}
      width="100%"
      height="100%"
      defaultState={{
        center,
        zoom,
        controls: [],
        behaviors: ['drag', ...behaviors],
      }}
      modules={['geocode', 'SuggestView']}
      style={{ position: 'absolute', top: 0, right: 0, bottom: 0, left: 0 }}
      onLoad={onLoad}
    >
      <ZoomControl />
      <Clusterer
        options={{
          preset: 'islands#blueClusterIcons',
          groupByCoordinates: false,
        }}
        modules={['geoObject.addon.balloon', 'geoObject.addon.hint']}
      >
        {clusterOptions.map((cluster, index) => {
          return (
            <Placemark
              key={index}
              geometry={cluster.cords}
              properties={{
                balloonContent: getBalloonContent(cluster.point),
              }}
              options={{
                iconLayout: 'default#image',
                iconImageHref: cluster.icon,
                iconImageSize: [32, 32],
                iconImageOffset: [-16, -28],
              }}
            />
          );
        })}
        {selected && <Placemark geometry={selected} key={selected[0] + selected[1]} />}
      </Clusterer>
    </Map>
  );
};

export default PRMap;
