import { Product } from 'entities/Product';

import request from '../axios-instance';
import { mapMetaDaoToMetaEntity } from '../mappers/meta.mappers';
import { mapProductDaoToEntity } from '../mappers/product.mappers';

export interface ProductSearchParams {
  name?: string;
  article?: string;
  barCode?: string;
  subcategoryId?: number;
  categoryId?: number;
  industryId?: number;
  colorId?: number;
  materialId?: number;
  manufacturer?: {
    id?: number;
    countryId?: number;
  };
}

export const fetchProducts = (page = 1, data: ProductSearchParams) =>
  request.post(`products/search?page=${page || 1}&page_size=20`, data).then((res) => {
    const { content, ...rest } = res.data;
    const products: Product[] = content.map(mapProductDaoToEntity);
    const meta = mapMetaDaoToMetaEntity(rest);

    return {
      products,
      meta,
    };
  });

export const fetchMyProducts = (params: Record<string, string | number>) =>
  request.get('products/my', { params }).then((res) => {
    const { content, ...rest } = res.data;
    const products: Product[] = content.map(mapProductDaoToEntity);
    const meta = mapMetaDaoToMetaEntity(rest);

    return {
      products,
      meta,
    };
  });

export const deleteProduct = (id: number) => request.delete(`products/${id}`);

export const fetchProductById = (id: number) =>
  request.get(`products/${id}`).then((res) => mapProductDaoToEntity(res.data));

export const importProductsFromFile = (fd: FormData) => request.post('products/import', fd);

interface CreateProductDto {
  name: string;
  article?: string;
  barCode?: string;
  description: string;
  subcategoryId: number;
  width?: number;
  height?: number;
  length?: number;
  weight?: number;
  colorId?: number;
  materialId?: number;
  currencyId: number;
  unitId: number;
  prices: { price: number; requiredQuantity: number }[];
  keywords: string[];
  images: string[];
}

export const createProduct = (data: CreateProductDto) =>
  request.post('products', data).then((res) => {
    console.log(res.data);
  });

export const updateProduct = (id: number, data: CreateProductDto) =>
  request.patch(`products/${id}`, data);

export const fetchImportFields = () =>
  request.get('products/import/xlsx/fields').then((res) => res.data);
