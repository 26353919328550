// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import React, { useState } from 'react';
import { Swiper as SwiperClass } from 'swiper';
import { Thumbs } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Document } from 'entities/Document';

import cl from './swiper.module.scss';

import 'swiper/scss';

interface Props {
  images: Document[];
}

const ProductSwiper: React.FC<Props> = ({ images }) => {
  const [thumbsSwiper, setThumbsSwiper] = useState<SwiperClass | null>(null);

  return (
    <div className={cl['product-swiper']}>
      <Swiper
        className={cl['product-swiper__main']}
        modules={[Thumbs]}
        thumbs={{ swiper: thumbsSwiper }}
      >
        {images.map((image, index) => (
          <SwiperSlide key={index}>{<img src={image.link} alt="slide image" />}</SwiperSlide>
        ))}
      </Swiper>

      <Swiper
        className={cl['product-swiper__thumbs']}
        spaceBetween={8}
        slidesPerView={4}
        modules={[Thumbs]}
        watchSlidesProgress
        onSwiper={setThumbsSwiper}
      >
        {images.map((image, index) => (
          <SwiperSlide className={cl['product-swiper__thumb-item']} key={index}>
            {<img src={image.link} alt="slide image" />}
          </SwiperSlide>
        ))}
      </Swiper>
    </div>
  );
};

export default ProductSwiper;
