export default {
  notConnection: 'Праверце злучэнне з інтэрнэтам',
  unknown: 'Што-то пайшло не так',
  server: 'Памылка сервера. Патрэбна звярнуцца ў тэх. падтрымку.',
  wrongAuth: 'Няправільны email ці пароль',
  required: 'Абавязковае поле для запаўнення',
  invalidEmail: 'Некарэктны email',
  minLength: 'Павінна быць даўжэй {{len}} сімвалаў',
  maxLength: 'Павінна быць не даўжэй {{len}} сімвалаў',
  passwordsNotMatch: 'Паролі не супадаюць',
  notCorrectPrice: 'Некарэктная цана',
  notCorrectLink: 'Некарэктная спасылка',
  forbidden: 'У вас недастаткова правоў для гэтага дзеяння',
  phone: 'Некарэктны нумар тэлефона',
  incorrectFormat: 'Файл павінен быць у фармаце {{format}}',
  selectRequiredColumns: 'Не выбраны абавязковыя слупкі',
  readFile: 'Памылка пры чытанні файла',
  emailNotAvailable: 'Email ужо зарэгістраваны ў сістэме',
};
