export default {
  notConnection: 'İnternet bağlantınızı kontrol edin',
  unknown: 'Bir şeyler ters gitti',
  server: 'Sunucu hatası. Teknik destekle iletişime geçmelisiniz.',
  wrongAuth: 'Geçersiz e-posta veya şifre',
  required: 'Doldurulması zorunlu alan',
  invalidEmail: 'Geçersiz e-posta',
  minLength: '{{len}} karakterden uzun olmalıdır',
  maxLength: '{{len}} karakterden fazla olmamalıdır',
  passwordsNotMatch: 'Şifreler eşleşmiyor',
  notCorrectPrice: 'Geçersiz fiyat',
  notCorrectLink: 'Geçersiz bağlantı',
  forbidden: 'Bu işlemi yapmak için yeterli izniniz yok',
  phone: 'Geçersiz telefon numarası',
  incorrectFormat: 'Dosya {{format}} biçiminde olmalıdır',
  selectRequiredColumns: 'Zorunlu sütunlar seçilmemiş',
  readFile: 'Dosya okunurken hata oluştu',
  emailNotAvailable: 'E-posta zaten sistemde kayıtlı',
};
