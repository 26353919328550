import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { ReactComponent as CloseIcon } from 'assets/icons/close.svg';
import { ReactComponent as PlusIcon } from 'assets/icons/plus.svg';
import { ReactComponent as UploadIcon } from 'assets/icons/upload.svg';
import PRFileInput from 'components/PRFileInput';
import { uploadFile } from 'api/repositories/files.repository';
import { Document } from 'entities/Document';

import cl from './product-images.module.scss';

interface Props {
  images: (Document | null)[];
  setImages: (images: (Document | null)[]) => void;
}

const ProductImageForm: React.FC<Props> = ({ images, setImages }) => {
  const { t } = useTranslation();

  const uploadedImages = useMemo(() => images.filter((file) => !!file).length, [images]);

  const onUploadFile = (file: File, position: number) => {
    const fd = new FormData();
    fd.append('file', file);

    uploadFile(fd).then((res) => {
      setImages(images.map((item, idx) => (idx === position ? res : item)));
    });
  };

  const deleteImageOnPosition = (position: number) => {
    setImages(images.map((image, index) => (index === position ? null : image)));
  };

  return (
    <div className={cl['images']}>
      <p className={cl['images__header']}>
        {t('common.images')} ({uploadedImages} из 4)
      </p>

      {images[0] ? (
        <div className={`${cl['images__preview']} ${cl['images__preview--main']}`}>
          <img src={images[0].link} alt={images[0].title} />
          <button
            className={cl['images__delete-btn']}
            type="button"
            onClick={() => deleteImageOnPosition(0)}
          >
            <CloseIcon />
          </button>
        </div>
      ) : (
        <PRFileInput format=".jpg,.jpeg,.png" setFile={(file) => onUploadFile(file, 0)}>
          <div className={cl['images__main']}>
            <p className={cl['images__main-blue']}>
              <UploadIcon />
              <span>{t('action.uploadFile')}</span>
            </p>
            <p>{t('common.supportedFormats', { formats: 'JPG, PNG' })}</p>
            <p>{t('common.maxSize', { size: '5 MB' })}</p>
          </div>
        </PRFileInput>
      )}

      <div className={cl['images__grid']}>
        {images.slice(1).map((image, index) => {
          if (image) {
            return (
              <div key={index} className={cl['images__preview']}>
                <img src={image.link} alt={image.title} />

                <button
                  className={cl['images__delete-btn']}
                  type="button"
                  onClick={() => deleteImageOnPosition(index + 1)}
                >
                  <CloseIcon />
                </button>
              </div>
            );
          }

          return (
            <PRFileInput
              format=".jpg,.jpeg,.png"
              key={index}
              setFile={(file) => onUploadFile(file, index + 1)}
            >
              <PlusIcon />
            </PRFileInput>
          );
        })}
      </div>
    </div>
  );
};

export default ProductImageForm;
