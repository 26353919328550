export default {
  notConnection: 'اتصال به اینترنت را بررسی کنید',
  unknown: 'مشکلی پیش آمده است',
  server: 'خطای سرور. باید به پشتیبانی فنی مراجعه کنید.',
  wrongAuth: 'ایمیل یا رمز عبور نادرست است',
  required: 'فیلد اجباری است',
  invalidEmail: 'ایمیل نامعتبر است',
  minLength: 'باید بیشتر از {{len}} حرف باشد',
  maxLength: 'نباید بیشتر از {{len}} حرف باشد',
  passwordsNotMatch: 'رمز عبورها مطابقت ندارند',
  notCorrectPrice: 'قیمت نادرست است',
  notCorrectLink: 'پیوند نادرست است',
  forbidden: 'شما دسترسی کافی برای این عمل ندارید',
  phone: 'شماره تلفن نادرست است',
  incorrectFormat: 'فایل باید به فرمت {{format}} باشد',
  selectRequiredColumns: 'ستون‌های اجباری انتخاب نشده‌اند',
  readFile: 'خطا در خواندن فایل',
  emailNotAvailable: 'ایمیل در سیستم قبلاً ثبت شده است',
};
