export const clearNonNumericSymbols = (value: string): string => value.replace(/\D/g, '');

export const formatPhoneNumber = (value: string): string => {
  const cleaned = clearNonNumericSymbols(value);

  const fragmented = cleaned.match(/(\d{0,3})(\d{0,3})(\d{0,2})(\d{0,2})/);

  if (!fragmented) return '';

  const codes = fragmented.slice(1, 6).filter((f) => f);

  return codes.length ? `${codes.join(' ')}` : '';
};
