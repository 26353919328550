export default {
  signTo: 'Увійти в систему',
  signUp: 'Створення особистого кабінету',
  passwordHelper: 'Мінімум 8 символів, 1 велика літера, 1 цифра.',
  lastUpdate: 'Останнє оновлення: {{date}}',
  tradingAgency:
    'Представник, який бере на себе зобов’язання встановлювати дистрибуцію в конкретному регіоні за заздалегідь визначеними правилами.',
  saleOfPoint: 'Територіальна присутність товару у конкретного продавця.',
  tradingHouse:
    'Уповноважена компанія від виробника на представлення товару та встановлення правил дистрибуції в конкретному регіоні.',
  uploadPresentationOrFile: 'За потреби завантажте додаткові документи, презентацію або файл',
  usingForSEO:
    'Використовуються для SEO і допомагають підвищити видимість товару в пошукових системах',
  noBranchesYet: 'У вас ще немає доданих філій',
  branchBenefits:
    'Список філій дозволить підвищити довіру та покращити видимість на сайті, а також надає іншим компаніям можливість бачити та контактувати з філіями.',
  activationCodeSend: 'На вашу пошту відправлено код, для завершення реєстрації введіть код у поле',
  resendCodeAfter: 'Відправити код повторно через {{seconds}} секунд',
  codeSent: 'Код відправлено',
  allDataWillBeDeleted: 'Всі ваші дані будуть видалені разом з обліковим записом',
  changePasswordImproveAccount:
    'Оновлення коду доступу до облікового запису з метою підвищення безпеки облікового запису',
  writeEmailForRecovery:
    'Будь ласка, введіть електронну пошту, яка прив’язана до вашого облікового запису.',
  codeForRecovery: 'Код для скидання пароля був відправлений на вашу пошту',
  enterCodeToAccessAccount: 'Введіть код нижче для відновлення доступу до облікового запису',
  createStrongPassword: 'Придумайте надійний пароль для вашого облікового запису',
  infoAboutYourCompany: 'Вкажіть інформацію про вашу компанію',
  iWantCatalogAndMakeSale: 'Я хочу виставляти свій каталог та продавати товари',
  iWantSearchManufacturers: 'Я шукаю виробників для укладення партнерства',
  tradingAgencyDescription:
    'Торгові представництва: це організації, які представляють інтереси та продукцію інших компаній на певній території. Вони можуть здійснювати пошук клієнтів, просування товарів, проведення переговорів та укладення угод від імені представленої компанії. Приклади торгових представництв: агентства з продажу нерухомості, агентства з продажу автомобілів і т.д.',
  tradingHouseDescription:
    'Торгові будинки: це великі торгові організації, які об’єднують декілька торгових точок або магазинів під однією управлінською компанією. Торговий будинок може включати різні відділи та спеціалізовані секції, що пропонують широкий асортимент товарів або послуг. Приклади торгових будинків: універмаги, торгові центри і т.д.',
  tradingPointDescription:
    'Торгові точки: це місця, де товари або послуги продаються безпосередньо споживачам. Це можуть бути магазини, супермаркети, ринки, торгові павільйони і т.д. Торгові точки зазвичай мають фізичне простір, де клієнти можуть придбати товари або отримати послуги.',
};
