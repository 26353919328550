import React, { useState } from 'react';
import { Controller } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { ReactComponent as CloseIcon } from 'assets/icons/close.svg';
import BaseModal from 'components/BaseModal';
import PRAsyncSelect from 'components/PRAsyncSelect';
import PRButton from 'components/PRButton';
import PRInput from 'components/PRInput';
import PRMap from 'components/PRMap';
import { EMAIL_REGEX } from 'types/constants/email.regex';
import { formatPhoneNumber } from 'utils/formatter.util';
import useHandleError from 'hooks/handleError';
import { useNotification } from 'hooks/notification';
import { createBranch, updateBranch } from 'api/repositories/company.repository';
import {
  fetchBranchTypes,
  fetchCities,
  fetchCountries,
  fetchPositions,
} from 'api/repositories/handbooks.repository';
import { Branch } from 'entities/Branch';
import { ErrorResponse } from 'entities/Error';
import { CityOption, CountryOption, TradeOption } from 'entities/Option';

import { CreateBranchForm, useCreateBranch } from '../../hooks/create-branch';

import cl from './add-branch.module.scss';

interface Props {
  branch?: Branch;
  isVisible: boolean;
  changeVisible: (val: boolean) => void;
}

const AddBranchModal: React.FC<Props> = ({ isVisible, changeVisible, branch }) => {
  const { t } = useTranslation();
  const { handleCommonError } = useHandleError();
  const notify = useNotification();

  const [isTradeDescriptionVisible, setTradeDescriptionVisible] = useState(true);

  const {
    control,
    isSubmitting,
    reset,
    handleSubmit,
    cluster,
    center,
    zoom,
    setZoom,
    cityKey,
    selectedCountry,
    selectedTrade,
    resetCity,
    onAddressSelect,
  } = useCreateBranch(branch);

  const onClose = (refresh = false) => {
    changeVisible(refresh);
    reset();
  };

  const onSubmit = async (form: CreateBranchForm) => {
    if (center[0] === 51.128315) {
      notify.error('Вы не выбрали точку на карте');
      return;
    }
    const data = {
      name: form.title,
      typeId: form.trade!.value,
      contactPerson: form.contactPerson,
      ...(form.phoneNumber && { phoneNumber: `${form.country?.phoneCode} ${form.phoneNumber}` }),
      email: form.email,
      countryId: form.country!.value,
      cityId: form.city!.value,
      addressText: form.address,
      latitude: form.coordinates[0],
      longitude: form.coordinates[1],
      ...(form.position && { contactPersonPositionId: form.position.value }),
    };

    try {
      if (branch) {
        await updateBranch(branch.id, data);
      } else {
        await createBranch(data);
      }
      onClose(true);
    } catch (err: unknown) {
      const error = err as ErrorResponse;
      handleCommonError(error);
    }
  };

  if (!isVisible) return null;

  return (
    <BaseModal title={t('common.addingBranch')} onClose={onClose}>
      <form className={cl['add-branch']} onSubmit={handleSubmit(onSubmit)} noValidate>
        <div className={cl['add-branch__first']}>
          <Controller
            control={control}
            name="title"
            rules={{ required: { message: t('error.required'), value: true } }}
            render={({ field: { onChange, onBlur, value, name, ref }, fieldState: { error } }) => (
              <PRInput
                ref={ref}
                label={t('form.title')}
                name={name}
                value={value}
                error={error?.message}
                required
                onChange={onChange}
                onBlur={onBlur}
              />
            )}
          />
          <Controller
            control={control}
            name="trade"
            rules={{ required: { message: t('error.required'), value: true } }}
            render={({ field: { onChange, onBlur, value }, fieldState: { error } }) => (
              <PRAsyncSelect
                value={value}
                label={t('form.branchType')}
                fetchList={fetchBranchTypes}
                error={error?.message}
                searchParams={{ page_size: 6 }}
                defaultOptions
                isClearable
                required
                onChange={(val) => onChange(val as TradeOption)}
                onBlur={onBlur}
              />
            )}
          />
        </div>

        {selectedTrade && isTradeDescriptionVisible ? (
          <div className={cl['add-branch__trade-helper']}>
            <p>{selectedTrade.description}</p>
            <span onClick={() => setTradeDescriptionVisible(false)}>
              {t('action.hide')} <CloseIcon />
            </span>
          </div>
        ) : null}

        <div className={cl['add-branch__third']}>
          <Controller
            control={control}
            name="country"
            rules={{ required: { message: t('error.required'), value: true } }}
            render={({ field: { onChange, onBlur, value }, fieldState: { error } }) => (
              <PRAsyncSelect
                value={value}
                label={t('form.country')}
                fetchList={fetchCountries}
                error={error?.message}
                searchParams={{ page_size: 250 }}
                defaultOptions
                isClearable
                required
                onChange={(val) => {
                  resetCity(val as CountryOption);
                  onChange(val as CountryOption);
                }}
                onBlur={onBlur}
              />
            )}
          />
          <Controller
            control={control}
            name="city"
            rules={{ required: { message: t('error.required'), value: true } }}
            render={({ field: { onChange, onBlur, value }, fieldState: { error } }) => (
              <PRAsyncSelect
                key={cityKey}
                value={value}
                label={t('form.city')}
                fetchList={fetchCities}
                error={error?.message}
                searchParams={{ countryId: selectedCountry?.value, page_size: 10 }}
                isDisabled={!selectedCountry}
                defaultOptions={!!selectedCountry}
                isClearable
                required
                onChange={(val) => onChange(val as CityOption)}
                onBlur={onBlur}
              />
            )}
          />
          <Controller
            control={control}
            name="address"
            rules={{ required: { message: t('error.required'), value: true } }}
            render={({ field: { onChange, onBlur, value, name, ref }, fieldState: { error } }) => (
              <PRInput
                ref={ref}
                label={t('form.street')}
                name={name}
                value={value}
                error={error?.message}
                required
                onChange={onChange}
                onBlur={onBlur}
              />
            )}
          />
        </div>
        <div className={cl['add-branch__second']}>
          <Controller
            control={control}
            name="contactPerson"
            rules={{ required: { message: t('error.required'), value: true } }}
            render={({ field: { onChange, onBlur, value, name, ref }, fieldState: { error } }) => (
              <PRInput
                ref={ref}
                label={t('form.contactPerson')}
                name={name}
                value={value}
                error={error?.message}
                required
                onChange={onChange}
                onBlur={onBlur}
              />
            )}
          />
          <Controller
            control={control}
            name="position"
            rules={{ required: { message: t('error.required'), value: true } }}
            render={({ field: { onChange, onBlur, value }, fieldState: { error } }) => (
              <PRAsyncSelect
                value={value}
                label={t('form.position')}
                fetchList={fetchPositions}
                error={error?.message}
                defaultOptions
                isClearable
                required
                onChange={(val) => onChange(val as CountryOption)}
                onBlur={onBlur}
              />
            )}
          />
          <Controller
            control={control}
            name="email"
            rules={{
              required: { message: t('error.required'), value: true },
              pattern: {
                message: t('error.invalidEmail'),
                value: EMAIL_REGEX,
              },
            }}
            render={({ field: { onChange, onBlur, value, name, ref }, fieldState: { error } }) => (
              <PRInput
                label={t('form.email')}
                ref={ref}
                name={name}
                value={value}
                onChange={onChange}
                onBlur={onBlur}
                error={error?.message}
                required
              />
            )}
          />
          <Controller
            control={control}
            name="phoneNumber"
            rules={{ minLength: { message: t('error.phone'), value: 13 } }}
            render={({ field: { onChange, onBlur, value, name, ref }, fieldState: { error } }) => (
              <PRInput
                ref={ref}
                label={t('form.phoneNumber')}
                name={name}
                value={value}
                error={error?.message}
                prefix={
                  <span className={cl['add-branch__phone-prefix']}>
                    {selectedCountry ? selectedCountry.phoneCode : '+7'}
                  </span>
                }
                onChange={(val) => onChange(formatPhoneNumber(val))}
                onBlur={onBlur}
              />
            )}
          />
        </div>
        <div className={cl['add-branch__map']}>
          <PRMap
            center={center}
            zoom={zoom}
            clusterOptions={cluster ? [cluster] : []}
            searchInputId="address"
            behaviors={['scrollZoom']}
            changeZoom={setZoom}
            selectAddress={onAddressSelect}
          />
        </div>
        <div className={cl['add-branch__footer']}>
          <PRButton loading={isSubmitting} type="submit">
            {t('action.ready')}
          </PRButton>
        </div>
      </form>
    </BaseModal>
  );
};

export default AddBranchModal;
