import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ReactComponent as EmailIcon } from 'assets/icons/email.svg';
import { ReactComponent as MoreIcon } from 'assets/icons/more.svg';
import { ReactComponent as PenIcon } from 'assets/icons/pen.svg';
import { ReactComponent as PhoneIcon } from 'assets/icons/phone.svg';
import { ReactComponent as PinLineIcon } from 'assets/icons/pin-line.svg';
import { ReactComponent as PlusIcon } from 'assets/icons/plus.svg';
import { ReactComponent as TrashIcon } from 'assets/icons/trash.svg';
import { ReactComponent as UserLineIcon } from 'assets/icons/user-line.svg';
import PRButton from 'components/PRButton';
import PRDropdown from 'components/PRDropdown';
import PRLoading from 'components/PRLoading';
import PRTitle from 'components/PRTitle';
import { useConfirmBox } from 'hooks/confirm-box';
import { deleteBranch, fetchCompanyBranches } from 'api/repositories/company.repository';
import { Branch } from 'entities/Branch';

import AddBranchModal from '../../components/AddBranchModal';

import cl from './branches.module.scss';

const CompanyBranches = () => {
  const { t } = useTranslation();
  const { showConfirmBox } = useConfirmBox();
  const [branches, setBranches] = useState<Branch[]>([]);
  const [selectedBranch, setSelectedBranch] = useState<Branch | undefined>();
  const [isLoading, setLoading] = useState(true);
  const [isAddModalVisible, setModalVisible] = useState(false);

  const menuOptions = [
    {
      id: 1,
      title: t('action.edit'),
      icon: <PenIcon />,
      onClick: (id?: number) => {
        if (!id) return;
        setSelectedBranch(branches.find((b) => b.id === id));
        setModalVisible(true);
      },
    },
    {
      id: 2,
      title: t('action.delete'),
      icon: <TrashIcon />,
      styles: { color: '#FF675E' },
      onClick: (id?: number) => {
        if (id) {
          showConfirmBox({
            message: 'Вы действительно хотите удалить филиал?',
            onAccept: () => {
              deleteBranch(id).then(() => {
                fetchBranches();
              });
            },
          });
        }
      },
    },
  ];

  useEffect(() => {
    fetchBranches();
  }, []);

  const onModalClose = (refresh = false) => {
    setSelectedBranch(undefined);
    setModalVisible(false);
    if (refresh) {
      fetchBranches();
    }
  };

  const fetchBranches = () => {
    setLoading(true);
    fetchCompanyBranches()
      .then((res) => {
        setBranches(res);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  if (isLoading) return <PRLoading />;

  return (
    <div className={cl['branches']}>
      {branches.length ? (
        <>
          <div className={cl['branches__header']}>
            <PRTitle tag="h2">{t('common.branches')}</PRTitle>
            <PRButton prefix={<PlusIcon />} onClick={() => setModalVisible(true)}>
              {t('action.addBranches')}
            </PRButton>
          </div>
          <div className={cl['branches__list']}>
            {branches.map((branch) => (
              <div key={branch.id} className={cl['branches__item']}>
                <div className={cl['branches__item-header']}>
                  <div className={cl['branches__trade']}>
                    <div className={cl['branches__trade-img']}>
                      <img src={branch.trade.logo?.link} alt="" />
                    </div>
                    <p>{branch.trade.label}</p>
                  </div>

                  <PRDropdown targetId={branch.id} options={menuOptions}>
                    <MoreIcon />
                  </PRDropdown>
                </div>
                <PRTitle className={cl['branches__title']} tag="h3">
                  {branch.title}
                </PRTitle>
                <p className={cl['branches__info']}>
                  <UserLineIcon />
                  {branch.contactPerson}
                </p>
                {branch.phone ? (
                  <p className={cl['branches__info']}>
                    <PhoneIcon />
                    {branch.phone}
                  </p>
                ) : null}
                {branch.email ? (
                  <p className={cl['branches__info']}>
                    <EmailIcon />
                    {branch.email}
                  </p>
                ) : null}
                <p className={cl['branches__info']}>
                  <PinLineIcon />
                  {branch.address}
                </p>
              </div>
            ))}
          </div>
        </>
      ) : (
        <div className={cl['branches__empty']}>
          <div>
            <PRTitle tag="h3">{t('message.noBranchesYet')}</PRTitle>
            <p>{t('message.branchBenefits')}</p>
          </div>
          <PRButton prefix={<PlusIcon />} onClick={() => setModalVisible(true)}>
            {t('action.addBranches')}
          </PRButton>
        </div>
      )}
      <AddBranchModal
        isVisible={isAddModalVisible}
        branch={selectedBranch}
        changeVisible={onModalClose}
      />
    </div>
  );
};

export default CompanyBranches;
