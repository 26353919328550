import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import PRButton from 'components/PRButton';
import PRSelect from 'components/PRSelect';
import PRTitle from 'components/PRTitle';
import useHandleError from 'hooks/handleError';
import { useNotification } from 'hooks/notification';
import { useReadExel } from 'hooks/readExel';
import { importProductsFromFile } from 'api/repositories/products.repository';
import { ErrorResponse } from 'entities/Error';
import { Option } from 'entities/Option';

import { useImportFields } from '../../hooks/importFields';

import cl from './second.module.scss';

interface Props {
  file: File;
}

const ImportProductsSecond: React.FC<Props> = ({ file }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const notify = useNotification();
  const { handleCommonError } = useHandleError();

  const rows = useReadExel(file);
  const { fields, setSelectedFields, prepareData } = useImportFields();

  useEffect(() => {
    if (rows.length) {
      setSelectedFields(new Array(rows[0].length).fill(-1));
    }
  }, [rows]);

  const onFieldSelect = (val: unknown, column: number) => {
    const option = val as Option | null;

    setSelectedFields((value) =>
      value.map((id, index) => (index === column ? (option ? option.value : -1) : id))
    );
  };

  const onSubmit = () => {
    const data = prepareData();

    if (!data) return;

    const fd = new FormData();
    fd.append('fields', JSON.stringify(data));
    fd.append('file', file);

    importProductsFromFile(fd)
      .then(() => {
        notify.success('Все позиции удачно импотированы');
        navigate('/company/catalog');
      })
      .catch((err: unknown) => {
        const error = err as ErrorResponse;
        handleCommonError(error);
      });
  };

  if (!rows.length) {
    return <div></div>;
  }

  return (
    <div className={cl['second']}>
      <div className={cl['second__header']}>
        <PRTitle tag="h3">{t('common.productList')}</PRTitle>
        <PRButton onClick={onSubmit}>{t('action.continue')}</PRButton>
      </div>

      <div className={cl['second__table']}>
        <div
          className={cl['second__row']}
          style={{ gridTemplateColumns: `repeat(${rows[0].length}, 300px)` }}
        >
          {rows[0].map((row, colIndex) => (
            <PRSelect
              key={colIndex}
              options={fields}
              placeholder={t('form.selectColumnName')}
              styles={{
                control: (baseStyles) => ({
                  ...baseStyles,
                  padding: '4px',
                  backgroundColor: '#F7F9FB',
                  borderColor: '#F7F9FB',
                  boxShadow: 'none',
                  '&:hover': {
                    borderWidth: '1px',
                  },
                }),
              }}
              onChange={(val) => onFieldSelect(val, colIndex)}
            />
          ))}
        </div>

        {rows.slice(1, 11).map((row, rowIndex) => (
          <div
            key={rowIndex}
            className={`${cl['second__row']} ${
              rowIndex % 2 ? cl['second__row--odd'] : cl['second__row--even']
            }`}
            style={{ gridTemplateColumns: `repeat(${row.length}, 300px)` }}
          >
            {row.map((cell, colIndex) => (
              <span className={cl['second__cell']} key={colIndex}>
                {cell && cell.toString()}
              </span>
            ))}
          </div>
        ))}
      </div>
    </div>
  );
};

export default ImportProductsSecond;
