import { Product } from 'entities/Product';

import { ProductDao } from '../dao/Product.dao';

import { mapDocumentDaoToEntity } from './document.mappers';
import { mapManufacturerDaoToEntity } from './manufacturer.mappers';
import { mapOptionDaoToEntity, mapUnitOptionToEntity } from './option.mappers';

export const mapProductDaoToEntity = (product: ProductDao): Product => ({
  id: product.id,
  title: product.name,
  images: product.images.map(mapDocumentDaoToEntity),
  description: product.description,
  prices: product.prices.map((p) => ({
    price: p.price.toString(),
    quantityFrom: p.requiredQuantity.toString(),
  })),
  vendorCode: product.article,
  barcode: product.barCode,
  bookmarked: false,
  subcategory: mapOptionDaoToEntity(product.subcategory),
  category: mapOptionDaoToEntity(product.subcategory.category),
  workingArea: product.subcategory.category.industry
    ? mapOptionDaoToEntity(product.subcategory.category.industry)
    : null,
  currency: {
    value: product.currency.id,
    label: product.currency.name,
    code: product.currency.code,
  },
  color: product.color ? mapOptionDaoToEntity(product.color) : null,
  material: product.material ? mapOptionDaoToEntity(product.material) : null,
  unitType: mapUnitOptionToEntity(product.unit),
  searchTerms: product.keywords,
  width: product.width,
  length: product.length,
  weight: product.weight,
  height: product.height,
  manufacturer: product.manufacturer ? mapManufacturerDaoToEntity(product.manufacturer) : null,
  createdAt: new Date(product.createdDate),
  updatedAt: new Date(product.lastModifiedDate),
});
