export default {
  notConnection: 'Sin conexión a internet',
  unknown: 'Algo salió mal',
  server: 'Error de servidor. Debe ponerse en contacto con el soporte técnico.',
  wrongAuth: 'Correo electrónico o contraseña incorrectos',
  required: 'Campo obligatorio',
  invalidEmail: 'Correo electrónico no válido',
  minLength: 'Debe tener al menos {{len}} caracteres',
  maxLength: 'No debe exceder de {{len}} caracteres',
  passwordsNotMatch: 'Las contraseñas no coinciden',
  notCorrectPrice: 'Precio incorrecto',
  notCorrectLink: 'Enlace incorrecto',
  forbidden: 'No tiene permisos suficientes para realizar esta acción',
  phone: 'Número de teléfono no válido',
  incorrectFormat: 'El archivo debe estar en formato {{format}}',
  selectRequiredColumns: 'No se han seleccionado las columnas obligatorias',
  readFile: 'Error al leer el archivo',
  emailNotAvailable: 'El correo electrónico ya está registrado en el sistema',
};
