export default {
  change: 'Озгартириш',
  delete: 'Ўчириш',
  find: 'Қидирув',
  reset: 'Қайта тиклаш',
  save: 'Сақлаш',
  edit: 'Таҳрирлаш',
  cancel: 'Бекор қилиш',
  accept: 'Қабул қилиш',
  login: 'Кириш',
  logout: 'Чиқиш',
  signup: 'Аккаунт яратиш',
  signin: 'Аутентификация қилиш',
  next: 'Кейинги',
  seekManufacturer: 'Ишчи найти кўриш',
  seekProduct: 'Маҳсулотни излаш',
  back: 'Орқага',
  forward: 'Илова',
  becomeAPartner: 'Хамкор бўлиш',
  contactWithManufacturer: 'Ишловчи билан боғланиш',
  uploadFile: 'Файлни юклаш',
  sendRequest: 'Сўров юбориш',
  importProducts: 'Маҳсулотларни импорт қилиш',
  addProduct: 'Маҳсулот қўшиш',
  continue: 'Давом эттириш',
  addPrice: 'Нархни қўшиш',
  removePrice: 'Нархни ўчириш',
  saveChanges: 'Ўзгаришларни сақлаш',
  leaveAsDraft: 'Қоралама қилиш',
  close: 'Ёпиш',
  ready: 'Тайёр',
  addBranches: 'Филиалларни қўшиш',
  resendActivationCode: 'Активлаш кодини кайтариш',
  sendForVerification: 'Тасдиқлаш учун юбориш',
  deleteAccount: 'Аккаунтни ўчириш',
  changePassword: 'Паролни ўзгартириш',
  hide: 'Ёпиш',
  expand: 'Кенгайтириш',
  orderProduct: 'Маҳсулот буюртиш',
  writeToManufacturer: 'Ишловчига хат ёзиш',
  goToCatalog: 'Каталогга ўтиш',
  confirm: 'Қабул қилиш',
  yes: 'Ҳа',
  no: 'Йўқ',
  selectChat: 'Чатни танлаш',
  signIn: 'Кириш',
  recover: 'Қайта тиклаш',
  create: 'Яратиш',
  uploadNewPhoto: 'Янги расм юклаш',
  verifyCompany: 'Компанияни тасдиқлаш',
};
