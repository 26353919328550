import React from 'react';
import { Navigate, NavLink, Outlet } from 'react-router-dom';
import { ReactComponent as FullLogo } from 'assets/images/full-logo.svg';
import { useAppSelector } from 'hooks/redux';

import PRLangSelect from '../components/PRLangSelect';

const Auth = () => {
  const { isLoading, user } = useAppSelector((state) => state.auth);

  if (isLoading) {
    return <span>Loading...</span>;
  }

  if (user) {
    return <Navigate to="/" />;
  }

  return (
    <div className="auth">
      <div className="auth__header">
        <div className="auth__container">
          <NavLink to="/">
            <FullLogo className="auth__logo" />
          </NavLink>

          <PRLangSelect />
        </div>
      </div>
      <div className="auth__body">
        <div className="auth__left">
          <Outlet />
        </div>
        <div className="auth__right"></div>
      </div>
    </div>
  );
};

export default Auth;
