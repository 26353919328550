import React from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import PRButton from 'components/PRButton';
import PRInput from 'components/PRInput';
import PRTitle from 'components/PRTitle';
import { registrationSlice } from 'store/registration';
import useHandleError from 'hooks/handleError';
import { useNotification } from 'hooks/notification';
import { useAppDispatch, useAppSelector } from 'hooks/redux';
import { resendActivationCode, verifyEmail } from 'api/repositories/users.repository';
import { ErrorResponse } from 'entities/Error';

import { useTimer } from '../../hooks/timer';

import cl from './fourth-step.module.scss';

const RegistrationFourthStep = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const { handleCommonError } = useHandleError();
  const { first, second } = useAppSelector((state) => state.registration);
  const notify = useNotification();
  const { seconds, restartTimer } = useTimer();

  const {
    control,
    formState: { isSubmitting },
    handleSubmit,
  } = useForm({
    defaultValues: {
      code: '',
    },
  });

  const resendCode = () => {
    resendActivationCode(first.email)
      .then(() => {
        restartTimer();
        notify.success(t('message.codeSent'));
      })
      .catch((err: unknown) => {
        const error = err as ErrorResponse;
        handleCommonError(error);
      });
  };

  const onSubmit = ({ code }: { code: string }) => {
    const data = {
      email: first.email || second.email,
      code,
    };

    verifyEmail(data)
      .then(() => {
        dispatch(registrationSlice.actions.resetState());
        navigate('/auth/login');
      })
      .catch((err: unknown) => {
        const error = err as ErrorResponse;
        handleCommonError(error);
      });
  };

  return (
    <form className={cl['fourth-step']} onSubmit={handleSubmit(onSubmit)}>
      <PRTitle tag="h1">{t('common.accountActivation')}</PRTitle>

      <p className={cl['fourth-step__text']}>{t('message.activationCodeSend')}:</p>

      <div className={cl['fourth-step__input']}>
        <Controller
          control={control}
          name="code"
          rules={{ required: { message: t('error.required'), value: true } }}
          render={({ field: { onChange, onBlur, value, name, ref }, fieldState: { error } }) => (
            <PRInput
              ref={ref}
              label={t('form.code')}
              name={name}
              value={value}
              error={error?.message}
              required
              onChange={onChange}
              onBlur={onBlur}
            />
          )}
        />
      </div>

      <div className={cl['fourth-step__countdown']}>
        {seconds > 0 ? (
          <p>{t('message.resendCodeAfter', { seconds })}</p>
        ) : (
          <PRButton variant="text" onClick={resendCode}>
            {t('action.resendActivationCode')}
          </PRButton>
        )}
      </div>

      <div className={cl['fourth-step__submit']}>
        <PRButton loading={isSubmitting} type="submit">
          {t('action.continue')}
        </PRButton>
      </div>
    </form>
  );
};

export default RegistrationFourthStep;
