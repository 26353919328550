export default {
  notConnection: 'Интернет байланысын тексеріңіз',
  unknown: 'Қате орын алды',
  server: 'Сервердегі қате. Техникалық қолдауға хабарласу керек.',
  wrongAuth: 'Електрондық пошта немесе құпия сөз дұрыс емес',
  required: 'Толтыру қажет',
  invalidEmail: 'Электрондық пошта дұрыс емес',
  minLength: '{{len}} символдан кем болмауы керек',
  maxLength: '{{len}} таңбадан аспауы тиіс',
  passwordsNotMatch: 'Құпия сөздер сәйкес келмейді',
  notCorrectPrice: 'бағасы дұрыс емес',
  notCorrectLink: 'сілтеме дұрыс емес',
  forbidden: 'Сізге бұл әрекетті орындау құқығы жеткіліксіз',
  phone: 'телефон нөмірі дұрыс емес ',
  incorrectFormat: 'Файл {{format}} пішімінде болуы тиіс',
  selectRequiredColumns: 'Міндетті бағандар таңдалмады',
  readFile: 'Файлды оқу кезінде қате',
  emailNotAvailable: 'Бұл электрондық пошта уже тіркеулі екен',
};
