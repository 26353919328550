import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useOutletContext } from 'react-router-dom';
import { ReactComponent as RefreshIcon } from 'assets/icons/refresh.svg';
import PRTitle from 'components/PRTitle';
import { Manufacturer } from 'entities/Manufacturer';

import cl from './about.module.scss';

const About = () => {
  const { t } = useTranslation();
  const [lastUpdate, setLastUpdate] = useState('');
  const manufacturer = useOutletContext<Manufacturer>();

  const certificates = [
    'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcS6YUPNKV1KbH0UF_OJIwBauGVpFdny-LpV1P38-O2PSqKbBniqRut5hfLUk_ToQ5r-KjM&usqp=CAU',
    'https://media.istockphoto.com/id/1312924009/vector/professional-certificate-of-appreciation-golden-template-design.jpg?s=612x612&w=0&k=20&c=lM4Xf0JoWggAkuzw7youwvJBjw7hQUC2XZ9jF8vpLBk=',
    'https://piktochart.com/wp-content/uploads/2022/11/large-156.jpg',
    'https://d1csarkz8obe9u.cloudfront.net/posterpreviews/certificate-design-template-5d1fcdd167093054820c47a4c90226ab_screen.jpg?ts=1638283536',
  ];

  useEffect(() => {
    setLastUpdate(
      new Date().toLocaleDateString('ru-RU', {
        day: 'numeric',
        month: 'short',
        year: 'numeric',
      })
    );
  }, [manufacturer]);

  const registeredText = useMemo(() => {
    const today = new Date();
    const regDate = new Date('2015-03-11');
    const diff = today.getFullYear() - regDate.getFullYear();

    return (
      regDate.toLocaleDateString('ru-RU', {
        day: 'numeric',
        month: 'short',
        year: 'numeric',
      }) + ` (${t('common.yearsAgo', { num: diff })})`
    );
  }, [manufacturer]);

  return (
    <div className={cl.about}>
      <div className={cl['card']}>
        <div className={cl['card__header']}>
          <PRTitle tag="h3">{t('common.summary')}</PRTitle>
          <div className={cl['card__update']}>
            <RefreshIcon />
            <p>
              {t('message.lastUpdate', {
                date: lastUpdate,
              })}
            </p>
          </div>
        </div>
        <div className={cl['card__row']}>
          <p>{t('common.title')}</p>
          <p>{manufacturer.title}</p>
        </div>
        <div className={cl['card__row']}>
          <p>{t('common.brandName')}</p>
          <p>{manufacturer.title}</p>
        </div>
        <div className={cl['card__row']}>
          <p>{t('common.jurisdiction')}</p>
          <p>{manufacturer.country.title}</p>
        </div>
        <div className={cl['card__row']}>
          <p>{t('common.legalAddress')}</p>
          <p>{manufacturer.address}</p>
        </div>
        <div className={cl['card__row']}>
          <p>{t('common.regNumberBin')}</p>
          <p>{manufacturer.bin}</p>
        </div>
        <div className={cl['card__row']}>
          <p>{t('common.registrationDate')}</p>
          <p>{registeredText}</p>
        </div>
        <div className={cl['card__row']}>
          <p>{t('common.sinceOnSite')}</p>
          <p>{manufacturer.createdAt.toLocaleDateString()}</p>
        </div>
      </div>

      <div className={cl['card']}>
        <div className={cl['card__header']}>
          <PRTitle tag="h3">{t('common.certificates')}</PRTitle>
        </div>
        <div className={cl['card__certificates']}>
          {manufacturer.certificates.map((cert, index) => (
            <div key={index} className={cl['card__certificate']}>
              <img src={cert.link} alt="" />
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default About;
