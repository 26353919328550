import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ReactComponent as CheckIcon } from 'assets/icons/check.svg';
import { ReactComponent as CloseIcon } from 'assets/icons/close.svg';
import { ReactComponent as UploadIcon } from 'assets/icons/upload.svg';
import BaseModal from 'components/BaseModal';
import PRButton from 'components/PRButton';
import PRFileInput from 'components/PRFileInput';
import useHandleError from 'hooks/handleError';
import { useNotification } from 'hooks/notification';
import { uploadMultipleFiles } from 'api/repositories/files.repository';
import { verifyOrganization } from 'api/repositories/users.repository';
import { ErrorResponse } from 'entities/Error';

import cl from './verification-docs.module.scss';

interface Props {
  isVisible: boolean;
  changeVisible: (val: boolean) => void;
}

const VerificationDocsModal: React.FC<Props> = ({ isVisible, changeVisible }) => {
  const { t } = useTranslation();
  const notify = useNotification();
  const { handleCommonError } = useHandleError();

  const docsList = [
    t('common.companyRegCertificate'),
    t('common.companyCharter'),
    t('common.certificateTaxReg'),
    t('common.productionDoc'),
    t('common.qualityCertificate'),
    t('common.licensesAndCertificates'),
    t('common.suppliersAndCustomersContracts'),
  ];
  const [isLoading, setLoading] = useState(false);
  const [files, setFiles] = useState<File[]>([]);

  const removeFile = (file: File) => {
    setFiles((val) => val.filter((f) => f !== file));
  };

  const onClose = () => {
    changeVisible(false);
  };

  const onSubmit = async () => {
    if (files.length === 0) {
      notify.error('Загруите файлы');
      return;
    }
    const fd = new FormData();
    for (const file of files) {
      fd.append('files', file);
    }

    setLoading(true);
    try {
      const documents = await uploadMultipleFiles(fd);
      await verifyOrganization(documents.map((doc) => doc.id));
      notify.success('Документы отправлены на проверку');
      onClose();
    } catch (err: unknown) {
      const error = err as ErrorResponse;
      handleCommonError(error);
    } finally {
      setLoading(false);
    }
  };

  if (!isVisible) return null;
  return (
    <BaseModal title={t('common.requirementsForVerification')} onClose={onClose}>
      <div className={cl['verification']}>
        <p className={cl['verification__text']}>{t('common.submitFollowingDocuments')}:</p>

        <div className={cl['verification__list']}>
          {docsList.map((doc, index) => (
            <div key={index} className={cl['verification__doc']}>
              <CheckIcon />
              <p>{doc}</p>
            </div>
          ))}
        </div>

        <PRFileInput
          format={'.pdf, .word, .doc, .docx'}
          setFile={(file) => setFiles((val) => [...val, file])}
        >
          <div className={cl['upload-file']}>
            <p>{t('common.supportedFormats', { formats: 'pdf, word, doc' })}</p>

            <p className={cl['upload-file__blue']}>
              <UploadIcon />
              <span>{t('action.uploadFile')}</span>
            </p>
          </div>
        </PRFileInput>

        <div className={cl['verification__result']}>
          {files.map((file) => (
            <div key={file.lastModified} className={cl['verification__file']}>
              <p>{file.name}</p>
              <button onClick={() => removeFile(file)}>
                <CloseIcon />
              </button>
            </div>
          ))}
        </div>

        <div className={cl['verification__footer']}>
          <PRButton loading={isLoading} onClick={onSubmit}>
            {t('action.sendForVerification')}
          </PRButton>
        </div>
      </div>
    </BaseModal>
  );
};

export default VerificationDocsModal;
