export default {
  change: 'Змінити',
  delete: 'Видалити',
  find: 'Знайти',
  reset: 'Скинути',
  save: 'Зберегти',
  edit: 'Редагувати',
  cancel: 'Скасувати',
  accept: 'Погоджуюсь',
  login: 'Увійти',
  logout: 'Вийти',
  signup: 'Створити аккаунт',
  signin: 'Авторизуватися',
  next: 'Далі',
  seekManufacturer: 'Шукаю виробника',
  seekProduct: 'Шукаю товар',
  back: 'Назад',
  forward: 'Вперед',
  becomeAPartner: 'Стати партнером',
  contactWithManufacturer: 'Зв язатися з виробником',
  uploadFile: 'Завантажити файл',
  sendRequest: 'Відправити запит',
  importProducts: 'Імпорт товарів',
  addProduct: 'Додати товар',
  continue: 'Продовжити',
  addPrice: 'Додати ціну',
  removePrice: 'Видалити ціну',
  saveChanges: 'Зберегти зміни',
  leaveAsDraft: 'Залишити як чернетку',
  close: 'Закрити',
  ready: 'Готово',
  addBranches: 'Додати філії',
  resendActivationCode: 'Відправити код повторно',
  sendForVerification: 'Відправити на перевірку',
  deleteAccount: 'Видалити аккаунт',
  changePassword: 'Змінити пароль',
  hide: 'Сховати',
  expand: 'Розгорнути',
  orderProduct: 'Замовити товар',
  writeToManufacturer: 'Написати виробнику',
  goToCatalog: 'Перейти в каталог',
  confirm: 'Підтвердити',
  yes: 'Так',
  no: 'Ні',
  selectChat: 'Виберіть чат',
  signIn: 'Увійти',
  recover: 'Відновити',
  create: 'Створити',
  uploadNewPhoto: 'Завантажити нове фото',
  verifyCompany: 'Підтвердити компанію',
};
