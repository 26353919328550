import { Branch } from 'entities/Branch';
import { Manufacturer } from 'entities/Manufacturer';

import { BranchDao } from '../dao/BranchDao';
import { ManufacturerDao } from '../dao/Manufacturer.dao';

import { mapDocumentDaoToEntity } from './document.mappers';
import {
  mapCityOptionToEntity,
  mapCountryOptionToEntity,
  mapOptionDaoToEntity,
  mapTradeOptionToEntity,
} from './option.mappers';

export const mapManufacturerDaoToEntity = (manufacturer: ManufacturerDao): Manufacturer => ({
  id: manufacturer.id,
  title: manufacturer.name,
  description: manufacturer.description,
  bin: manufacturer.taxId,
  logo: manufacturer.logo?.viewLink,
  verified: manufacturer.isVerified,
  address: manufacturer.legalAddress,
  phone: manufacturer.phoneNumber,
  website: manufacturer.website,
  country: {
    id: manufacturer.country.id,
    title: manufacturer.country.name,
  },
  workingArea: manufacturer.industry ? mapOptionDaoToEntity(manufacturer.industry) : null,
  certificates: manufacturer.certificates
    ? manufacturer.certificates.map(mapDocumentDaoToEntity)
    : [],
  createdAt: new Date(manufacturer.registrationDate),
  updatedAt: manufacturer.updated_at,
  deletedAt: manufacturer.deleted_at,
});

export const mapManufacturerBranchToEntity = (branch: BranchDao): Branch => ({
  id: branch.id,
  title: branch.name,
  contactPerson: branch.contactPerson,
  address: branch.addressText,
  trade: mapTradeOptionToEntity(branch.type),
  phone: branch.phoneNumber,
  email: branch.email,
  coordinates: [branch.latitude, branch.longitude],
  country: mapCountryOptionToEntity(branch.country),
  city: mapCityOptionToEntity(branch.city),
  position: branch.contactPersonPosition
    ? mapOptionDaoToEntity(branch.contactPersonPosition)
    : null,
  manufacturer: mapManufacturerDaoToEntity(branch.organization),
  createdAt: branch.createdAt,
  updatedAt: branch.updatedAt,
});
