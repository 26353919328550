export default {
  main: 'صفحه اصلی',
  loading: 'در حال بارگذاری...',
  listEmpty: 'لیست خالی است',
  attention: 'توجه',
  services: 'خدمات',
  applications: 'درخواست‌ها',
  partnership: 'شراکت',
  branches: 'شعبه‌ها',
  catalog: 'فهرست',
  faq: 'سوالات متداول',
  companyData: 'اطلاعات شرکت',
  employeeData: 'اطلاعات نماینده',
  accountActivation: 'فعال‌سازی حساب کاربری',
  aboutPlatform: 'درباره پلتفرم',
  manageCompany: 'مدیریت شرکت',
  tradingAgency: 'نمایندگی تجاری',
  saleOfPoint: 'نقطه فروش',
  tradingHouse: 'خانه تجاری',
  manufacturerName: 'نام تولید کننده',
  productName: 'نام محصول',
  priceFrom: 'قیمت از {{from}}',
  aboutManufacturer: 'درباره تولید کننده',
  contacts: 'تماس',
  manufacturers: 'تولید کنندگان',
  summary: 'خلاصه',
  certificates: 'گواهینامه‌ها',
  yearsAgo: '{{num}} سال پیش',
  title: 'عنوان',
  brandName: 'نام برند',
  legalAddress: 'آدرس حقوقی',
  jurisdiction: 'حیطه قضائی',
  regNumberBin: 'شماره ثبت/باین',
  registrationDate: 'تاریخ ثبت',
  sinceOnSite: 'از تاریخ ورود به سایت',
  product: 'محصول',
  vendorCode: 'کد فروشنده',
  date: 'تاریخ',
  display: 'نمایش',
  inStock: 'موجود در انبار',
  actions: 'عملیات',
  required: 'الزامی',
  optional: 'اختیاری',
  productList: 'لیست محصولات',
  barcode: 'بارکد یا UPC/EAN',
  color: 'رنگ',
  material: 'مواد',
  currency: 'واحد پول',
  unitType: 'واحد اندازه‌گیری',
  status: 'وضعیت',
  wholesalePrice: 'قیمت عمده فروشی',
  from: 'از',
  mainInfo: 'اطلاعات اصلی',
  productDescription: 'توضیحات محصول',
  dimensions: 'ابعاد',
  characteristics: 'ویژگی‌ها',
  price: 'قیمت',
  createAndUpdate: 'ایجاد و به‌روزرسانی',
  created: 'ایجاد شده',
  updated: 'به‌روزرسانی شده',
  searchTerms: 'عبارات جستجو',
  supportedFormats: 'فرمت‌های پشتیبانی شده: {{formats}}',
  maxSize: 'حداکثر اندازه: {{size}}',
  images: 'تصاویر',
  agency: 'نمایندگی',
  password: 'رمز عبور',
  addingBranch: 'افزودن شعبه',
  questionAndAnswer: 'سوالات و پاسخ‌ها',
  profile: 'پروفایل',
  settings: 'تنظیمات',
  requirementsForVerification: 'الزامات برای تأیید',
  submitFollowingDocuments: 'لطفاً اسناد زیر را ارسال کنید',
  companyRegCertificate: 'گواهی ثبت شرکت',
  companyCharter: 'اساسنامه شرکت',
  certificateTaxReg: 'گواهی مالیاتی',
  productionDoc: 'مدارک تولید',
  qualityCertificate: 'گواهی کیفیت و انطباق',
  licensesAndCertificates: 'مجوزها و گواهی‌نامه‌ها',
  suppliersAndCustomersContracts: 'قراردادها با تأمین‌کنندگان و مشتریان',
  accountDeleting: 'حذف حساب کاربری',
  passwordChanging: 'تغییر رمز عبور',
  today: 'امروز',
  weight: 'وزن',
  availableOnOrder: 'موجود درخواستی',
  manufacturerOtherProducts: 'سایر محصولات تولید کننده',
  lastActivity: 'آخرین فعالیت',
  passwordRecovery: 'بازیابی رمز عبور',
  forgotPassword: 'رمز عبور خود را فراموش کرده‌اید؟',
  haveNotAccount: 'حساب کاربری ندارید؟',
  createPassword: 'ایجاد رمز عبور',
  registration: 'ثبت‌نام',
  haveAnAccount: 'حساب کاربری دارید؟',
  iAmManufacturer: 'من تولید کننده هستم',
  iAmUser: 'من کاربر هستم',
  aboutCompany: 'درباره شرکت',
  logo: 'لوگو',
  personalData: 'اطلاعات شخصی',
  notifications: 'اعلان‌ها',
  todayAt: 'امروز در {{date}}',
  yesterdayAt: 'دیروز در {{date}}',
  all: 'همه',
  system: 'سیستمی',
  notSpecified: 'مشخص نشده',
};
