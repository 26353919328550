export default {
  signTo: 'تسجيل الدخول إلى النظام',
  signUp: 'إنشاء حساب جديد',
  passwordHelper: 'على الأقل 8 أحرف، 1 حرف كبير، 1 رقم.',
  lastUpdate: 'آخر تحديث: {{date}}',
  tradingAgency:
    'وكيل تجاري: شخص ملتزم ببناء توزيع المنتجات في منطقة محددة وفقًا لقواعد محددة مسبقًا.',
  saleOfPoint: 'بيع المنتج في موقع معين للبائع.',
  tradingHouse:
    'البيت التجاري: الشركة المعتمدة من الشركة المصنعة لتمثيل المنتج وبناء قواعد التوزيع في منطقة محددة.',
  uploadPresentationOrFile: 'إذا كان ذلك ضروريًا، قم بتحميل مستندات إضافية أو عرض أو ملف.',
  usingForSEO: 'تُستخدم لأغراض تحسين محركات البحث وتعزيز رؤية المنتج في محركات البحث.',
  noBranchesYet: 'ليس لديك فروع مضافة بعد.',
  branchBenefits:
    'قائمة الفروع ستساعد في زيادة الثقة وتحسين الرؤية على الموقع، وتوفير فرص للشركات الأخرى لرؤية والتواصل مع الفروع.',
  activationCodeSend: 'تم إرسال رمز التنشيط إلى بريدك الإلكتروني، يرجى إدخال الرمز في الحقل.',
  resendCodeAfter: 'إعادة إرسال الرمز بعد {{seconds}} ثانية',
  codeSent: 'تم إرسال الرمز',
  allDataWillBeDeleted: 'سيتم حذف جميع بياناتك مع حسابك.',
  changePasswordImproveAccount: 'تغيير كلمة المرور لتعزيز أمان الحساب',
  writeEmailForRecovery: 'الرجاء إدخال عنوان البريد الإلكتروني المرتبط بحسابك.',
  codeForRecovery: 'تم إرسال رمز لاستعادة كلمة المرور إلى بريدك الإلكتروني.',
  enterCodeToAccessAccount: 'أدخل الرمز أدناه للوصول إلى حسابك.',
  createStrongPassword: 'قم بإنشاء كلمة مرور قوية لحسابك.',
  infoAboutYourCompany: 'حدد معلومات عن شركتك.',
  iWantCatalogAndMakeSale: 'أرغب في عرض الكتالوج وبيع المنتجات.',
  iWantSearchManufacturers: 'أنا أبحث عن مصنعين للشراكة.',
  tradingAgencyDescription:
    'وكالات التجارة: هذه هي المؤسسات التي تمثل مصالح ومنتجات شركات أخرى في منطقة معينة. يمكن أن تقوم بالبحث عن العملاء، وتسويق المنتجات، وإجراء المفاوضات وإبرام الصفقات نيابة عن الشركة التي تمثلها. أمثلة على وكالات التجارة: وكالات بيع العقارات، ووكالات بيع السيارات، وغيرها.',
  tradingHouseDescription:
    'البيوت التجارية: هذه هي المؤسسات التجارية الكبيرة التي تجمع بين عدة نقاط بيع أو متاجر تحت إدارة واحدة. يمكن أن يتضمن البيت التجاري أقسامًا متعددة وأقسامًا متخصصة تقدم مجموعة واسعة من المنتجات أو الخدمات. أمثلة على البيوت التجارية: الأسواق الكبرى، ومراكز التسوق، وغيرها.',
  tradingPointDescription:
    'نقاط البيع: هذه هي الأماكن التي يتم بيع المنتجات أو الخدمات فيها مباشرة للعملاء. يمكن أن تكون متاجر، وسوبرماركت، وأسواق، وأكشاك تجارية، وما إلى ذلك. عادةً ما تحتوي نقاط البيع على مساحة جسدية حيث يمكن للعملاء شراء المنتجات أو الاستفادة من الخدمات.',
};
