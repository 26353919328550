import React from 'react';
import { useTranslation } from 'react-i18next';
import { NavLink } from 'react-router-dom';
import { ReactComponent as BookmarkIcon } from 'assets/icons/bookmark-full.svg';
import { Product } from 'entities/Product';

import PRTitle from '../PRTitle';

import cl from './product.module.scss';

interface Props {
  product: Product;
  toggleBookmark: (id: number) => void;
}

const PRProduct: React.FC<Props> = ({ product, toggleBookmark }) => {
  const { t } = useTranslation();

  return (
    <div className={cl['product-item']} key={product.id}>
      <button
        className={`${cl['product-item__bookmark']} ${
          product.bookmarked ? cl['product-item__bookmark--active'] : ''
        }`}
        onClick={() => toggleBookmark(product.id)}
      >
        <BookmarkIcon />
      </button>

      <div className={cl['product-item__image']}>
        {product.images.length ? (
          <img src={product.images[0].link} alt={product.title} />
        ) : (
          <img src={require('assets/images/empty.png')} alt="" />
        )}
      </div>

      <NavLink to={`/products/${product.id}`} className={cl['product-item__link']}>
        <PRTitle className={cl['product-item__title']} tag="h3">
          {product.title}
        </PRTitle>
      </NavLink>

      <div className={cl['product-item__prices']}>
        {product.prices.map((price) => (
          <div key={price.price}>
            <p className={cl['product-item__price-label']}>
              {t('common.priceFrom', { from: price.quantityFrom })} {product.unitType.shortName}
            </p>
            <p className={cl['product-item__price-value']}>{(+price.price).toLocaleString()}₸</p>
          </div>
        ))}
      </div>

      {product.manufacturer ? (
        <div className={cl['manufacturer']}>
          <div className={cl['manufacturer__logo']}>
            {product.manufacturer.logo ? (
              <img src={product.manufacturer.logo} alt={product.manufacturer.title} />
            ) : (
              <img src={require('assets/images/empty.png')} alt="" />
            )}
          </div>
          <div className={cl['manufacturer__body']}>
            <NavLink
              to={`/manufacturers/${product.manufacturer.id}`}
              className={cl['product-item__link']}
            >
              <p className={cl['manufacturer__title']}>{product.manufacturer.title}</p>
            </NavLink>
            <p className={cl['manufacturer__address']}>
              {product.manufacturer.country.title}, {product.manufacturer.address}
            </p>
          </div>
        </div>
      ) : null}
    </div>
  );
};

export default PRProduct;
