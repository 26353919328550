import React, { useMemo, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Link, NavLink, useLocation, useNavigate } from 'react-router-dom';
import { ReactComponent as CloseEyeIcon } from 'assets/icons/close-eye.svg';
import { ReactComponent as OpenEyeIcon } from 'assets/icons/open-eye.svg';
import PRButton from 'components/PRButton';
import PRInput from 'components/PRInput';
import PRTitle from 'components/PRTitle';
import { authSlice } from 'store/auth';
import { EMAIL_REGEX } from 'types/constants/email.regex';
import useHandleError from 'hooks/handleError';
import { useNotification } from 'hooks/notification';
import { useAppDispatch } from 'hooks/redux';
import { login } from 'api/repositories/users.repository';
import { ErrorResponse } from 'entities/Error';

import cl from './login.module.scss';

const Login = () => {
  const dispatch = useAppDispatch();
  const location = useLocation();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const notify = useNotification();
  const { handleCommonError } = useHandleError();
  const [passwordVisible, setPasswordVisible] = useState(false);

  const {
    formState: { isSubmitting },
    handleSubmit,
    control,
  } = useForm({
    defaultValues: {
      email: '',
      password: '',
    },
  });

  const onSubmit = async (data: Record<string, string>) => {
    try {
      const response = await login(data.email.toLowerCase(), data.password);
      dispatch(authSlice.actions.login(response.token));
      navigate(location.state?.from ?? '/');
    } catch (err: unknown) {
      const error = err as ErrorResponse;
      if (error.response?.status === 401) {
        notify.error(t('error.wrongAuth'));
      } else {
        handleCommonError(error);
      }
    }
  };

  const passwordSuffix = useMemo(() => {
    if (passwordVisible) {
      return (
        <CloseEyeIcon className={cl['login__eye']} onClick={() => setPasswordVisible(false)} />
      );
    }
    return <OpenEyeIcon className={cl['login__eye']} onClick={() => setPasswordVisible(true)} />;
  }, [passwordVisible]);

  return (
    <div className={cl['login']}>
      <PRTitle tag="h2">{t('message.signTo')}</PRTitle>
      <p className={cl['login__sign-up']}>
        {t('common.haveNotAccount')} <NavLink to="/auth/registration">{t('action.create')}</NavLink>
      </p>

      <form className={cl['login__form']} onSubmit={handleSubmit(onSubmit)}>
        <Controller
          control={control}
          name="email"
          rules={{
            required: { message: t('error.required'), value: true },
            pattern: {
              message: t('error.invalidEmail'),
              value: EMAIL_REGEX,
            },
          }}
          render={({ field: { onChange, onBlur, value, name, ref }, fieldState: { error } }) => (
            <PRInput
              label={t('form.email')}
              ref={ref}
              name={name}
              value={value}
              onChange={onChange}
              onBlur={onBlur}
              error={error?.message}
            />
          )}
        />
        <Controller
          control={control}
          name="password"
          rules={{
            required: { message: t('error.required'), value: true },
            minLength: { message: t('error.minLength', { len: 6 }), value: 6 },
          }}
          render={({ field: { onChange, onBlur, value, name, ref }, fieldState: { error } }) => (
            <PRInput
              label={t('form.password')}
              type={passwordVisible ? 'text' : 'password'}
              ref={ref}
              name={name}
              value={value}
              suffix={passwordSuffix}
              onChange={onChange}
              onBlur={onBlur}
              error={error?.message}
            />
          )}
        />
        <p className={cl['login__forgot']}>
          {t('common.forgotPassword')} <Link to="/auth/reset-password">{t('action.recover')}</Link>
        </p>
        <div>
          <PRButton loading={isSubmitting} type="submit">
            {t('action.signIn')}
          </PRButton>
        </div>
      </form>
    </div>
  );
};

export default Login;
