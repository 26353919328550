import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { NavLink } from 'react-router-dom';
import { ReactComponent as HomeIcon } from 'assets/icons/home2.svg';
import { ReactComponent as UserIcon } from 'assets/icons/user-line.svg';
import PRButton from 'components/PRButton';
import PRTitle from 'components/PRTitle';
import { registrationSlice } from 'store/registration';
import { useAppDispatch, useAppSelector } from 'hooks/redux';

import cl from './start.module.scss';

const RegistrationStartStep = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const { start } = useAppSelector((state) => state.registration);
  const [type, setType] = useState(start.type);

  const onSubmit = () => {
    dispatch(registrationSlice.actions.setStartStep({ type }));
  };

  return (
    <div className={cl['start']}>
      <PRTitle tag="h1">{t('common.registration')}</PRTitle>
      <p className={cl['start__text']}>
        {t('common.haveAnAccount')} <NavLink to="/auth/login">{t('action.signIn')}</NavLink>
      </p>
      <div
        className={`${cl['start__item']} ${
          type === 'manufacturer' ? cl['start__item--active'] : ''
        }`}
        onClick={() => setType('manufacturer')}
      >
        <div className={cl['start__icon']}>
          <HomeIcon />
        </div>
        <div>
          <p>{t('common.iAmManufacturer')}</p>
          <p>{t('message.iWantCatalogAndMakeSale')}</p>
        </div>
      </div>
      <div
        className={`${cl['start__item']} ${type === 'user' ? cl['start__item--active'] : ''}`}
        onClick={() => setType('user')}
      >
        <div className={cl['start__icon']}>
          <UserIcon />
        </div>
        <div>
          <p>{t('common.iAmUser')}</p>
          <p>{t('message.iWantSearchManufacturers')}</p>
        </div>
      </div>
      <div className={cl['start__button']}>
        <PRButton onClick={onSubmit}>{t('action.continue')}</PRButton>
      </div>
    </div>
  );
};

export default RegistrationStartStep;
