export default {
  signTo: 'ورود به سیستم',
  signUp: 'ایجاد حساب کاربری شخصی',
  passwordHelper: 'حداقل 8 حرف، 1 حرف بزرگ، 1 عدد.',
  lastUpdate: 'آخرین به‌روزرسانی: {{date}}',
  tradingAgency:
    'نماینده‌ای که تعهد دارد توزیع در منطقه‌ای خاص را به‌صورت مطابق با قوانین پیش‌تعیین‌شده انجام دهد.',
  saleOfPoint: 'حضور مکانی مشخص برای فروش محصول.',
  tradingHouse: 'شرکتی مجاز از تولیدکننده برای نمایش و تعیین قوانین توزیع محصول در منطقه‌ای خاص.',
  uploadPresentationOrFile: 'در صورت نیاز، مستندات اضافی، ارائه‌نامه یا فایل را بارگذاری کنید',
  usingForSEO:
    'برای بهینه‌سازی برای موتورهای جستجو (SEO) و افزایش قابلیت رؤیت محصول در نتایج جستجو استفاده می‌شوند',
  noBranchesYet: 'تا کنون شعبه‌ای اضافه نشده است',
  branchBenefits:
    'لیست شعب به بهبود اعتماد و دیده‌شدن در وب‌سایت کمک می‌کند و همچنین به سایر شرکت‌ها اجازه می‌دهد شعبه‌ها را مشاهده و با آنها تماس بگیرند.',
  activationCodeSend:
    'کدی به ایمیل شما ارسال شده است. برای تکمیل ثبت‌نام، لطفاً کد را در جعبه ورود کد وارد کنید',
  resendCodeAfter: 'ارسال مجدد کد پس از {{seconds}} ثانیه',
  codeSent: 'کد ارسال شد',
  allDataWillBeDeleted: 'تمام داده‌های شما به همراه حساب کاربری حذف خواهند شد',
  changePasswordImproveAccount: 'تغییر کد دسترسی به حساب کاربری به منظور افزایش امنیت حساب',
  writeEmailForRecovery: 'لطفاً ایمیلی را وارد کنید که به حساب کاربری شما متصل است',
  codeForRecovery: 'کد بازیابی رمز عبور به ایمیل شما ارسال شده است',
  enterCodeToAccessAccount: 'کد را برای دسترسی به حساب کاربری وارد کنید',
  createStrongPassword: 'یک رمز عبور قوی برای حساب خود ایجاد کنید',
  infoAboutYourCompany: 'اطلاعات مربوط به شرکت خود را وارد کنید',
  iWantCatalogAndMakeSale: 'می‌خواهم کاتالوگ خود را نمایش داده و محصولات را بفروشم',
  iWantSearchManufacturers: 'من در جستجوی تولیدکنندگان برای همکاری هستم',
  tradingAgencyDescription:
    'نمایندگی‌های تجاری: این سازمان‌ها نماینده منافع و محصولات سایر شرکت‌ها در یک منطقه خاص هستند. آنها می‌توانند مشتریان را جذب کنند، محصولات را تبلیغ کنند، مذاکرات را انجام دهند و معاملات را به نمایندگی از شرکت نماینده‌شون انجام دهند. مثال‌هایی از نمایندگی‌های تجاری شامل دفاتر مشاوره‌ی املاک، دفاتر مشاوره‌ی خرید و فروش خودرو و غیره هستند.',
  tradingHouseDescription:
    'خانه‌های تجاری: این سازمان‌ها که توسط تولیدکننده انتخاب می‌شوند، سازمان‌های تجاری بزرگی هستند که چندین نقطه‌ی فروش یا مغازه را تحت مدیریت یک شرکت اداره می‌کنند. خانه‌های تجاری ممکن است دارای بخش‌ها و بخش‌های تخصصی مختلفی باشند که محصولات یا خدمات متنوعی ارائه می‌دهند. مثال‌هایی از خانه‌های تجاری شامل ایستگاه‌های مرکزی خرید و مراکز خرید و غیره هستند.',
  tradingPointDescription:
    'نقاط تجاری: این مکان‌ها هستند که محصولات یا خدمات به مشتریان به‌صورت مستقیم فروخته می‌شوند. این ممکن است فروشگاه‌ها، سوپرمارکت‌ها، بازارها، پاساژها و غیره باشند. نقاط تجاری معمولاً فضای فیزیکی دارند که مشتریان می‌توانند محصولات را خریداری کنند یا خدمات را دریافت کنند.',
};
