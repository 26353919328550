export default {
  notConnection: 'Интернет байланышын текшериңиз',
  unknown: 'Неоше бир нерсе чыгып кетти',
  server: 'Сервердеги ката. Техникалык долбоорго карар бериңиз.',
  wrongAuth: 'Email же пароль дүрес эмес',
  required: 'Толтуруу үчүн талап кылынат',
  invalidEmail: 'Нотук email',
  minLength: '{{len}} белгиден кийин болушу керек',
  maxLength: '{{len}} белгиден ашуу керек жок',
  passwordsNotMatch: 'Паролдар туура боолушу керек эмес',
  notCorrectPrice: 'Дүрес баа эмес',
  notCorrectLink: 'Дүрес сылтама эмес',
  forbidden: 'Сизге бул иштеп болгон берүүлөр жеткиликтүү эмес',
  phone: 'Нотук телефон номери',
  incorrectFormat: 'Файл {{format}} форматында болушу керек',
  selectRequiredColumns: 'Талап кылынган багыттар тандаган жокту',
  readFile: 'Файлды окуу ката болду',
  emailNotAvailable: 'Email арттыралган, системада ачуу болгон жокту',
};
