import React, { useMemo } from 'react';
import { ReactComponent as CheckIcon } from 'assets/icons/check.svg';

import cl from './stepper.module.scss';

interface Props {
  step: number;
  currentStep: number;
}

const Step: React.FC<Props> = ({ step, currentStep }) => {
  const stepClass = useMemo(() => {
    let cls = cl['stepper__item'];
    if (step === currentStep) {
      cls += ` ${cl['active']}`;
    } else if (step < currentStep) {
      cls += ` ${cl['done']}`;
    }
    return cls;
  }, [step, currentStep]);

  return (
    <div className={stepClass}>
      <div className={cl['stepper__circle']}>
        <CheckIcon className={cl['stepper__check']} />
      </div>
      <div className={cl['stepper__line']} />
    </div>
  );
};

export default Step;
