import React from 'react';
import { NavLink } from 'react-router-dom';

import cl from './tabs.module.scss';

export interface Tab {
  id: number;
  title: string;
  badge?: string;
  action: string | ((tab: Tab) => void);
}

interface Props {
  tabs: Tab[];
  activeTab?: number;
  badgeColor?: 'green' | 'blue';
}

const PRTabs: React.FC<Props> = ({ tabs, badgeColor = 'blue', activeTab = 0 }) => {
  return (
    <div className={cl['pr-tabs']}>
      {tabs.map((tab) =>
        typeof tab.action === 'string' ? (
          <NavLink
            key={tab.id}
            to={tab.action}
            className={({ isActive }) =>
              `${cl['pr-tabs__tab']} ${isActive ? cl['pr-tabs__tab--active'] : ''}`
            }
            end
          >
            <span className={cl['pr-tabs__text']}>{tab.title}</span>
            {tab.badge ? (
              <span className={`${cl['pr-tabs__badge']} ${cl[`pr-tabs__badge--${badgeColor}`]}`}>
                {tab.badge}
              </span>
            ) : null}
          </NavLink>
        ) : (
          <button
            key={tab.id}
            className={`${cl['pr-tabs__tab']} ${
              activeTab === tab.id ? cl['pr-tabs__tab--active'] : ''
            }`}
            onClick={() => typeof tab.action === 'function' && tab.action(tab)}
          >
            <span className={cl['pr-tabs__text']}>{tab.title}</span>
            {tab.badge ? (
              <span className={`${cl['pr-tabs__badge']} ${cl[`pr-tabs__badge--${badgeColor}`]}`}>
                {tab.badge}
              </span>
            ) : null}
          </button>
        )
      )}
    </div>
  );
};

export default PRTabs;
