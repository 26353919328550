export default {
  change: 'تغییر',
  delete: 'حذف',
  find: 'یافتن',
  reset: 'بازنشانی',
  save: 'ذخیره',
  edit: 'ویرایش',
  cancel: 'لغو',
  accept: 'موافق',
  login: 'ورود',
  logout: 'خروج',
  signup: 'ایجاد حساب کاربری',
  signin: 'ورود به حساب کاربری',
  next: 'بعدی',
  seekManufacturer: 'درخواست تولید کننده',
  seekProduct: 'درخواست محصول',
  back: 'بازگشت',
  forward: 'به جلو',
  becomeAPartner: 'تبدیل به شریک',
  contactWithManufacturer: 'تماس با تولید کننده',
  uploadFile: 'بارگذاری فایل',
  sendRequest: 'ارسال درخواست',
  importProducts: 'وارد کردن محصولات',
  addProduct: 'افزودن محصول',
  continue: 'ادامه',
  addPrice: 'افزودن قیمت',
  removePrice: 'حذف قیمت',
  saveChanges: 'ذخیره تغییرات',
  leaveAsDraft: 'به عنوان پیش‌نویس باقی بمانید',
  close: 'بستن',
  ready: 'آماده',
  addBranches: 'افزودن شعبه‌ها',
  resendActivationCode: 'ارسال مجدد کد فعال‌سازی',
  sendForVerification: 'ارسال برای تأیید',
  deleteAccount: 'حذف حساب کاربری',
  changePassword: 'تغییر رمز عبور',
  hide: 'مخفی کردن',
  expand: 'گسترش',
  orderProduct: 'سفارش محصول',
  writeToManufacturer: 'نوشتن به تولید کننده',
  goToCatalog: 'رفتن به کاتالوگ',
  confirm: 'تأیید',
  yes: 'بله',
  no: 'خیر',
  selectChat: 'انتخاب چت',
  signIn: 'ورود',
  recover: 'بازیابی',
  create: 'ایجاد',
  uploadNewPhoto: 'بارگذاری عکس جدید',
  verifyCompany: 'تأیید شرکت',
};
