export default {
  main: 'Галоўная',
  loading: 'Загрузка...',
  listEmpty: 'Спіс пусты',
  attention: 'Увага',
  services: 'Паслугі',
  applications: 'Заяўкі',
  partnership: 'Партнёрства',
  branches: 'Філіялы',
  catalog: 'Каталог',
  faq: 'Часта задаваемыя пытанні',
  companyData: 'Дадзеныя кампаніі',
  employeeData: 'Дадзеныя прадстаўніка',
  accountActivation: 'Актывацыя рахунку',
  aboutPlatform: 'Аб пляцоўцы',
  manageCompany: 'Кіраванне кампаніяй',
  tradingAgency: 'Таргавае прадстаўніцтва',
  saleOfPoint: 'Точка продажу',
  tradingHouse: 'Таргавы дом',
  manufacturerName: 'Назва вытворцы',
  productName: 'Назва тавару',
  priceFrom: 'Цана ад {{from}}',
  aboutManufacturer: 'Аб вытворцы',
  contacts: 'Кантакты',
  manufacturers: 'Вытворцы',
  summary: 'Зводка',
  certificates: 'Сертыфікаты',
  yearsAgo: '{{num}} гадоў таму',
  title: 'Назва',
  brandName: 'Назва брэнда',
  legalAddress: 'Юрыдычны адрас',
  jurisdiction: 'Юрысдыкцыя',
  regNumberBin: 'Рэгістрацыйны нумар',
  registrationDate: 'Дата рэгістрацыі',
  sinceOnSite: 'На сайце з',
  product: 'Тавар',
  vendorCode: 'Артыкул',
  date: 'Дата',
  display: 'Адлюстроўванне',
  inStock: 'У наяўнасці',
  actions: 'Дзеянні',
  required: 'Абавязковыя',
  optional: 'Апцыянальныя',
  productList: 'Спіс тавараў',
  barcode: 'Штрыхкод або UPC/EAN',
  color: 'Колер',
  material: 'Матэрыял',
  currency: 'Валюта',
  unitType: 'Адзінка меркавання',
  status: 'Статус',
  wholesalePrice: 'Аптавая цана',
  from: 'ад',
  mainInfo: 'Асноўная інфармацыя',
  productDescription: 'Апісанне тавару',
  dimensions: 'Габарытныя памеры',
  characteristics: 'Характарыстыкі',
  price: 'Цана',
  createAndUpdate: 'Стварэнне і абнаўленне',
  created: 'Створана',
  updated: 'Абноўлена',
  searchTerms: 'Пошукавыя запыты',
  supportedFormats: 'Падтрымліваемыя фарматы: {{formats}}',
  maxSize: 'Максімальны памер: {{size}}',
  images: 'Выявы',
  agency: 'Прадстаўніцтва',
  password: 'Пароль',
  addingBranch: 'Даданне філіяла',
  questionAndAnswer: 'Пытанні і адказы',
  profile: 'Профіль',
  settings: 'Налады',
  requirementsForVerification: 'Патрабаванні для верыфікацыі',
  submitFollowingDocuments: 'Прадастаўце наступныя тыпы дакументаў',
  companyRegCertificate: 'Свідоцтва аб рэгістрацыі кампаніі',
  companyCharter: 'Устав кампаніі',
  certificateTaxReg: 'Свідоцтва аб падатковай рэгістрацыі',
  productionDoc: 'Дакументацыя аб вытворчасці',
  qualityCertificate: 'Свідоцтва аб якасці і адпаведнасці',
  licensesAndCertificates: 'Ліцэнзіі і сертыфікаты',
  suppliersAndCustomersContracts: 'Дагаворы з пастаўшчыкамі і кліентамі',
  accountDeleting: 'Выдаленне рахунку',
  passwordChanging: 'Змена пароля',
  today: 'сёння',
  weight: 'Вага',
  availableOnOrder: 'Тавар даступны па замове',
  manufacturerOtherProducts: 'Іншыя тавары вытворцы',
  lastActivity: 'Апошняя актыўнасць',
  passwordRecovery: 'Аднаўленне пароля',
  forgotPassword: 'Забылі пароль?',
  haveNotAccount: 'Няма рахунку?',
  createPassword: 'Стварэнне пароля',
  registration: 'Рэгістрацыя',
  haveAnAccount: 'Ёсць рахунак?',
  iAmManufacturer: 'Я вытворца',
  iAmUser: 'Я карыстальнік',
  aboutCompany: 'Аб кампаніі',
  logo: 'Лагатып',
  personalData: 'Асабістыя дадзеныя',
  notifications: 'Апавяшчэнні',
  todayAt: 'Сёння ў {{date}}',
  yesterdayAt: 'Учора ў {{date}}',
  all: 'Усе',
  system: 'Сістэмныя',
  notSpecified: 'не пазначана',
};
