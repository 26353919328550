export default {
  signTo: 'Кирүү',
  signUp: 'Жеке кабинеттин жасалуу',
  passwordHelper: 'Кемине 8 белги, 1 башкы тамга, 1 сандык.',
  lastUpdate: 'Акча турган убакыт: {{date}}',
  tradingAgency:
    'Соода агенттемелер: берилген жерде дистрибуцияны байкоо үчүн алдын ала белгилеген куралдарды түзөт.',
  saleOfPoint: 'Маалыматты жакшы көрсөтүү өнөктөрдү сатуу жери.',
  tradingHouse:
    'Соода домдор: мамлекеттиктен кабар берүү жана куралдарды түзөтүү үчүн көмөкчү болгон куралдуу компания.',
  uploadPresentationOrFile: 'Керек болсо, кошулгоону, презентацияны же файлды жүктөңүз',
  usingForSEO: 'SEO үчүн колдонулат, булар товарды издөөдө көмөк көрсөтөт',
  noBranchesYet: 'Сиздин буга чейинки филиалдар жок',
  branchBenefits:
    'Филиалдар тизмеси довертини жогоруто жана сайтта көрүнүштүгүн тезейт, башка компанияларга филиалдарды көрүп, контакт кылуу мүмкүнчүлүгү берет.',
  activationCodeSend:
    'Сиздин электрондук почтага код жөнөтүлдү, регистрацияны аяктагуу үчүн кодду киргизиңиз',
  resendCodeAfter: '{{seconds}} секунддан кийин кодду майкалоо',
  codeSent: 'Код жөнөтүлдү',
  allDataWillBeDeleted: 'Бардык деректериңиз аккаунттарыңызбы менен бирге жок болот',
  changePasswordImproveAccount: 'Кодду жаңыдан киргизип, аккаунттын амандуулугун көбөлөө',
  writeEmailForRecovery: 'Аккаунтка байланыштырган электрондук почтуңузду киргизиңиз.',
  codeForRecovery: 'Сыр сөздү кайра табуу коду сиздин электрондук почтага жөнөтүлдү',
  enterCodeToAccessAccount: 'Аккаунтка киришке кодду жазыңыз',
  createStrongPassword: 'Аккаунттың үчүн курбан кеткен паролду тууралуу жасаңыз',
  infoAboutYourCompany: 'Компаниясыңыздын маалыматын көрсөтүңүз',
  iWantCatalogAndMakeSale:
    'Менин каталогумду көрсөтүп, маалыматты сатууга болгон жеткилик көрсөтүүм келет',
  iWantSearchManufacturers: 'Мен производителерди табуу үчүн издейм',
  tradingAgencyDescription:
    'Соода агенттемелер: бул территориядагы башка компаниялардын интерестерин жана продукциясын көрсөтүүгө жардам берүү организациялардır. Алар клиенттерди издөө, товарды чабылтуу, жардамашылык сыйлаштыруу жана күмөндүктерди кабыл алуу үчүн келишет. Торговые агенттемелердин мисалдары: эмлекти сатуу агенттемелери, автомашын сатуу агенттемелери ж.б.',
  tradingHouseDescription:
    'Соода домдор: бул чоң торговые организациялар, андан кийин бир ылайык жеткиликтүү башка жерлерди же дүкөндөрдү башка түзөткүчү компаниянын төбөлүндөр. Торговый дом кол кеттүүчү багыттар мен специализацияланган бөлүмдөрдү киреди, жардам береттүү ширек жаткан тамактарды же кызматтарды көрсөткөн. Торговые домдун мисалдары: универмагдар, торговые ордондуу меркездер ж.б.',
  tradingPointDescription:
    'Соода нүктөлөр: бул жерлер, жарактарды же мамлекеттерди директор калыптырып сатат. Алар кайталаптар, супермаркеттер, базарлар, торговые буланттар ж.б. Торговые нүктөлөрдүн бошулуштуу көрүнүштөрү болсо, клиенттер тамактаган жана кызмат көрсөтүүгө болот.',
};
