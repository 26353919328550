import React from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import PRAsyncSelect from 'components/PRAsyncSelect';
import PRButton from 'components/PRButton';
import PRCheckbox from 'components/PRCheckbox';
import PRInput from 'components/PRInput';
import { EMAIL_REGEX } from 'types/constants/email.regex';
import { formatPhoneNumber } from 'utils/formatter.util';
import { fetchWorkingAreas } from 'api/repositories/handbooks.repository';
import { Option } from 'entities/Option';

import { BecomePartnerForm } from '../../dto/partner-form.dto';

import cl from './first.module.scss';

interface Props {
  form: BecomePartnerForm;
  onSubmit: (data: Partial<BecomePartnerForm>) => void;
}
const BecomePartnerFirst: React.FC<Props> = ({ form, onSubmit }) => {
  const { t } = useTranslation();
  const {
    control,
    formState: { isSubmitting },
    handleSubmit,
  } = useForm({
    defaultValues: {
      fullName: form.fullName,
      companyName: form.companyName,
      workingArea: form.workingArea,
      email: form.email,
      website: form.website,
      phone: form.phone,
      isAgree: form.isAgree,
    },
  });

  return (
    <form className={cl.first} onSubmit={handleSubmit(onSubmit)} noValidate>
      <div className={cl['first__two']}>
        <Controller
          control={control}
          name="fullName"
          rules={{ required: { message: t('error.required'), value: true } }}
          render={({ field: { onChange, onBlur, value, name, ref }, fieldState: { error } }) => (
            <PRInput
              ref={ref}
              label={t('form.fullName')}
              name={name}
              value={value}
              error={error?.message}
              required
              onChange={onChange}
              onBlur={onBlur}
            />
          )}
        />
        <Controller
          control={control}
          name="companyName"
          rules={{ required: { message: t('error.required'), value: true } }}
          render={({ field: { onChange, onBlur, value, name, ref }, fieldState: { error } }) => (
            <PRInput
              ref={ref}
              label={t('form.companyName')}
              name={name}
              value={value}
              error={error?.message}
              required
              onChange={onChange}
              onBlur={onBlur}
            />
          )}
        />
      </div>

      <Controller
        control={control}
        name="workingArea"
        rules={{ required: { message: t('error.required'), value: true } }}
        render={({ field: { onChange, onBlur, value }, fieldState: { error } }) => (
          <PRAsyncSelect
            value={value}
            label={t('form.cooperationPurpose')}
            fetchList={fetchWorkingAreas}
            error={error?.message}
            isClearable
            required
            onChange={(val) => onChange(val as Option)}
            onBlur={onBlur}
          />
        )}
      />

      <div className={cl['first__three']}>
        <Controller
          control={control}
          name="email"
          rules={{
            required: { message: t('error.required'), value: true },
            pattern: {
              message: t('error.invalidEmail'),
              value: EMAIL_REGEX,
            },
          }}
          render={({ field: { onChange, onBlur, value, name, ref }, fieldState: { error } }) => (
            <PRInput
              label={t('form.email')}
              ref={ref}
              name={name}
              value={value}
              error={error?.message}
              required
              onChange={onChange}
              onBlur={onBlur}
            />
          )}
        />
        <Controller
          control={control}
          name="website"
          render={({ field: { onChange, onBlur, value, name, ref } }) => (
            <PRInput
              label={t('form.website')}
              ref={ref}
              name={name}
              value={value}
              onChange={onChange}
              onBlur={onBlur}
            />
          )}
        />
        <Controller
          control={control}
          name="phone"
          rules={{
            required: { message: t('error.required'), value: true },
            minLength: { message: t('error.phone'), value: 13 },
          }}
          render={({ field: { onChange, onBlur, value, name, ref }, fieldState: { error } }) => (
            <PRInput
              label={t('form.phoneNumber')}
              ref={ref}
              name={name}
              value={value}
              error={error?.message}
              required
              onChange={(val) => onChange(formatPhoneNumber(val))}
              onBlur={onBlur}
            />
          )}
        />
      </div>
      <div className={cl['first__agreement']}>
        <Controller
          control={control}
          name="isAgree"
          rules={{ required: { message: t('error.required'), value: true } }}
          render={({ field: { onChange, value, name, ref }, fieldState: { error } }) => (
            <PRCheckbox ref={ref} name={name} value={value} onChange={onChange}>
              <span className={error?.message ? cl['first__error'] : ''}>
                Я ознакомлен(а) и принимаю <Link to="#">условия обработки данных</Link>
              </span>
            </PRCheckbox>
          )}
        />
      </div>
      <div className={cl['first__submit']}>
        <PRButton loading={isSubmitting} type="submit">
          {t('action.next')}
        </PRButton>
      </div>
    </form>
  );
};

export default BecomePartnerFirst;
