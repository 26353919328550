export default {
  change: 'Өзгерту',
  delete: 'Жою',
  find: 'Табу',
  reset: 'Қайта теңеу',
  save: 'Сақтау',
  edit: 'Өңдеу',
  cancel: 'Болдырмау',
  accept: 'Қабылдау',
  login: 'Кіру',
  logout: 'Шығу',
  signup: 'Тіркелу',
  signin: 'Кіру',
  next: 'Келесі',
  seekManufacturer: 'Өндіруші іздеп жатамын',
  seekProduct: 'Товар іздеу',
  back: 'Артқа',
  forward: 'Алға',
  becomeAPartner: 'Шартты партнер болу',
  contactWithManufacturer: 'Өндірушімен байланысу',
  uploadFile: 'Файл жүктеу',
  sendRequest: 'Сұрау жіберу',
  importProducts: 'Товарларды импорттау',
  addProduct: 'Товарды қосу',
  continue: 'Жалғастыру',
  addPrice: 'Бағаны қосу',
  removePrice: 'Бағаны жою',
  saveChanges: 'Өзгерістерді сақтау',
  leaveAsDraft: 'Үлгі ретінде қалдыру',
  close: 'Жабу',
  ready: 'Дайын',
  addBranches: 'Филиалдарды қосу',
  resendActivationCode: 'Активация кодын қайтару',
  sendForVerification: 'Тексерілу үшін жіберу',
  deleteAccount: 'Аккаунты жою',
  changePassword: 'Құпия сөзді өзгерту',
  hide: 'Жасыру',
  expand: 'Кеңейту',
  orderProduct: 'Товарды тапсыру',
  writeToManufacturer: 'Өндірушіге жазу',
  goToCatalog: 'Каталогқа өту',
  confirm: 'Растау',
  yes: 'Иә',
  no: 'Жоқ',
  selectChat: 'Чатты таңдау',
  signIn: 'Кіру',
  recover: 'Қалпына келтіру',
  create: 'Жасау',
  uploadNewPhoto: 'Жаңа сурет жүктеу',
  verifyCompany: 'Компанияны тексеру',
};
