import React from 'react';

import HomeFilter from '../../components/HomeFilter';
import MapHolder from '../../components/MapHolder';
import { useFilter } from '../../hooks/filter';
import Manufacturer from '../manufacturer';
import Product from '../product';

import cl from './home.module.scss';

const Home: React.FC = () => {
  const [filters, setFilters] = useFilter();

  const onPageChange = (page: number) => {
    setFilters({ page });
  };

  return (
    <div className={cl.home}>
      <HomeFilter params={filters} onSubmit={setFilters} />

      <MapHolder params={filters} onFilterChange={setFilters} />

      {filters.section === 'manufacturer' ? (
        <Manufacturer params={filters} onPageChange={onPageChange} />
      ) : (
        <Product params={filters} onPageChange={onPageChange} />
      )}
    </div>
  );
};

export default Home;
