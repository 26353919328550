import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useOutsideClick } from 'hooks/outside-click';

import cl from './dropdown.module.scss';

interface DropdownOption {
  id: number;
  title: string;
  path?: string;
  icon?: React.ReactNode;
  styles?: Record<string, string>;
  onClick?: (id?: number) => void;
}

type DropdownPosition = 'right' | 'left';

interface DropdownProps {
  targetId?: number;
  options: DropdownOption[];
  position?: DropdownPosition;
  children: React.ReactNode;
}

const PRDropdown: React.FC<DropdownProps> = ({
  targetId = 0,
  options,
  position = 'right',
  children,
}) => {
  const navigate = useNavigate();
  const [isOpen, setOpen] = useState(false);
  const dropdownRef = useOutsideClick(() => {
    setOpen(false);
  });

  const toggleDropdown = () => {
    setOpen(!isOpen);
  };

  const onOptionClick = (option: DropdownOption) => {
    if (option.path) {
      navigate(option.path);
    } else {
      option.onClick && option.onClick(targetId);
    }
    toggleDropdown();
  };

  return (
    <div ref={dropdownRef} className={`${cl['dropdown']} ${isOpen ? cl['dropdown--open'] : ''}`}>
      <button className={cl['dropdown__button']} onClick={toggleDropdown}>
        {children}
      </button>
      <div
        className={`${cl['dropdown__list']} ${cl[`dropdown__list--${position}`]}`}
        onClick={(e) => {
          e.stopPropagation();
          e.preventDefault();
        }}
      >
        <ul>
          {options.map((option) => (
            <li key={option.id}>
              <button style={option.styles} onClick={() => onOptionClick(option)}>
                {option.icon ?? null}
                <span>{option.title}</span>
              </button>
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
};

export default PRDropdown;
