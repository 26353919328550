import React, { useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { ReactComponent as CloseIcon } from 'assets/icons/close.svg';
import { ReactComponent as UploadIcon } from 'assets/icons/upload.svg';
import PRAsyncSelect from 'components/PRAsyncSelect';
import PRButton from 'components/PRButton';
import PRFileInput from 'components/PRFileInput';
import PRInput from 'components/PRInput';
import { fetchCooperationPurposes } from 'api/repositories/handbooks.repository';
import { Option } from 'entities/Option';

import { BecomePartnerForm } from '../../dto/partner-form.dto';

import cl from './second.module.scss';

interface Props {
  form: BecomePartnerForm;
  onSubmit: (data: Partial<BecomePartnerForm>) => void;
}

interface SecondForm {
  purpose: Option[] | null;
  offer: string;
  benefits: string;
}

const BecomePartnerSecond: React.FC<Props> = ({ form, onSubmit }) => {
  const { t } = useTranslation();
  const {
    control,
    formState: { isSubmitting },
    handleSubmit,
  } = useForm({
    defaultValues: {
      purpose: form.purpose,
      offer: form.offer,
      benefits: form.benefits,
    },
  });

  const [files, setFiles] = useState<File[]>(form.files);

  const onFileSelect = (file: File) => {
    setFiles([...files, file]);
  };

  const onRemoveFile = (file: File) => {
    setFiles(files.filter((f) => f !== file));
  };

  const submit = (data: SecondForm) => {
    onSubmit({
      ...data,
      files,
    });
  };

  return (
    <form className={cl.second} onSubmit={handleSubmit(submit)} noValidate>
      <Controller
        control={control}
        name="purpose"
        rules={{ required: { message: t('error.required'), value: true } }}
        render={({ field: { onChange, onBlur, value }, fieldState: { error } }) => (
          <PRAsyncSelect
            value={value}
            label={t('form.cooperationPurpose')}
            fetchList={fetchCooperationPurposes}
            error={error?.message}
            isMulti
            isClearable
            required
            onChange={(val) => onChange(val as Option[])}
            onBlur={onBlur}
          />
        )}
      />

      <Controller
        control={control}
        name="offer"
        rules={{ required: { message: t('error.required'), value: true } }}
        render={({ field: { onChange, onBlur, value, name, ref }, fieldState: { error } }) => (
          <PRInput
            ref={ref}
            label={t('form.offer')}
            name={name}
            value={value}
            error={error?.message}
            required
            onChange={onChange}
            onBlur={onBlur}
          />
        )}
      />

      <Controller
        control={control}
        name="benefits"
        rules={{ required: { message: t('error.required'), value: true } }}
        render={({ field: { onChange, onBlur, value, name, ref }, fieldState: { error } }) => (
          <PRInput
            ref={ref}
            label={t('form.expectedBenefits')}
            name={name}
            value={value}
            error={error?.message}
            required
            onChange={onChange}
            onBlur={onBlur}
          />
        )}
      />

      <div className={cl['second__files']}>
        <p className={cl['second__file-hint']}>{t('message.uploadPresentationOrFile')}</p>
        <div className={cl['dnd']}>
          <PRFileInput setFile={onFileSelect} format=".pdf,.doc,.docx">
            <div className={cl['dnd__wrapper']}>
              <div className={cl['dnd__text']}>
                <UploadIcon />
                {t('action.uploadFile')}
              </div>
              <p>{t('common.supportedFormats', { formats: 'PDF, DOCX' })}</p>
              <p>{t('common.supportedFormats', { formats: '5 MB' })}</p>
            </div>
          </PRFileInput>

          <div className={cl['dnd__result']}>
            {files.map((f) => (
              <p className={cl['dnd__file']} key={f.lastModified}>
                <span>{f.name}</span>
                <button className={cl['dnd__close']} onClick={() => onRemoveFile(f)}>
                  <CloseIcon />
                </button>
              </p>
            ))}
          </div>
        </div>
      </div>

      <div className={cl['second__submit']}>
        <PRButton loading={isSubmitting} type="submit">
          {t('action.next')}
        </PRButton>
      </div>
    </form>
  );
};

export default BecomePartnerSecond;
