import React, { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ReactComponent as PlusIcon } from 'assets/icons/plus.svg';
import PRButton from 'components/PRButton';
import PRFileInput from 'components/PRFileInput';
import PRTitle from 'components/PRTitle';
import { authSlice } from 'store/auth';
import { useAppDispatch, useAppSelector } from 'hooks/redux';
import { uploadFile } from 'api/repositories/files.repository';
import { saveCertificates } from 'api/repositories/users.repository';
import { Document } from 'entities/Document';
import { Organization } from 'entities/User';

import cl from './certificates.module.scss';

const CertificatesCard = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const user = useAppSelector((state) => state.auth.user) as Organization;

  const [deleteList, setDeleteList] = useState<string[]>([]);
  const [certificates, setCertificates] = useState<Document[]>([]);

  const savedCertificates = useMemo(
    () => user.certificates.filter((c) => !deleteList.includes(c.id)),
    [deleteList, user.certificates]
  );

  const uploadCertificate = (file: File) => {
    const fd = new FormData();
    fd.append('file', file);

    uploadFile(fd).then((res) => {
      setCertificates((prev) => [...prev, res]);
    });
  };

  const onSavedCertificateDelete = (id: string) => {
    setDeleteList((prev) => [...prev, id]);
  };

  const onCertificateDelete = (id: string) => {
    setCertificates((prev) => prev.filter((i) => i.id !== id));
  };

  const onSaveCertificates = async () => {
    const list = [...savedCertificates, ...certificates];
    await saveCertificates(list.map((c) => c.id));
    dispatch(authSlice.actions.userFetchingSuccess({ ...user, certificates: list }));
    setCertificates([]);
    setDeleteList([]);
  };

  return (
    <div className={cl['certificates']}>
      <PRTitle tag="h3">{t('common.certificates')}</PRTitle>

      <div className={cl['certificates__list']}>
        {savedCertificates.map((certificate) => (
          <div key={certificate.id} className={cl['certificates__item']}>
            <button onClick={() => onSavedCertificateDelete(certificate.id)}>&times;</button>
            <img src={certificate.link} alt="" />
          </div>
        ))}
        {certificates.map((c) => (
          <div key={c.id} className={cl['certificates__item']}>
            <button onClick={() => onCertificateDelete(c.id)}>&times;</button>
            <img src={c.link} alt="" />
          </div>
        ))}

        <PRFileInput format=".png, .jpg,.jpeg" setFile={(file) => uploadCertificate(file)}>
          <div className={cl['certificates__upload']}>
            <PlusIcon />
          </div>
        </PRFileInput>
      </div>

      {certificates.length || deleteList.length ? (
        <div className={cl['certificates__footer']}>
          <PRButton onClick={onSaveCertificates}>{t('action.saveChanges')}</PRButton>
          <PRButton variant="text" onClick={() => setCertificates([])}>
            {t('action.cancel')}
          </PRButton>
        </div>
      ) : null}
    </div>
  );
};

export default CertificatesCard;
