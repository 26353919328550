import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { NavLink, useNavigate, useParams } from 'react-router-dom';
import { ReactComponent as ArrowIcon } from 'assets/icons/chevron-down.svg';
import { ReactComponent as ArrowLeftIcon } from 'assets/icons/chevron-left.svg';
import PRButton from 'components/PRButton';
import PRLoading from 'components/PRLoading';
import PRTitle from 'components/PRTitle';
import useHandleError from 'hooks/handleError';
import { useAppSelector } from 'hooks/redux';
import { fetchProductById } from 'api/repositories/products.repository';
import { ErrorResponse } from 'entities/Error';
import { Product } from 'entities/Product';

import OtherProducts from '../../components/OtherProducts';
import ProductSwiper from '../../components/ProductSwiper';

import cl from './detail.module.scss';

const ProductDetail = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { id } = useParams();
  const { user } = useAppSelector((state) => state.auth);
  const { handleCommonError } = useHandleError();
  const [product, setProduct] = useState<Product | null>(null);
  const [isDescExpanded, setDescExpanded] = useState(false);

  useEffect(() => {
    if (!id) return;

    fetchProductById(+id)
      .then((res) => {
        setProduct(res);
      })
      .catch((err: unknown) => {
        const error = err as ErrorResponse;
        if (error.response?.status === 404) {
          navigate('/404');
        } else {
          handleCommonError(error);
        }
      });

    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  }, [id]);

  const goToHome = () => {
    navigate('/?section=product');
  };

  if (!product) {
    return <PRLoading />;
  }

  return (
    <div className={cl['wrapper']}>
      <div className={cl['back-button']}>
        <PRButton
          variant="text"
          styles={{ color: '#A9B5BB' }}
          prefix={<ArrowLeftIcon />}
          onClick={goToHome}
        >
          Назад
        </PRButton>
      </div>

      <div className={cl['product']}>
        <div className={cl['product__top']}>
          <div className={`${cl['slider-card']} ${cl['product__card']}`}>
            {product.images?.length ? (
              <ProductSwiper images={product.images} />
            ) : (
              <img
                className={cl['slider-card__empty']}
                src={require('assets/images/empty.png')}
                alt=""
              />
            )}
          </div>
          <div className={cl['product__info']}>
            <div className={`${cl['main-card']} ${cl['product__card']}`}>
              {product.vendorCode ? (
                <div className={cl['main-card__header']}>
                  <p>
                    <span>№{product.vendorCode}</span>
                  </p>
                </div>
              ) : null}
              <PRTitle tag="h2">{product.title}</PRTitle>
              <div className={cl['main-card__features']}>
                <p>
                  <span>{t('common.dimensions')}:</span>
                  {product.length && product.width && product.height ? (
                    <span>{`${product.width}х${product.length}х${product.height}`}</span>
                  ) : (
                    <span>{t('common.notSpecified')}</span>
                  )}
                </p>
                <p>
                  <span>{t('common.weight')}:</span>
                  <span>{product.weight ? `${product.weight} г` : t('common.notSpecified')}</span>
                </p>
                <p>
                  <span>{t('common.color')}:</span>
                  <span>{product.color?.label || t('common.notSpecified')}</span>
                </p>
                <p>
                  <span>{t('common.material')}:</span>
                  <span>{product.material?.label || t('common.notSpecified')}</span>
                </p>
              </div>
              <div className={cl['main-card__prices']}>
                {product.prices.map((p, index) => (
                  <div key={index} className={cl['main-card__price-item']}>
                    <p className={cl['main-card__price-label']}>
                      {t('common.priceFrom', { from: p.quantityFrom })}{' '}
                      {product?.unitType.shortName}
                    </p>
                    <p className={cl['main-card__price-value']}>
                      {p.price} <span>{product.currency.code}</span>
                    </p>
                  </div>
                ))}
              </div>
              {user?.entityId !== product.manufacturer?.id ? (
                <div className={cl['main-card__buttons']}>
                  <PRButton>{t('action.orderProduct')}</PRButton>
                  <PRButton variant="outline">{t('action.writeToManufacturer')}</PRButton>
                </div>
              ) : null}
            </div>
            {product.manufacturer ? (
              <div className={`${cl['manufacturer-card']} ${cl['product__card']}`}>
                <NavLink
                  to={`/manufacturers/${product.manufacturer.id}`}
                  className={cl['manufacturer-card__body']}
                >
                  <div className={cl['manufacturer-card__image']}>
                    {product.manufacturer.logo ? (
                      <img src={product.manufacturer.logo} alt={product.manufacturer.title} />
                    ) : (
                      <img src={require('assets/images/empty.png')} alt="" />
                    )}
                  </div>
                  <div>
                    <PRTitle tag="h4">{product.manufacturer.title}</PRTitle>
                    <p className={cl['manufacturer-card__address']}>
                      {product.manufacturer.country.title}, {product.manufacturer.address}
                    </p>
                  </div>
                </NavLink>
                <div className={cl['manufacturer-card__footer']}>
                  <p>
                    {t('common.sinceOnSite')}
                    <span>{product.manufacturer.createdAt.toLocaleDateString()}</span>
                  </p>
                  <p>
                    Последняя активность: <span>{t('common.today')}</span>
                  </p>
                </div>
              </div>
            ) : null}
          </div>
        </div>
        <div className={`${cl['product__card']} ${cl['description-card']}`}>
          <PRTitle tag="h3">{t('common.productDescription')}</PRTitle>
          <div
            className={`${cl['description-card__text']} ${
              isDescExpanded ? cl['description-card__text--expand'] : ''
            }`}
            dangerouslySetInnerHTML={{ __html: product.description }}
          />
          {product.description.length > 250 ? (
            <PRButton
              variant="text"
              suffix={
                <ArrowIcon className={isDescExpanded ? cl['description-card__arrow-up'] : ''} />
              }
              onClick={() => setDescExpanded((val) => !val)}
            >
              {isDescExpanded ? t('action.hide') : t('action.expand')}
            </PRButton>
          ) : null}
        </div>
        {product.manufacturer ? <OtherProducts manufacturerId={product.manufacturer.id} /> : null}
      </div>
    </div>
  );
};

export default ProductDetail;
