import { CityOption, CountryOption, Option, TradeOption, UnitOption } from 'entities/Option';

import {
  CityOptionDao,
  CountryOptionDao,
  OptionDao,
  TradeOptionDao,
  UnitOptionDao,
} from '../dao/Option.dao';

import { mapDocumentDaoToEntity } from './document.mappers';

export const mapOptionDaoToEntity = (option: OptionDao): Option => ({
  value: option.id,
  label: option.name,
});

export const mapCountryOptionToEntity = (option: CountryOptionDao): CountryOption => ({
  value: option.id,
  label: option.name,
  phoneCode: option.phoneCode,
  center: [option.latitude, option.longitude],
  zoom: option.zoom,
  code: option.code,
});

export const mapCityOptionToEntity = (option: CityOptionDao): CityOption => ({
  value: option.id,
  label: option.name,
  center: [option.latitude, option.longitude],
  zoom: option.zoom,
});

export const mapTradeOptionToEntity = (option: TradeOptionDao): TradeOption => ({
  value: option.id,
  label: option.name,
  description: option.description,
  logo: option.logo ? mapDocumentDaoToEntity(option.logo) : null,
});

export const mapUnitOptionToEntity = (option: UnitOptionDao): UnitOption => ({
  value: option.id,
  label: option.name,
  shortName: option.shortName,
});
