import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSearchParams } from 'react-router-dom';
import { ReactComponent as SearchIcon } from 'assets/icons/search.svg';
import PRHomeMap from 'components/PRHomeMap';
import PRInput from 'components/PRInput';
import { ClusterPointDto } from 'types/dto/cluster-point.dto';
import { fetchBranchTypes } from 'api/repositories/handbooks.repository';
import { fetchBranchesByFilter } from 'api/repositories/manufacturer.repository';
import { TradeOption } from 'entities/Option';

import { FilterParamsDto } from '../../dto/filter-params.dto';

import cl from './map-holder.module.scss';

interface Props {
  params: FilterParamsDto;
  onFilterChange: (params: Partial<FilterParamsDto>) => void;
}

const MapHolder: React.FC<Props> = ({ params, onFilterChange }) => {
  const [query] = useSearchParams();
  const { t } = useTranslation();

  const [branchTypes, setBranchTypes] = useState<TradeOption[]>([]);
  const [timer, setTimer] = useState(0);
  const [productName, setProductName] = useState(params.name || '');
  const [zoom, setZoom] = useState(10);
  const [points, setPoints] = useState<ClusterPointDto[]>([]);

  const section = query.get('section');

  useEffect(() => {
    fetchBranchTypes().then((res) => {
      setBranchTypes(res);
    });
  }, []);

  useEffect(() => {
    fetchBranchesByFilter(params).then((res) => {
      setPoints(
        res.map((branch) => ({
          icon: branch.trade.logo?.link || '',
          cords: branch.coordinates,
          point: {
            id: branch.id,
            title: branch.title,
            address: branch.address,
            link: `/manufacturers/${branch.manufacturer.id}`,
          },
        }))
      );
    });
  }, [params.name, params.trade, params.region, params.area, params.category, params.subcategory]);

  const onTradeSelect = (val: number) => {
    if (params.trade === val) {
      onFilterChange({ trade: null });
    } else {
      onFilterChange({ trade: val });
    }
  };

  const onNameChange = (val: string) => {
    window.clearTimeout(timer);
    setProductName(val);

    setTimer(
      window.setTimeout(() => {
        onFilterChange({ name: val || null });
      }, 1000)
    );
  };

  return (
    <div className={cl.holder}>
      <PRHomeMap
        center={[51.128315, 71.430648]}
        zoom={zoom}
        changeZoom={setZoom}
        clusterOptions={points}
      />

      <div className={cl['holder__header']}>
        <div className={cl['holder__search']}>
          <PRInput
            name="product-name"
            value={productName}
            onChange={onNameChange}
            placeholder={
              section === 'product' ? t('common.productName') : t('common.manufacturerName')
            }
            suffix={<SearchIcon className={cl['holder__search-icon']} />}
          />
        </div>
        <div className={cl['holder__buttons']}>
          {branchTypes.map((option) => (
            <button
              key={option.value}
              className={params.trade === option.value ? cl['active'] : ''}
              onClick={() => onTradeSelect(option.value)}
            >
              {option.label}
            </button>
          ))}
        </div>
      </div>
    </div>
  );
};

export default MapHolder;
