import { Branch } from 'entities/Branch';

import request from '../axios-instance';
import { mapManufacturerBranchToEntity } from '../mappers/manufacturer.mappers';

export const fetchCompanyBranches = () =>
  request.get('branches/my').then((res) => {
    const branches: Branch[] = res.data.content.map(mapManufacturerBranchToEntity);
    return branches;
  });

export interface CreateBranchDto {
  name: string;
  typeId: number;
  contactPerson: string;
  phoneNumber?: string;
  email: string;
  countryId: number;
  cityId: number;
  addressText: string;
  latitude: number;
  longitude: number;
  contactPersonPositionId?: number;
}

export const createBranch = (data: CreateBranchDto) => request.post('branches', data);

export const updateBranch = (id: number, data: CreateBranchDto) =>
  request.patch(`branches/${id}`, data);

export const deleteBranch = (id: number) => request.delete(`branches/${id}`);
