import React from 'react';

import Step from './step';

import cl from './stepper.module.scss';

interface Props {
  step: number;
  labels: string[];
}

const PRStepper: React.FC<Props> = ({ step = 1, labels }) => {
  return (
    <div
      className={cl['stepper']}
      style={{ gridTemplateColumns: `repeat(${labels.length}, minmax(0, 1fr))` }}
    >
      {labels.map((l, index) => (
        <Step key={index} step={index + 1} currentStep={step} />
      ))}

      {labels.map((label, index) => (
        <p key={index}>{label}</p>
      ))}
    </div>
  );
};

export default PRStepper;
