import React from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import PRAsyncSelect from 'components/PRAsyncSelect';
import PRButton from 'components/PRButton';
import PRCheckbox from 'components/PRCheckbox';
import PRInput from 'components/PRInput';
import PRTitle from 'components/PRTitle';
import { registrationSlice, SecondStep } from 'store/registration';
import { EMAIL_REGEX } from 'types/constants/email.regex';
import { useAppDispatch, useAppSelector } from 'hooks/redux';
import { fetchCountries } from 'api/repositories/handbooks.repository';
import { checkEmailAvailability } from 'api/repositories/users.repository';
import { Option } from 'entities/Option';

import cl from './second-step.module.scss';

const RegistrationSecondStep = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const { second } = useAppSelector((state) => state.registration);

  const {
    control,
    formState: { isSubmitting },
    handleSubmit,
  } = useForm({ defaultValues: { ...second } });

  const onSubmit = (data: SecondStep) => {
    dispatch(registrationSlice.actions.setSecondStep(data));
  };

  return (
    <form className={cl['second-step']} onSubmit={handleSubmit(onSubmit)} noValidate>
      <PRTitle tag="h1">{t('message.signUp')}</PRTitle>

      <p className={cl['first-step__text']}>{t('message.infoAboutYourCompany')}</p>

      <div className={cl['second-step__two']}>
        <Controller
          control={control}
          name="firstName"
          rules={{ required: { message: t('error.required'), value: true } }}
          render={({ field: { onChange, onBlur, value, name, ref }, fieldState: { error } }) => (
            <PRInput
              ref={ref}
              label={t('form.firstName')}
              name={name}
              value={value}
              error={error?.message}
              required
              onChange={onChange}
              onBlur={onBlur}
            />
          )}
        />

        <Controller
          control={control}
          name="lastName"
          rules={{ required: { message: t('error.required'), value: true } }}
          render={({ field: { onChange, onBlur, value, name, ref }, fieldState: { error } }) => (
            <PRInput
              ref={ref}
              label={t('form.lastName')}
              name={name}
              value={value}
              error={error?.message}
              required
              onChange={onChange}
              onBlur={onBlur}
            />
          )}
        />
      </div>

      <div className={cl['second-step__two']}>
        <Controller
          control={control}
          name="country"
          rules={{ required: { message: t('error.required'), value: true } }}
          render={({ field: { onChange, onBlur, value }, fieldState: { error } }) => (
            <PRAsyncSelect
              value={value}
              label={t('form.country')}
              fetchList={fetchCountries}
              error={error?.message}
              searchParams={{ page_size: 250 }}
              defaultOptions
              isClearable
              required
              onChange={(val) => onChange(val as Option)}
              onBlur={onBlur}
            />
          )}
        />

        <Controller
          control={control}
          name="email"
          rules={{
            required: { message: t('error.required'), value: true },
            pattern: {
              message: t('error.invalidEmail'),
              value: EMAIL_REGEX,
            },
            validate: async (value) => {
              const isAccountExists = await checkEmailAvailability(value);
              if (isAccountExists) {
                return t('error.emailNotAvailable');
              }
            },
          }}
          render={({ field: { onChange, onBlur, value, name, ref }, fieldState: { error } }) => (
            <PRInput
              label={t('form.email')}
              ref={ref}
              name={name}
              value={value}
              error={error?.message}
              required
              onChange={onChange}
              onBlur={onBlur}
            />
          )}
        />
      </div>

      <div className={cl['second-step__agreement']}>
        <Controller
          control={control}
          name="isAgree"
          rules={{ required: { message: t('error.required'), value: true } }}
          render={({ field: { onChange, value, name, ref }, fieldState: { error } }) => (
            <PRCheckbox ref={ref} name={name} value={value} onChange={onChange}>
              <span className={error?.message ? cl['second-step__error'] : ''}>
                Я ознакомлен(а) и принимаю <Link to="#">условия обработки данных</Link>
              </span>
            </PRCheckbox>
          )}
        />
      </div>

      <div className={cl['second-step__submit']}>
        <PRButton loading={isSubmitting} type="submit">
          {t('action.next')}
        </PRButton>
      </div>
    </form>
  );
};

export default RegistrationSecondStep;
