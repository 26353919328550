import { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { usePartialState } from 'hooks/partial-state';
import { deleteProduct, fetchMyProducts } from 'api/repositories/products.repository';
import { Product } from 'entities/Product';

export const useProducts = () => {
  const [query, setQuery] = useSearchParams();
  const search = query.get('search');
  const page = query.get('page');

  const [filters, setFilters] = usePartialState({
    search: search ?? '',
    page: page ? +page : 1,
    perPage: 20,
    lastPage: 1,
  });
  const [products, setProducts] = useState<Product[]>([]);

  useEffect(() => {
    fetchProducts();
  }, [filters.page, filters.search]);

  const onDeleteProduct = (id: number) => {
    deleteProduct(id).then(() => {
      fetchProducts();
    });
  };

  const fetchProducts = () => {
    const params = {
      page: filters.page,
      page_size: filters.perPage,
      ...(filters.search && { search: filters.search }),
    };

    query.set('page', filters.page.toString());
    if (filters.search) {
      query.set('search', filters.search);
    } else {
      query.delete('search');
    }
    setQuery(query);

    fetchMyProducts(params).then((res) => {
      setProducts(res.products);
      setFilters({ lastPage: res.meta.lastPage });
    });
  };

  return { products, filters, setFilters, onDeleteProduct };
};
