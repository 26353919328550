export default {
  submit: 'ارسال',
  email: 'پست الکترونیکی',
  password: 'رمز عبور',
  title: 'عنوان',
  workingArea: 'حوزه کاری',
  country: 'کشور',
  legalAddress: 'آدرس حقوقی',
  bin: 'شماره ثبت مالیاتی (BIN)',
  website: 'وب‌سایت',
  phoneNumber: 'شماره تلفن',
  fullName: 'نام کامل',
  position: 'سمت',
  firstName: 'نام',
  lastName: 'نام خانوادگی',
  patronymic: 'نام پدر',
  confirmPassword: 'تایید رمز عبور',
  newPassword: 'رمز عبور جدید',
  createAccount: 'ساخت حساب کاربری',
  forgotPassword: 'رمز عبور را فراموش کرده‌ام',
  city: 'شهر',
  description: 'توضیحات',
  category: 'دسته بندی',
  subcategory: 'زیردسته بندی',
  region: 'منطقه',
  industry: 'صنعت',
  showOtherCompanies: 'نمایش شرکت‌های دیگر که در منطقه من موجود نیستند',
  companyName: 'نام شرکت',
  cooperationPurpose: 'هدف همکاری',
  offer: 'پیشنهاد',
  expectedBenefits: 'مزایای مورد انتظار',
  searchByIdTitle: 'جستجو بر اساس نام، شناسه یا شماره محصول',
  selectColumnName: 'نام ستون را انتخاب کنید',
  positionName: 'نام سمت',
  width: 'عرض، {{unit}}',
  height: 'ارتفاع، {{unit}}',
  length: 'طول، {{unit}}',
  weight: 'وزن، {{unit}}',
  orderFrom: 'سفارش از',
  keyPhrases: 'عبارات کلیدی را وارد کنید که مشتریان محصول شما را جستجو می‌کنند',
  contactPerson: 'نفر تماس',
  code: 'کد',
  street: 'خیابان',
  writeMessage: 'پیام خود را وارد کنید',
  branchType: 'نوع شعبه سازمان',
};
