import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { NavLink } from 'react-router-dom';
import { ReactComponent as DiscoveryIcon } from 'assets/icons/discovery.svg';
import { ReactComponent as EmailIcon } from 'assets/icons/email.svg';
import { ReactComponent as HomeIcon } from 'assets/icons/home.svg';
import { ReactComponent as ProfileIcon } from 'assets/icons/profile.svg';
import { ReactComponent as QuestionIcon } from 'assets/icons/question.svg';
import { ReactComponent as ServicesIcon } from 'assets/icons/services.svg';
import { ReactComponent as TodoIcon } from 'assets/icons/todo.svg';
import { useAppDispatch, useAppSelector } from 'hooks/redux';
import { useWindowSize } from 'hooks/window-size';

import { settingsSlice } from '../../store/settings';

import cl from './sidebar.module.scss';

const PRSidebar = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const { user } = useAppSelector((state) => state.auth);
  const { miniNavbar, mobileNavbar } = useAppSelector((state) => state.settings);
  const device = useWindowSize();

  const mainNav = [
    {
      path: '/',
      name: t('common.main'),
      icon: <DiscoveryIcon />,
    },
  ];

  const commonNav = [
    {
      path: '/about',
      name: 'О Profind',
      icon: <ServicesIcon />,
    },
    {
      path: '/faq',
      name: t('common.questionAndAnswer'),
      icon: <QuestionIcon />,
    },
  ];

  const companyNav = [
    {
      path: '/company/catalog',
      name: t('common.catalog'),
      icon: <TodoIcon />,
    },
    // {
    //   path: '/company/requests',
    //   name: t('common.applications'),
    //   icon: <EmailIcon />,
    // },
    // {
    //   path: '/company/partnership',
    //   name: t('common.partnership'),
    //   icon: <ProfileIcon />,
    // },
    {
      path: '/company/branches',
      name: t('common.branches'),
      icon: <HomeIcon />,
    },
  ];

  const sidebarClass = useMemo(() => {
    let cls = cl['sidebar'];

    if (miniNavbar && device === 'desktop') {
      cls += ` ${cl['sidebar--small']}`;
    }

    if (mobileNavbar && device !== 'desktop') {
      cls += ` ${cl['sidebar--mobile']}`;
    }

    return cls;
  }, [device, miniNavbar, mobileNavbar]);

  const closeMobileSidebar = () => {
    if (mobileNavbar) {
      dispatch(settingsSlice.actions.toggleMobileNavbar());
    }
  };

  return (
    <nav className={sidebarClass}>
      <div className={cl['sidebar__overlay']} onClick={closeMobileSidebar} />
      <ul className={cl['sidebar__block']}>
        {mainNav.map((item) => (
          <li key={item.path}>
            <NavLink
              className={({ isActive }) =>
                `${cl['sidebar__link']} ${isActive ? cl['sidebar__link--active'] : ''}`
              }
              to={item.path}
              end
              onClick={closeMobileSidebar}
            >
              {item.icon}
              <span className={cl['sidebar__nav-title']}>{item.name}</span>
            </NavLink>
          </li>
        ))}
      </ul>
      {/*<h4 className={cl['sidebar__header']}>{t('common.aboutPlatform')}</h4>*/}
      {/*<ul className={cl['sidebar__block']}>*/}
      {/*  {commonNav.map((item) => (*/}
      {/*    <li key={item.path}>*/}
      {/*      <NavLink*/}
      {/*        className={({ isActive }) =>*/}
      {/*          `${cl['sidebar__link']} ${isActive ? cl['sidebar__link--active'] : ''}`*/}
      {/*        }*/}
      {/*        to={item.path}*/}
      {/*        onClick={closeMobileSidebar}*/}
      {/*      >*/}
      {/*        {item.icon}*/}
      {/*        <span className={cl['sidebar__nav-title']}>{item.name}</span>*/}
      {/*      </NavLink>*/}
      {/*    </li>*/}
      {/*  ))}*/}
      {/*</ul>*/}
      {user && user.type === 'ORGANIZATION' ? (
        <>
          <h4 className={cl['sidebar__header']}>{t('common.manageCompany')}</h4>
          <ul className={cl['sidebar__block']}>
            {companyNav.map((item) => (
              <li key={item.path}>
                <NavLink
                  className={({ isActive }) =>
                    `${cl['sidebar__link']} ${isActive ? cl['sidebar__link--active'] : ''}`
                  }
                  to={item.path}
                  onClick={closeMobileSidebar}
                >
                  {item.icon}
                  <span className={cl['sidebar__nav-title']}>{item.name}</span>
                </NavLink>
              </li>
            ))}
          </ul>
        </>
      ) : null}
    </nav>
  );
};

export default PRSidebar;
