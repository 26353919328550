export default {
  submit: 'Gönder',
  email: 'E-posta',
  password: 'Şifre',
  title: 'Başlık',
  workingArea: 'Çalışma Alanı',
  country: 'Ülke',
  legalAddress: 'Yasal Adres',
  bin: 'VKN',
  website: 'Web Sitesi',
  phoneNumber: 'Telefon Numarası',
  fullName: 'Tam Adı',
  position: 'Pozisyon',
  firstName: 'Ad',
  lastName: 'Soyad',
  patronymic: 'Orta Ad',
  confirmPassword: 'Şifreyi Onayla',
  newPassword: 'Yeni Şifre',
  createAccount: 'Hesap Oluştur',
  forgotPassword: 'Şifremi Unuttum',
  city: 'Şehir',
  description: 'Açıklama',
  category: 'Kategori',
  subcategory: 'Alt Kategori',
  region: 'Bölge',
  industry: 'Endüstri',
  showOtherCompanies: 'Bölgenizde olmayan şirketleri göster',
  companyName: 'Şirket Adı',
  cooperationPurpose: 'İşbirliği Amacı',
  offer: 'Teklif',
  expectedBenefits: 'Beklenen Faydalar',
  searchByIdTitle: 'Başlık, Kimlik veya Ürün Koduna Göre Ara',
  selectColumnName: 'Sütun Adını Seç',
  positionName: 'Pozisyon Adı',
  width: 'Genişlik, {{unit}}',
  height: 'Yükseklik, {{unit}}',
  length: 'Uzunluk, {{unit}}',
  weight: 'Ağırlık, {{unit}}',
  orderFrom: 'Siparişten İtibaren',
  keyPhrases: 'Müşterilerin ürününüzü aramak için kullanacakları anahtar kelimeleri girin',
  contactPerson: 'İletişim Kişisi',
  code: 'Kod',
  street: 'Cadde/Sokak',
  writeMessage: 'Mesajınızı Yazın',
  branchType: 'Şirket Türü',
};
