import React, { useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { ReactComponent as PenIcon } from 'assets/icons/pen.svg';
import PRAsyncSelect from 'components/PRAsyncSelect';
import PRButton from 'components/PRButton';
import PRInput from 'components/PRInput';
import PRTitle from 'components/PRTitle';
import { EMAIL_REGEX } from 'types/constants/email.regex';
import { useAppSelector } from 'hooks/redux';
import { fetchCountries } from 'api/repositories/handbooks.repository';
import { CountryOption } from 'entities/Option';
import { Person } from 'entities/User';

import cl from './info.module.scss';

const PersonInfo = () => {
  const { t } = useTranslation();
  const user = useAppSelector((state) => state.auth.user) as Person;
  const [isDisabled, setIsDisabled] = useState(true);

  const {
    control,
    formState: { isSubmitting },
    handleSubmit,
    reset,
  } = useForm({
    defaultValues: {
      firstName: user.firstName,
      lastName: user.lastName,
      country: user.country,
      email: user.email,
    },
  });

  const onCancel = () => {
    setIsDisabled(true);
    reset();
  };

  const onSubmit = () => {
    console.log('submit');
  };

  return (
    <div className={cl['card']}>
      <div className={cl['card__header']}>
        <PRTitle tag="h3">{t('common.profile')}</PRTitle>

        {isDisabled ? (
          <PRButton
            variant="text"
            prefix={<PenIcon className={cl['card__pen-icon']} />}
            onClick={() => setIsDisabled(false)}
          >
            {t('action.edit')}
          </PRButton>
        ) : null}
      </div>

      <div className={cl['card__block']}>
        <div className={cl['card__sub-title']}>
          <p>{t('common.personalData')}</p>
          <p>Будьте в курсе новых личных сообщений от других пользователей.</p>
        </div>
        <div className={cl['card__form']}>
          <Controller
            control={control}
            name="firstName"
            rules={{ required: { message: t('error.required'), value: true } }}
            render={({ field, fieldState: { error } }) => (
              <PRInput
                {...field}
                label={t('form.firstName')}
                disabled={isDisabled}
                error={error?.message}
                required
              />
            )}
          />
          <Controller
            control={control}
            name="lastName"
            rules={{ required: { message: t('error.required'), value: true } }}
            render={({ field, fieldState: { error } }) => (
              <PRInput
                {...field}
                label={t('form.lastName')}
                disabled={isDisabled}
                error={error?.message}
                required
              />
            )}
          />
          <Controller
            control={control}
            name="country"
            rules={{ required: { message: t('error.required'), value: true } }}
            render={({ field: { onChange, onBlur, value }, fieldState: { error } }) => (
              <PRAsyncSelect
                value={value}
                label={t('form.country')}
                fetchList={fetchCountries}
                isDisabled={isDisabled}
                error={error?.message}
                isClearable
                required
                onChange={(val: unknown) => onChange(val as CountryOption)}
                onBlur={onBlur}
              />
            )}
          />
          <Controller
            control={control}
            name="email"
            rules={{
              required: { message: t('error.required'), value: true },
              pattern: {
                message: t('error.invalidEmail'),
                value: EMAIL_REGEX,
              },
            }}
            render={({ field, fieldState: { error } }) => (
              <PRInput
                {...field}
                label={t('form.email')}
                disabled={isDisabled}
                error={error?.message}
                required
              />
            )}
          />
        </div>
      </div>

      {isDisabled ? null : (
        <div className={cl['card__footer']}>
          <PRButton loading={isSubmitting} onClick={handleSubmit(onSubmit)}>
            {t('action.saveChanges')}
          </PRButton>
          <PRButton variant="text" onClick={onCancel}>
            {t('action.cancel')}
          </PRButton>
        </div>
      )}
    </div>
  );
};

export default PersonInfo;
