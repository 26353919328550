import { Branch } from 'entities/Branch';
import { Manufacturer } from 'entities/Manufacturer';

import request from '../axios-instance';
import {
  mapManufacturerBranchToEntity,
  mapManufacturerDaoToEntity,
} from '../mappers/manufacturer.mappers';
import { mapMetaDaoToMetaEntity } from '../mappers/meta.mappers';

export const fetchManufacturers = (params: Record<string, string | number | null>) =>
  request.get('organizations', { params }).then((res) => {
    const { content, ...rest } = res.data;
    const manufacturers: Manufacturer[] = content.map(mapManufacturerDaoToEntity);
    const meta = mapMetaDaoToMetaEntity(rest);

    return {
      manufacturers,
      meta,
    };
  });

export const fetchManufacturerById = (id: number) =>
  request.get(`organizations/${id}`).then((res) => mapManufacturerDaoToEntity(res.data));

export const fetchManufacturerBranches = (id: number) =>
  request
    .post('branches/search', {
      page: 1,
      page_size: 20,
      organization: { id },
    })
    .then((res) => {
      const branches: Branch[] = res.data.content.map(mapManufacturerBranchToEntity);
      return branches;
    });

interface BranchesFilterDto {
  section: string;
  trade: number | null;
  region: number | null;
  area: number | null;
  name: string | null;
  category: number | null;
  subcategory: number | null;
}

export const fetchBranchesByFilter = (params: BranchesFilterDto) =>
  request
    .post('branches/search?page=1&page_size=1000', {
      ...(params.trade && { typeId: params.trade }),
      organization: {
        ...(params.region && { countryId: params.region }),
        ...(params.area && { industryId: params.area }),
        ...(params.section === 'manufacturer' && params.name && { name: params.name }),
      },
      product: {
        ...(params.category && { categoryId: params.category }),
        ...(params.subcategory && { subcategoryId: params.subcategory }),
        ...(params.section === 'product' && params.name && { name: params.name }),
      },
    })
    .then((res) => {
      const branches: Branch[] = res.data.content.map(mapManufacturerBranchToEntity);
      return branches;
    });
