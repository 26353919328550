import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { NavLink, useNavigate } from 'react-router-dom';
import { ReactComponent as BookmarkIcon } from 'assets/icons/bookmark.svg';
import { ReactComponent as ChatIcon } from 'assets/icons/chat.svg';
import { ReactComponent as HelpIcon } from 'assets/icons/help-chat.svg';
import { ReactComponent as LogoutIcon } from 'assets/icons/logout.svg';
import { ReactComponent as SearchIcon } from 'assets/icons/search.svg';
import { ReactComponent as UserCircleIcon } from 'assets/icons/user-circle.svg';
import { ReactComponent as UserIcon } from 'assets/icons/user-line.svg';
import { ReactComponent as FullLogo } from 'assets/images/full-logo.svg';
import { settingsSlice } from 'store/settings';
import { useAppDispatch, useAppSelector } from 'hooks/redux';
import { useWindowSize } from 'hooks/window-size';

import { authSlice } from '../../store/auth';
import PRButton from '../PRButton';
import PRDropdown from '../PRDropdown';
import PRInput from '../PRInput';
import PRLangSelect from '../PRLangSelect';
import PRNotification from '../PRNotification';

import cl from './header.module.scss';

const PRHeader = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const { user } = useAppSelector((state) => state.auth);
  const device = useWindowSize();
  const [search, setSearch] = useState('');
  const [hasNotification, setHasNotification] = useState(true);

  const userMenu = [
    {
      id: 1,
      title: 'Личный кабинет',
      icon: <UserCircleIcon />,
      onClick: () => goToProfile(),
    },
    // {
    //   id: 2,
    //   title: 'Служба поддержки',
    //   path: '',
    //   icon: <HelpIcon />,
    // },
    {
      id: 3,
      title: 'Выйти',
      icon: <LogoutIcon />,
      styles: { color: '#FF675E' },
      onClick: () => {
        dispatch(authSlice.actions.logout());
      },
    },
  ];

  const toggleNavbar = () => {
    if (device === 'desktop') {
      dispatch(settingsSlice.actions.toggleNavbar());
    } else {
      dispatch(settingsSlice.actions.toggleMobileNavbar());
    }
  };

  const goToLogin = () => {
    navigate('/auth/login');
  };

  const goToRegister = () => {
    navigate('/auth/registration');
  };

  const goToProfile = () => {
    if (user?.type === 'USER') {
      navigate('/profile');
    } else {
      navigate('/company/profile');
    }
  };

  const goToMessages = () => {
    navigate('/company/messages');
  };

  return (
    <header className={cl.header}>
      <div className={cl['header__left']}>
        <div className={`${cl['burger']} ${cl['burger--left']}`} onClick={toggleNavbar}>
          <div className={`${cl['burger__line']} ${cl['burger__top']}`} />
          <div className={`${cl['burger__line']} ${cl['burger__center']}`} />
          <div className={`${cl['burger__line']} ${cl['burger__bottom']}`} />
        </div>
        <NavLink to="/">
          <FullLogo className={cl['header__full-logo']} />
        </NavLink>
        {/*<div className={cl['header__search']}>*/}
        {/*  <PRInput*/}
        {/*    name="header-search"*/}
        {/*    value={search}*/}
        {/*    onChange={setSearch}*/}
        {/*    suffix={<SearchIcon className={cl['header__search-icon']} />}*/}
        {/*    placeholder="Название производителя или товара"*/}
        {/*  />*/}
        {/*</div>*/}
      </div>
      <div className={cl['header__right']}>
        <div className={cl['header__lang-select']}>
          <PRLangSelect />
        </div>

        {user ? (
          <div className={cl['header__icons']}>
            {/*<button*/}
            {/*  className={`${cl['header__button']} ${cl['header__chat']}`}*/}
            {/*  onClick={goToMessages}*/}
            {/*>*/}
            {/*  <ChatIcon />*/}
            {/*</button>*/}
            {/*<button className={`${cl['header__button']} ${cl['header__bookmark']}`}>*/}
            {/*  <BookmarkIcon />*/}
            {/*</button>*/}
            {/*<PRNotification hasNotification={hasNotification} />*/}
            <PRDropdown options={userMenu}>
              <p className={`${cl['header__button']} ${cl['header__avatar']}`}>
                {user.type === 'ORGANIZATION' && user.avatar ? (
                  <img src={user.avatar} alt={user.title} />
                ) : (
                  <UserIcon />
                )}
              </p>
            </PRDropdown>
          </div>
        ) : (
          <div className={cl['header__auth-buttons']}>
            <PRButton colorType="main" variant="text" onClick={goToLogin}>
              {t('action.login')}
            </PRButton>
            <PRButton colorType="main" variant="solid" onClick={goToRegister}>
              {t('action.signup')}
            </PRButton>
          </div>
        )}

        <div className={`${cl['burger']} ${cl['burger--right']}`} onClick={toggleNavbar}>
          <div className={`${cl['burger__line']} ${cl['burger__top']}`} />
          <div className={`${cl['burger__line']} ${cl['burger__center']}`} />
          <div className={`${cl['burger__line']} ${cl['burger__bottom']}`} />
        </div>
      </div>
    </header>
  );
};

export default PRHeader;
