import { Document } from 'entities/Document';

import request from '../axios-instance';
import { mapDocumentDaoToEntity } from '../mappers/document.mappers';

export const uploadFile = (fd: FormData) =>
  request.post('files', fd).then((res) => {
    return mapDocumentDaoToEntity(res.data.file);
  });

export const uploadMultipleFiles = (fd: FormData) =>
  request.post('files/batch', fd).then((res) => {
    const files: Document[] = res.data.files.map(mapDocumentDaoToEntity);
    return files;
  });
