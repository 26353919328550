import React, { forwardRef } from 'react';
import { ReactComponent as CheckIcon } from 'assets/icons/check.svg';

import cl from './checkbox.module.scss';

interface Props {
  name: string;
  value: boolean;
  children: React.ReactNode;
  onChange: (val: boolean) => void;
}

const PRCheckbox = forwardRef<HTMLInputElement, Props>(
  ({ name, children, value = false, onChange }, ref) => {
    const onCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
      onChange(event.target.checked);
    };

    return (
      <label className={cl.checkbox}>
        <input
          id={name}
          checked={value}
          ref={ref}
          className={cl['checkbox__input']}
          type="checkbox"
          onChange={onCheckboxChange}
        />
        <span className={cl['checkbox__mark']}>
          <CheckIcon />
        </span>
        {children}
      </label>
    );
  }
);

export default PRCheckbox;
