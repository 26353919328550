import { Conversation } from 'entities/Conversation';
import { Message } from 'entities/Message';

import request from '../axios-instance';
import { mapConversationDaoToEntity, mapMessageDaoToEntity } from '../mappers/conversation.mappers';
import { mapMetaDaoToMetaEntity } from '../mappers/meta.mappers';

export const fetchConversations = () =>
  request.get('conversations').then((res) => {
    const { content, ...rest } = res.data;
    const conversations: Conversation[] = content.map(mapConversationDaoToEntity);
    const meta = mapMetaDaoToMetaEntity(rest);

    return {
      conversations,
      meta,
    };
  });

export const fetchConversationMessages = (id: number) =>
  request.get(`conversations/${id}/messages`).then((res) => {
    const { content, ...rest } = res.data;
    const messages: Message[] = content.map(mapMessageDaoToEntity);
    const meta = mapMetaDaoToMetaEntity(rest);
    return {
      messages,
      meta,
    };
  });

export const sendMessage = (id: number, text: string) =>
  request
    .post(`conversations/${id}/messages`, { text })
    .then((res) => mapMessageDaoToEntity(res.data.message));
