import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import PRPagination from 'components/PRPagination';
import PRProduct from 'components/PRProduct';
import { usePartialState } from 'hooks/partial-state';
import { fetchProducts } from 'api/repositories/products.repository';
import { Product } from 'entities/Product';

import cl from './catalog.module.scss';

const ManufacturerCatalog = () => {
  const { id } = useParams();
  const { t } = useTranslation();

  const [products, setProducts] = useState<Product[]>([]);
  const [pagination, setPagination] = usePartialState({ page: 1, lastPage: 1 });

  useEffect(() => {
    if (!id) return;

    fetchProducts(pagination.page, { manufacturer: { id: +id } }).then((res) => {
      setProducts(res.products);
      setPagination({ lastPage: res.meta.lastPage });
    });
  }, [id, pagination.page]);

  const toggleBookmark = (id: number) => {
    const updatedProducts = products.map((p) => {
      if (p.id === id) {
        p.bookmarked = !p.bookmarked;
      }
      return p;
    });
    setProducts(updatedProducts);
  };

  return (
    <div className={cl.catalog}>
      {products.length === 0 ? (
        <div className={cl['catalog__empty']}>{t('common.listEmpty')}</div>
      ) : null}
      <div className={cl['catalog__list']}>
        {products.map((product) => (
          <PRProduct key={product.id} product={product} toggleBookmark={toggleBookmark} />
        ))}
      </div>

      <PRPagination
        current={pagination.page}
        lastPage={pagination.lastPage}
        onChange={(page: number) => setPagination({ page })}
      />
    </div>
  );
};

export default ManufacturerCatalog;
