import React from 'react';
import { useTranslation } from 'react-i18next';
import { Navigate, Outlet } from 'react-router-dom';
import PRTabs from 'components/PRTabs';
import PRTitle from 'components/PRTitle';
import { useAppSelector } from 'hooks/redux';
import { Person } from 'entities/User';

import cl from './person.module.scss';

const PersonProfile = () => {
  const { t } = useTranslation();
  const user = useAppSelector((state) => state.auth.user) as Person;
  const isLoading = useAppSelector((state) => state.auth.isLoading);

  const tabs = [
    {
      id: 1,
      action: `/profile`,
      title: t('common.profile'),
    },
    {
      id: 2,
      action: `/profile/settings`,
      title: t('common.settings'),
    },
  ];

  if (isLoading) {
    return <span>Loading...</span>;
  }

  if (!user) {
    return <Navigate to="/auth/login" state={{ from: '/profile' }} />;
  }

  if (user.type !== 'USER') {
    return <Navigate to="/company/profile" />;
  }

  return (
    <div className={cl['profile']}>
      <div className={cl['profile__card']}>
        <PRTitle tag="h1">
          {user.firstName} {user.lastName}
        </PRTitle>

        <PRTabs tabs={tabs} />
      </div>

      <Outlet />
    </div>
  );
};

export default PersonProfile;
