export default {
  main: 'Бош саҳифа',
  loading: 'Юкланиб ўтилмоқда...',
  listEmpty: 'Рўйхат бўш',
  attention: 'Эътироз',
  services: 'Хизматлар',
  applications: 'Мурожаатлар',
  partnership: 'Хамкорлик',
  branches: 'Филиаллар',
  catalog: 'Каталог',
  faq: 'Кўп бериладиган саволлар',
  companyData: 'Компания маълумотлари',
  employeeData: 'Ҳодим маълумотлари',
  accountActivation: 'Аккаунтни фаоллаштириш',
  aboutPlatform: 'Платформа ҳақида',
  manageCompany: 'Компанияни бошқариш',
  tradingAgency: 'Савдо вакиллиги',
  saleOfPoint: 'Сотиш нуқтаси',
  tradingHouse: 'Савдо уй',
  manufacturerName: 'Ишлаб чиқарувчи номи',
  productName: 'Маҳсулот номи',
  priceFrom: 'Нархи {{from}} дан',
  aboutManufacturer: 'Ишлаб чиқарувчи ҳақида',
  contacts: 'Алоқалар',
  manufacturers: 'Ишлаб чиқарувчилар',
  summary: 'Қисқартиб',
  certificates: 'Сертификатлар',
  yearsAgo: '{{num}} йил аввал',
  title: 'Номи',
  brandName: 'Бренд номи',
  legalAddress: 'Юридик манзил',
  jurisdiction: 'Юрисдикция',
  regNumberBin: 'Рўйхат рақами',
  registrationDate: 'Рўйхатдан ўтган сана',
  sinceOnSite: 'Сайтда {{date}} дан бери',
  product: 'Маҳсулот',
  vendorCode: 'Артикул',
  date: 'Сана',
  display: 'Кўрсатиш',
  inStock: 'Омборда',
  actions: 'Ҳаракатлар',
  required: 'Мажбурий',
  optional: 'Тақдим этилган',
  productList: 'Маҳсулотлар рўйхати',
  barcode: 'Баркод ёки UPC/EAN',
  color: 'Ранг',
  material: 'Материал',
  currency: 'Валюта',
  unitType: 'Олчов бирлиги',
  status: 'Статус',
  wholesalePrice: 'Оптовая нарх',
  from: 'да',
  mainInfo: 'Асосий маълумот',
  productDescription: 'Маҳсулот таърифи',
  dimensions: 'Ғабаритлар',
  characteristics: 'Ҳусусиятлар',
  price: 'Нархи',
  createAndUpdate: 'Яратиш ва янгилаш',
  created: 'Яратилди',
  updated: 'Янгиланди',
  searchTerms: 'Қидирув сўзлари',
  supportedFormats: 'Қўллаб-қувватланган форматлар: {{formats}}',
  maxSize: 'Максимал ҳажми: {{size}}',
  images: 'Расмлар',
  agency: 'Вакиллик',
  password: 'Парол',
  addingBranch: 'Филиал қўшиш',
  questionAndAnswer: 'Саволлар ва жавоблар',
  profile: 'Профиль',
  settings: 'Созламалар',
  requirementsForVerification: 'Тасдиқ учун талаблар',
  submitFollowingDocuments: 'Қуйидаги хужжатларни тақдим қилинг',
  companyRegCertificate: 'Компания рўйхатномаси',
  companyCharter: 'Компания устави',
  certificateTaxReg: 'Солиқ рўйхатга олиш сертификати',
  productionDoc: 'Ишлаб чиқариш ҳужжатлари',
  qualityCertificate: 'Сифат ва мувофиқлик сертификатлари',
  licensesAndCertificates: 'Лицензиялар ва сертификатлар',
  suppliersAndCustomersContracts: 'Таъминотчилар ва мижозлар биноси',
  accountDeleting: 'Аккаунтни ўчириш',
  passwordChanging: 'Паролни ўзгартириш',
  today: 'бугун',
  weight: 'вазн',
  availableOnOrder: 'Маҳсулот буюртма бўйича мавжуд',
  manufacturerOtherProducts: 'Ишлаб чиқарувчи бошқа маҳсулотлари',
  lastActivity: 'Сўнгги фаолият',
  passwordRecovery: 'Паролни қайта тиклаш',
  forgotPassword: 'Паролни унутдингизми?',
  haveNotAccount: 'Аккаунтингиз йўқми?',
  createPassword: 'Паролни яратиш',
  registration: 'Рўйхатдан ўтиш',
  haveAnAccount: 'Аккаунтингиз борми?',
  iAmManufacturer: 'Мен ишлаб чиқарувчиман',
  iAmUser: 'Мен фойдаланувчиман',
  aboutCompany: 'Компания ҳақида',
  logo: 'Логотип',
  personalData: 'Шахсий маълумотлар',
  notifications: 'Билдирувлар',
  todayAt: 'Бугун {{date}} санъатларда',
  yesterdayAt: 'Кеча {{date}} санъатларда',
  all: 'Барчаси',
  system: 'Тизим',
  notSpecified: 'Аниқланмаган',
};
