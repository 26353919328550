import React from 'react';
import { createBrowserRouter } from 'react-router-dom';
import Auth from 'layouts/auth';
import Company from 'layouts/company';
import Default from 'layouts/default';
import Login from 'pages/auth/views/login';
import Registration from 'pages/auth/views/registration';
import ResetPassword from 'pages/auth/views/reset-password';
import CompanyBranches from 'pages/branches/views/main';
import CreateProduct from 'pages/catalog/views/create-product';
import ImportProducts from 'pages/catalog/views/import-products';
import Catalog from 'pages/catalog/views/main';
import NotFound from 'pages/errors/404';
import FrequentlyAskedQuestions from 'pages/faq/views/main';
import Home from 'pages/home/views/main';
import About from 'pages/manufacturer/views/about';
import Branches from 'pages/manufacturer/views/branches';
import ManufacturerCatalog from 'pages/manufacturer/views/catalog';
import ManufacturerLayout from 'pages/manufacturer/views/main';
import Messages from 'pages/messages/views/main';
import ProductDetail from 'pages/products/views/detail';
import OrganizationProfile from 'pages/profile/views/organization';
import OrganizationInfo from 'pages/profile/views/organization-info';
import OrganizationSettings from 'pages/profile/views/organization-settings';
import PersonProfile from 'pages/profile/views/person';
import PersonInfo from 'pages/profile/views/person-info';
import PersonSettings from 'pages/profile/views/person-settings';
import RequestsLayout from 'pages/requests/views/main';
import PartnershipRequests from 'pages/requests/views/partnership';
import ProductRequests from 'pages/requests/views/product';

export const router = createBrowserRouter([
  {
    path: 'auth',
    element: <Auth />,
    children: [
      {
        path: 'login',
        element: <Login />,
      },
      {
        path: 'registration',
        element: <Registration />,
      },
      {
        path: 'reset-password',
        element: <ResetPassword />,
      },
    ],
  },
  {
    path: '/',
    element: <Default />,
    children: [
      {
        index: true,
        element: <Home />,
      },
      {
        path: 'manufacturers/:id',
        element: <ManufacturerLayout />,
        children: [
          {
            index: true,
            element: <ManufacturerCatalog />,
          },
          {
            path: 'about',
            element: <About />,
          },
          {
            path: 'branches',
            element: <Branches />,
          },
        ],
      },
      {
        path: 'products/:id',
        element: <ProductDetail />,
      },
      {
        path: 'faq',
        element: <FrequentlyAskedQuestions />,
      },
      {
        path: 'company',
        element: <Company />,
        children: [
          {
            path: 'catalog',
            element: <Catalog />,
          },
          {
            path: 'catalog/import',
            element: <ImportProducts />,
          },
          {
            path: 'catalog/create',
            element: <CreateProduct />,
          },
          {
            path: 'catalog/:id/edit',
            element: <CreateProduct />,
          },
          {
            path: 'branches',
            element: <CompanyBranches />,
          },
          {
            path: 'messages',
            element: <Messages />,
          },
          {
            path: 'profile',
            element: <OrganizationProfile />,
            children: [
              {
                index: true,
                element: <OrganizationInfo />,
              },
              {
                path: 'settings',
                element: <OrganizationSettings />,
              },
            ],
          },
          {
            path: 'requests',
            element: <RequestsLayout />,
            children: [
              {
                index: true,
                element: <PartnershipRequests />,
              },
              {
                path: 'product',
                element: <ProductRequests />,
              },
            ],
          },
        ],
      },
      {
        path: 'profile',
        element: <PersonProfile />,
        children: [
          {
            index: true,
            element: <PersonInfo />,
          },
          {
            path: 'settings',
            element: <PersonSettings />,
          },
        ],
      },
      {
        path: '*',
        element: <NotFound />,
      },
    ],
  },
]);
