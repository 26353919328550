export default {
  submit: 'Жиберүү',
  email: 'Эл. почта',
  password: 'Сыр сөз',
  title: 'Аталышы',
  workingArea: 'Иш жайы',
  country: 'Өлкө',
  legalAddress: 'Канундук дарек',
  bin: 'БИН',
  website: 'Веб-сайт',
  phoneNumber: 'Телефон номери',
  fullName: 'Толук аты',
  position: 'Мамлекеттик жыйынтык',
  firstName: 'Аты',
  lastName: 'Фамилия',
  patronymic: 'Отчество',
  confirmPassword: 'Сыр сөздү жакшыртуу',
  newPassword: 'Жаңы сыр сөз',
  createAccount: 'Аккаунт түзүү',
  forgotPassword: 'Сыр сөздү унуттуңузбу?',
  city: 'Үйгөрүүчү',
  description: 'Тайырыбы',
  category: 'Категория',
  subcategory: 'Боорук категория',
  region: 'Аймак',
  industry: 'Ишкерлек',
  showOtherCompanies: 'Менин аймагымда болгон компанияларды көрсөтүү',
  companyName: 'Компания аты',
  cooperationPurpose: 'Иштерди бириктирген максат',
  offer: 'Текшерүү',
  expectedBenefits: 'Күтүлгөн кайсы болуп саналат?',
  searchByIdTitle: 'Аталышы, ID же баар артикулу боюнча издөө',
  selectColumnName: 'Колонка атын тандоо',
  positionName: 'Позиция аты',
  width: 'Кириңиз, {{unit}}',
  height: 'Бириңиз, {{unit}}',
  length: 'Узундук, {{unit}}',
  weight: 'Масса, {{unit}}',
  orderFrom: 'Тапшыруучуга аба салууга',
  keyPhrases: 'Сатканчылар сиздин товарыңызды издөөгө катта сөздөрдү жазып калсын',
  contactPerson: 'Байланышкан адам',
  code: 'Код',
  street: 'Көчө',
  writeMessage: 'Сообшение жазыңыз',
  branchType: 'Салуу түрү',
};
