import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNotification } from 'hooks/notification';
import { fetchImportFields } from 'api/repositories/products.repository';
import { Option } from 'entities/Option';

interface ImportField {
  id: number;
  name: string;
  required: boolean;
}

export const useImportFields = () => {
  const { t } = useTranslation();
  const notify = useNotification();

  const [requiredFields, setRequiredFields] = useState<Option[]>([]);
  const [optionalFields, setOptionalFields] = useState<Option[]>([]);
  const [selectedFields, setSelectedFields] = useState<number[]>([]);

  useEffect(() => {
    fetchImportFields().then((res: ImportField[]) => {
      const required: Option[] = [];
      const optional: Option[] = [];
      for (const item of res) {
        const option = {
          value: item.id,
          label: item.name,
          isDisabled: false,
        };

        if (item.required) {
          required.push(option);
        } else {
          optional.push(option);
        }
      }
      setRequiredFields(required);
      setOptionalFields(optional);
    });
  }, []);

  const fields = useMemo(() => {
    return [
      {
        label: t('common.required'),
        options: requiredFields.map((f) => ({
          ...f,
          isDisabled: selectedFields.includes(f.value),
        })),
      },
      {
        label: t('common.optional'),
        options: optionalFields.map((f) => ({
          ...f,
          isDisabled: selectedFields.includes(f.value),
        })),
      },
    ];
  }, [selectedFields, optionalFields, requiredFields]);

  const prepareData = () => {
    if (!requiredFields.every((num) => selectedFields.includes(num.value))) {
      notify.error(t('error.selectRequiredColumns'));
      return null;
    }

    return selectedFields.map((num, index) => ({
      col: index,
      field: num,
    }));
  };

  return {
    fields,
    setSelectedFields,
    prepareData,
  };
};
