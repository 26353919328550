import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { ReactComponent as EmailIcon } from 'assets/icons/email.svg';
import { ReactComponent as PhoneIcon } from 'assets/icons/phone.svg';
import { ReactComponent as PinLineIcon } from 'assets/icons/pin-line.svg';
import { ReactComponent as UserLineIcon } from 'assets/icons/user-line.svg';
import PRButton from 'components/PRButton';
import PRMap from 'components/PRMap';
import PRTitle from 'components/PRTitle';
import { ClusterPointDto } from 'types/dto/cluster-point.dto';
import { useWindowSize } from 'hooks/window-size';
import { fetchBranchTypes } from 'api/repositories/handbooks.repository';
import { fetchManufacturerBranches } from 'api/repositories/manufacturer.repository';
import { Branch } from 'entities/Branch';
import { TradeOption } from 'entities/Option';

import cl from './branches.module.scss';

const INIT_CORDS: [number, number] = [51.161528, 71.40127];
const INIT_ZOOM = 11;

const Branches = () => {
  const { id } = useParams();
  const { t } = useTranslation();
  const device = useWindowSize();

  const [branchTypes, setBranchTypes] = useState<TradeOption[]>([]);
  const [isMobileMap, setIsMobileMap] = useState(false);
  const [filter, setFilter] = useState<number | null>(null);
  const [zoom, setZoom] = useState(INIT_ZOOM);
  const [center, setCenter] = useState<[number, number]>(INIT_CORDS);

  const [branches, setBranches] = useState<Branch[]>([]);

  useEffect(() => {
    if (!id) return;

    fetchManufacturerBranches(+id).then((res) => {
      setBranches(res);
    });
  }, [id]);

  useEffect(() => {
    if (device === 'desktop') {
      setIsMobileMap(false);
    }
  }, [device]);

  useEffect(() => {
    if (isMobileMap) {
      document.body.classList.add('modal-open');
    } else {
      document.body.classList.remove('modal-open');
    }
  }, [isMobileMap]);

  useEffect(() => {
    fetchBranchTypes().then((res) => {
      setBranchTypes(res);
    });
  }, []);

  const branchOptions = useMemo(
    () => branches.filter((b) => (filter ? b.trade.value === filter : true)),
    [branches, filter]
  );

  const clusterOptions = useMemo<ClusterPointDto[]>(() => {
    return branches.map((b) => ({
      icon: b.trade.logo ? b.trade.logo.link : '',
      cords: b.coordinates,
      point: { id: b.id, title: b.title, address: b.address },
    }));
  }, [branches]);

  const onChangeTab = (tradeId: number) => {
    setFilter(tradeId);
    setZoom(INIT_ZOOM);
    setCenter(INIT_CORDS);
  };

  const onSelectBranch = (branch: Branch) => {
    setCenter(branch.coordinates);
    setZoom(17);
    if (device !== 'desktop') {
      setIsMobileMap(true);
    }
  };

  return (
    <div className={cl.branches}>
      <div className={cl['branches__card']}>
        <div className={cl['branches__tabs']}>
          {branchTypes.map((type) => (
            <button
              key={type.value}
              className={filter === type.value ? cl['active'] : ''}
              onClick={() => onChangeTab(type.value)}
            >
              {type.label}
            </button>
          ))}
        </div>
        <div className={cl['branches__list']}>
          {branchOptions.map((branch) => (
            <div
              key={branch.id}
              className={cl['branches__item']}
              onClick={() => onSelectBranch(branch)}
            >
              <div className={cl['branches__trade']}>
                <p>
                  <img src={branch.trade.logo?.link} /> {branch.trade.label}
                </p>
              </div>
              <PRTitle tag="h3">{branch.title}</PRTitle>
              <p className={cl['branches__info']}>
                <UserLineIcon />
                {branch.contactPerson}
              </p>
              <div className={cl['branches__contact']}>
                {branch.phone ? (
                  <p className={cl['branches__info']}>
                    <PhoneIcon />
                    {branch.phone}
                  </p>
                ) : null}
                {branch.email ? (
                  <p className={cl['branches__info']}>
                    <EmailIcon />
                    {branch.email}
                  </p>
                ) : null}
              </div>
              <p className={cl['branches__info']}>
                <PinLineIcon />
                {branch.address}
              </p>
            </div>
          ))}
        </div>
      </div>
      <div className={`${cl['branches__map']} ${isMobileMap ? cl['branches__map--mobile'] : ''}`}>
        <div className={cl['branches__map-close']}>
          <PRButton onClick={() => setIsMobileMap(false)}>{t('action.close')}</PRButton>
        </div>
        <PRMap center={center} zoom={zoom} changeZoom={setZoom} clusterOptions={clusterOptions} />
      </div>
    </div>
  );
};

export default Branches;
