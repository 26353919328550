import React, { useMemo } from 'react';
import { Controller } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { ReactComponent as CloseIcon } from 'assets/icons/close.svg';
import { ReactComponent as PlusIcon } from 'assets/icons/plus.svg';
import { ReactComponent as TrashIcon } from 'assets/icons/trash.svg';
import PRAsyncSelect from 'components/PRAsyncSelect';
import PRButton from 'components/PRButton';
import PRInput from 'components/PRInput';
import PRRichTextEditor from 'components/PRRichTextEditor';
import PRTitle from 'components/PRTitle';
import useHandleError from 'hooks/handleError';
import { useNotification } from 'hooks/notification';
import {
  fetchCategories,
  fetchColors,
  fetchCurrencies,
  fetchMaterials,
  fetchSubcategories,
  fetchUnitTypes,
  fetchWorkingAreas,
} from 'api/repositories/handbooks.repository';
import { createProduct, updateProduct } from 'api/repositories/products.repository';
import { ErrorResponse } from 'entities/Error';
import { Option } from 'entities/Option';

import ProductImageForm from '../../components/ProductImagesForm';
import { CreateProductForm } from '../../dto/create-product.dto';
import { useCreateProductForm } from '../../hooks/create-product-form';

import cl from './create.module.scss';

const CreateProduct = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const notify = useNotification();
  const { handleCommonError } = useHandleError();

  const {
    productId,
    control,
    isSubmitting,
    handleSubmit,
    fields,
    getValues,
    setValue,
    watch,
    images,
    search,
    searchTerms,
    setImages,
    setSearch,
    addPrice,
    removePrice,
    onSearchSubmit,
    removeSearchTermAtPosition,
  } = useCreateProductForm();

  const workingArea = watch('area');
  const category = watch('category');

  const categoryKey = useMemo(() => {
    if (workingArea) {
      return workingArea.value;
    }
    return -1;
  }, [workingArea]);

  const subcategoryKey = useMemo(() => {
    if (category) {
      return category.value;
    }
    return -2;
  }, [category]);

  const currency = watch('currency');
  const unitType = watch('unitType');

  const createdAt = watch('createdAt');
  const updatedAt = watch('updatedAt');

  const isPriceVisible = useMemo(() => !!currency && !!unitType, [currency, unitType]);

  const resetCategory = (option: Option) => {
    if (workingArea?.value === option?.value) return;

    setValue('category', null);
    resetSubcategory(null);
  };

  const resetSubcategory = (option: Option | null) => {
    if (option?.value === category?.value) return;

    setValue('subcategory', null);
  };

  const onSubmit = async (form: CreateProductForm) => {
    const data = {
      externalId: Date.now().toString(),
      name: form.title,
      ...(form.vendorCode && { article: form.vendorCode }),
      ...(form.barcode && { barCode: form.barcode }),
      description: form.description,
      subcategoryId: form.subcategory!.value,
      ...(form.width && { width: +form.width }),
      ...(form.height && { height: +form.height }),
      ...(form.length && { length: +form.length }),
      ...(form.weight && { weight: +form.weight }),
      ...(form.color && { colorId: form.color?.value }),
      ...(form.material && { materialId: form.material.value }),
      currencyId: form.currency!.value,
      unitId: form.unitType!.value,
      prices: form.prices.map((p) => ({ price: +p.price, requiredQuantity: +p.quantityFrom })),
      keywords: searchTerms,
      images: images.filter((img) => !!img).map((img) => img!.id),
    };

    try {
      if (productId) {
        await updateProduct(+productId, data);
        notify.success('Товар удачно обновлен!');
      } else {
        await createProduct(data);
        notify.success('Товар удачно создан!');
      }
      navigate('/company/catalog');
    } catch (err: unknown) {
      const error = err as ErrorResponse;
      handleCommonError(error);
    }
  };

  const saveAsDraft = () => {
    const values = getValues();

    console.log('save as draft', values);
    console.log('images', images);
    console.log('search terms', searchTerms);
  };

  return (
    <div className={cl['create']}>
      <div className={`${cl['create__card']} ${cl['main']}`}>
        <PRTitle tag="h2">{t('common.mainInfo')}</PRTitle>

        <div className={cl['create__block']}>
          <PRTitle tag="h4">{t('common.productDescription')}</PRTitle>

          <Controller
            control={control}
            name="title"
            rules={{ required: { message: t('error.required'), value: true } }}
            render={({ field: { onChange, onBlur, value, name, ref }, fieldState: { error } }) => (
              <PRInput
                ref={ref}
                label={t('form.positionName')}
                name={name}
                value={value}
                error={error?.message}
                required
                onChange={onChange}
                onBlur={onBlur}
              />
            )}
          />

          <div className={cl['create__two']}>
            <Controller
              control={control}
              name="vendorCode"
              render={({ field }) => <PRInput {...field} label={t('common.vendorCode')} />}
            />
            <Controller
              control={control}
              name="barcode"
              render={({ field }) => <PRInput {...field} label={t('common.barcode')} />}
            />
          </div>

          <Controller
            control={control}
            name="description"
            rules={{
              required: { message: t('error.required'), value: true },
              maxLength: { message: t('error.maxLength', { len: 5000 }), value: 5000 },
            }}
            render={({ field: { onChange, value }, fieldState: { error } }) => (
              <PRRichTextEditor
                value={value}
                placeholder={`${t('form.description')} *`}
                error={error?.message}
                onChange={onChange}
              />
            )}
          />

          <div className={cl['create__three']}>
            <Controller
              control={control}
              name="area"
              rules={{ required: { message: t('error.required'), value: true } }}
              render={({ field: { onChange, onBlur, value }, fieldState: { error } }) => (
                <PRAsyncSelect
                  value={value}
                  label={t('form.industry')}
                  fetchList={fetchWorkingAreas}
                  error={error?.message}
                  defaultOptions
                  isClearable
                  required
                  onChange={(val) => {
                    resetCategory(val as Option);
                    onChange(val as Option);
                  }}
                  onBlur={onBlur}
                />
              )}
            />
            <Controller
              control={control}
              name="category"
              rules={{ required: { message: t('error.required'), value: true } }}
              render={({ field: { onChange, onBlur, value }, fieldState: { error } }) => (
                <PRAsyncSelect
                  key={categoryKey}
                  value={value}
                  label={t('form.category')}
                  fetchList={fetchCategories}
                  error={error?.message}
                  isDisabled={!workingArea}
                  searchParams={{ industry_id: workingArea?.value || 0 }}
                  defaultOptions={!!workingArea}
                  isClearable
                  required
                  onChange={(val) => {
                    resetSubcategory(val as Option);
                    onChange(val as Option);
                  }}
                  onBlur={onBlur}
                />
              )}
            />
            <Controller
              control={control}
              name="subcategory"
              rules={{ required: { message: t('error.required'), value: true } }}
              render={({ field: { onChange, onBlur, value }, fieldState: { error } }) => (
                <PRAsyncSelect
                  key={subcategoryKey}
                  value={value}
                  label={t('form.subcategory')}
                  fetchList={fetchSubcategories}
                  error={error?.message}
                  isDisabled={!category}
                  searchParams={{ category_id: category?.value || 0 }}
                  defaultOptions={!!category}
                  isClearable
                  required
                  onChange={(val) => onChange(val as Option)}
                  onBlur={onBlur}
                />
              )}
            />
          </div>
        </div>

        <div className={cl['create__block']}>
          <PRTitle tag="h4">{t('common.dimensions')}</PRTitle>
          <div className={cl['create__four']}>
            <Controller
              control={control}
              name="width"
              render={({ field: { onChange, value, name, ref } }) => (
                <PRInput
                  ref={ref}
                  label={t('form.width', { unit: 'см' })}
                  name={name}
                  value={value}
                  onChange={onChange}
                />
              )}
            />
            <Controller
              control={control}
              name="height"
              render={({ field: { onChange, value, name, ref } }) => (
                <PRInput
                  ref={ref}
                  label={t('form.height', { unit: 'см' })}
                  name={name}
                  value={value}
                  onChange={onChange}
                />
              )}
            />
            <Controller
              control={control}
              name="length"
              render={({ field: { onChange, value, name, ref } }) => (
                <PRInput
                  ref={ref}
                  label={t('form.length', { unit: 'см' })}
                  name={name}
                  value={value}
                  onChange={onChange}
                />
              )}
            />
            <Controller
              control={control}
              name="weight"
              render={({ field: { onChange, value, name, ref } }) => (
                <PRInput
                  ref={ref}
                  label={t('form.weight', { unit: 'г' })}
                  name={name}
                  value={value}
                  onChange={onChange}
                />
              )}
            />
          </div>
        </div>

        <div className={cl['create__block']}>
          <PRTitle tag="h4">{t('common.characteristics')}</PRTitle>
          <div className={cl['create__two']}>
            <Controller
              control={control}
              name="color"
              render={({ field: { onChange, value } }) => (
                <PRAsyncSelect
                  value={value}
                  label={t('common.color')}
                  fetchList={fetchColors}
                  defaultOptions
                  isClearable
                  onChange={(val) => onChange(val as Option)}
                />
              )}
            />
            <Controller
              control={control}
              name="material"
              render={({ field: { onChange, value } }) => (
                <PRAsyncSelect
                  value={value}
                  label={t('common.material')}
                  fetchList={fetchMaterials}
                  defaultOptions
                  isClearable
                  onChange={(val) => onChange(val as Option)}
                />
              )}
            />
          </div>
        </div>

        <div className={cl['create__block']}>
          <PRTitle tag="h4">{t('common.price')}</PRTitle>

          <div className={cl['create__two']}>
            <Controller
              control={control}
              name="currency"
              rules={{ required: { message: t('error.required'), value: true } }}
              render={({ field: { onChange, onBlur, value }, fieldState: { error } }) => (
                <PRAsyncSelect
                  value={value}
                  label={t('common.currency')}
                  fetchList={fetchCurrencies}
                  error={error?.message}
                  defaultOptions
                  isClearable
                  required
                  onChange={(val) => onChange(val as Option)}
                  onBlur={onBlur}
                />
              )}
            />
            <Controller
              control={control}
              name="unitType"
              rules={{ required: { message: t('error.required'), value: true } }}
              render={({ field: { onChange, onBlur, value }, fieldState: { error } }) => (
                <PRAsyncSelect
                  value={value}
                  label={t('common.unitType')}
                  fetchList={fetchUnitTypes}
                  error={error?.message}
                  defaultOptions
                  isClearable
                  required
                  onChange={(val) => onChange(val as Option)}
                  onBlur={onBlur}
                />
              )}
            />
          </div>
          {isPriceVisible &&
            fields.map((item, index) => (
              <div key={index} className={cl['create__price']}>
                <div className={cl['create__three']}>
                  <Controller
                    control={control}
                    name={`prices.${index}.price`}
                    rules={{ required: { message: t('error.required'), value: true } }}
                    render={({
                      field: { onChange, onBlur, value, name, ref },
                      fieldState: { error },
                    }) => (
                      <PRInput
                        ref={ref}
                        label={t('common.wholesalePrice')}
                        name={name}
                        value={value}
                        error={error?.message}
                        type="number"
                        required
                        onChange={(val) => onChange(val === '0' ? '' : val)}
                        onBlur={onBlur}
                      />
                    )}
                  />
                  <Controller
                    control={control}
                    name={`prices.${index}.quantityFrom`}
                    rules={{ required: { message: t('error.required'), value: true } }}
                    render={({
                      field: { onChange, onBlur, value, name, ref },
                      fieldState: { error },
                    }) => (
                      <PRInput
                        ref={ref}
                        label={t('form.orderFrom')}
                        name={name}
                        value={value}
                        error={error?.message}
                        type="number"
                        prefix={
                          <span className={cl['create__price-from']}>{t('common.from')}</span>
                        }
                        required
                        onChange={(val) => onChange(val === '0' ? '' : val)}
                        onBlur={onBlur}
                      />
                    )}
                  />

                  <div className={cl['create__price-bottom']}>
                    {fields.length === 1 ? (
                      <PRButton
                        variant="text"
                        prefix={<PlusIcon className={cl['create__plus-icon']} />}
                        onClick={addPrice}
                      >
                        {t('action.addPrice')}
                      </PRButton>
                    ) : null}
                    {index === 1 ? (
                      <PRButton
                        variant="text"
                        colorType="danger"
                        prefix={<TrashIcon className={cl['create__plus-icon']} />}
                        onClick={() => removePrice(index)}
                      >
                        {t('action.removePrice')}
                      </PRButton>
                    ) : null}
                  </div>
                </div>
              </div>
            ))}
        </div>
      </div>

      <div className={`${cl['create__card']} ${cl['sidebar']}`}>
        {createdAt ? (
          <>
            <PRTitle tag="h4">{t('common.createAndUpdate')}</PRTitle>
            <div className={cl['sidebar__dates']}>
              <p>
                <span>{t('common.created')}:</span>
                <span>{createdAt}</span>
              </p>
              <p>
                <span>{t('common.updated')}:</span>
                <span>{updatedAt}</span>
              </p>
            </div>
          </>
        ) : null}

        <ProductImageForm images={images} setImages={setImages} />
      </div>

      <form className={`${cl['create__card']} ${cl['searching']}`} onSubmit={onSearchSubmit}>
        <PRTitle tag="h2">{t('common.searchTerms')}</PRTitle>
        <p className={cl['searching__lead']}>{t('message.usingForSEO')}</p>
        <PRInput
          name="searchTerms"
          value={search}
          onChange={setSearch}
          label={t('form.keyPhrases')}
        />

        <div className={cl['searching__result']}>
          {searchTerms.map((str, index) => (
            <div key={str} className={cl['searching__item']}>
              <span>{str}</span>
              <button type="button" onClick={() => removeSearchTermAtPosition(index)}>
                <CloseIcon />
              </button>
            </div>
          ))}
        </div>
      </form>

      <div className={cl['create__footer']}>
        <PRButton loading={isSubmitting} type="button" onClick={handleSubmit(onSubmit)}>
          {t('action.saveChanges')}
        </PRButton>
        {/*<PRButton variant="outline" type="button" onClick={saveAsDraft}>*/}
        {/*  {t('action.leaveAsDraft')}*/}
        {/*</PRButton>*/}
      </div>
    </div>
  );
};

export default CreateProduct;
