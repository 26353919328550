import action from './action';
import common from './common';
import error from './error';
import form from './form';
import message from './message';

export default {
  action,
  common,
  form,
  message,
  error,
};
