import React, { useEffect, useState } from 'react';
import { useFieldArray, useForm } from 'react-hook-form';
import { useParams } from 'react-router-dom';
import { fetchProductById } from 'api/repositories/products.repository';
import { Document } from 'entities/Document';

import { CreateProductForm } from '../dto/create-product.dto';

export const useCreateProductForm = () => {
  const { id } = useParams();
  const [images, setImages] = useState<(Document | null)[]>([null, null, null, null]);

  const [search, setSearch] = useState('');
  const [searchTerms, setSearchTerms] = useState<string[]>([]);

  const {
    control,
    formState: { isSubmitting },
    handleSubmit,
    watch,
    getValues,
    setValue,
  } = useForm<CreateProductForm>({
    defaultValues: {
      title: '',
      vendorCode: '',
      barcode: '',
      description: '',
      area: null,
      category: null,
      subcategory: null,
      width: '',
      height: '',
      length: '',
      weight: '',
      color: null,
      material: null,

      currency: null,
      unitType: null,
      prices: [
        {
          price: '',
          quantityFrom: '',
        },
      ],
      createdAt: '',
      updatedAt: '',
    },
  });

  useEffect(() => {
    if (id) {
      fetchProductById(+id).then((res) => {
        setValue('title', res.title);
        setValue('vendorCode', res.vendorCode);
        setValue('barcode', res.barcode);
        setValue('description', res.description);
        setValue('area', res.workingArea);
        setValue('category', res.category);
        setValue('subcategory', res.subcategory);
        setValue('color', res.color);
        setValue('material', res.material);
        setValue('currency', res.currency);
        setValue('unitType', res.unitType);
        setValue('prices', res.prices);
        setValue('width', res.width ? res.width.toString() : '');
        setValue('height', res.height ? res.height.toString() : '');
        setValue('length', res.length ? res.length.toString() : '');
        setValue('weight', res.weight ? res.weight.toString() : '');

        setSearchTerms(res.searchTerms);
        setImages((val) => val.map((img, index) => res.images[index] || null));
        setValue('createdAt', res.createdAt.toLocaleDateString());
        setValue('updatedAt', res.updatedAt.toLocaleDateString());
      });
    }
  }, [id]);

  const { fields, append, remove } = useFieldArray({
    control,
    name: 'prices',
  });

  const addPrice = () => {
    append({
      price: '',
      quantityFrom: '',
    });
  };

  const removePrice = (position: number) => {
    remove(position);
  };

  const onSearchSubmit = (e: React.FormEvent) => {
    e.preventDefault();

    if (search.length) {
      setSearchTerms((value) => Array.from(new Set([...value, search])));
      setSearch('');
    }
  };

  const removeSearchTermAtPosition = (position: number) => {
    const newSearch = [...searchTerms];
    newSearch.splice(position, 1);
    setSearchTerms(newSearch);
  };

  return {
    productId: id,
    control,
    isSubmitting,
    handleSubmit,
    fields,
    getValues,
    setValue,
    watch,

    images,
    search,
    searchTerms,
    setImages,
    setSearch,
    addPrice,
    removePrice,
    onSearchSubmit,
    removeSearchTermAtPosition,
  };
};
