import React, { useState } from 'react';
import BaseModal from 'components/BaseModal';
import PRStepper from 'components/PRStepper';
import { usePartialState } from 'hooks/partial-state';

import { useNotification } from '../../../../hooks/notification';
import { BecomePartnerForm } from '../../dto/partner-form.dto';
import BecomePartnerCooperation from '../BecomePartnerCooperation';
import BecomePartnerFirst from '../BecomePartnerFirst';
import BecomePartnerSecond from '../BecomePartnerSecond';
import BecomePartnerThird from '../BecomePartnerThird';

import cl from './become-partner.module.scss';

interface Props {
  isVisible: boolean;
  changeVisible: (val: boolean) => void;
}

const formInit = (): BecomePartnerForm => ({
  cooperation: null,

  fullName: '',
  companyName: '',
  workingArea: null,
  email: '',
  website: '',
  phone: '',
  isAgree: false,

  purpose: null,
  benefits: '',
  offer: '',
  files: [],
});

const BecomePartnerModal: React.FC<Props> = ({ isVisible = false, changeVisible }) => {
  const notify = useNotification();
  const [form, setForm] = usePartialState<BecomePartnerForm>(formInit());
  const [step, setStep] = useState(0);
  const steps = ['Данные компании', 'Детали сотрудничества', 'Проверка заявки'];

  const onNext = () => {
    setStep((val) => val + 1);
  };

  const setPartialForm = (data: Partial<BecomePartnerForm>) => {
    setForm(data);
    onNext();
  };

  const onClose = () => {
    setStep(0);
    setForm(formInit());
    changeVisible(false);
  };

  const onEdit = () => {
    setStep(1);
  };

  const onSubmit = () => {
    console.log('send form', form);
    notify.success(`Мы отправим уведомление с ответом на почту ${form.email}`);
    onClose();
  };

  if (!isVisible) {
    return null;
  }

  return (
    <BaseModal title="Заявка на сотрудничество" onClose={onClose}>
      <div className={cl['become-partner']}>
        {step !== 0 ? <PRStepper step={step} labels={steps} /> : null}

        {step === 0 ? (
          <BecomePartnerCooperation
            cooperation={form.cooperation}
            onSelect={(val: string) => setForm({ cooperation: val })}
            onNext={onNext}
          />
        ) : null}

        {step === 1 ? <BecomePartnerFirst form={form} onSubmit={setPartialForm} /> : null}

        {step === 2 ? <BecomePartnerSecond form={form} onSubmit={setPartialForm} /> : null}

        {step === 3 ? <BecomePartnerThird form={form} onSubmit={onSubmit} onEdit={onEdit} /> : null}
      </div>
    </BaseModal>
  );
};

export default BecomePartnerModal;
