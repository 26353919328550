import React from 'react';
import { Navigate, Outlet, useLocation } from 'react-router-dom';
import { useAppSelector } from 'hooks/redux';

const Company = () => {
  const location = useLocation();
  const { isLoading, user } = useAppSelector((state) => state.auth);

  if (isLoading) {
    return <span>Loading...</span>;
  }

  if (!user) {
    return <Navigate to="/auth/login" state={{ from: location.pathname }} />;
  }

  if (location.pathname === '/company') {
    return <Navigate to="/company/catalog" />;
  }

  return <Outlet />;
};

export default Company;
