import { Faq } from 'entities/Faq';
import { CityOption, CountryOption, Option, TradeOption } from 'entities/Option';

import request from '../axios-instance';
import { mapFaqDaoToEntity } from '../mappers/faq.mappers';
import {
  mapCityOptionToEntity,
  mapCountryOptionToEntity,
  mapOptionDaoToEntity,
  mapTradeOptionToEntity,
} from '../mappers/option.mappers';

type OptionParams = {
  name?: string;
  page?: number;
  page_size?: number;
};

export const fetchWorkingAreas = (params?: OptionParams) =>
  request.get('industries', { params }).then((res) => {
    const areas: Option[] = res.data.content.map(mapOptionDaoToEntity);
    return areas;
  });

export const fetchWorkingAreaById = (id: number) =>
  request.get(`industries/${id}`).then((res) => {
    return mapOptionDaoToEntity(res.data);
  });

export const fetchPositions = (params?: OptionParams) =>
  request.get('positions', { params }).then((res) => {
    const positions: Option[] = res.data.content.map(mapOptionDaoToEntity);
    return positions;
  });

export const fetchPositionById = (id: number) =>
  request.get(`positions/${id}`).then((res) => mapOptionDaoToEntity(res.data));

export const fetchRegions = () =>
  request.get('handbook/regions').then((res) => {
    const regions: Option[] = res.data.list.map(mapOptionDaoToEntity);
    return regions;
  });

export const fetchCategories = (params?: OptionParams & { industry_id?: number }) =>
  request.get('categories', { params }).then((res) => {
    const categories: Option[] = res.data.content.map(mapOptionDaoToEntity);
    return categories;
  });

export const fetchCategoryById = (id: number) =>
  request.get(`categories/${id}`).then((res) => mapOptionDaoToEntity(res.data));

export const fetchSubcategories = (params?: OptionParams & { category_id?: number }) =>
  request.get('subcategories', { params }).then((res) => {
    const subcategories: Option[] = res.data.content.map(mapOptionDaoToEntity);
    return subcategories;
  });

export const fetchSubcategoryById = (id: number) =>
  request.get(`subcategories/${id}`).then((res) => mapOptionDaoToEntity(res.data));

export const fetchCooperationPurposes = () =>
  request.get('handbook/cooperation-purposes').then((res) => {
    const purposes: Option[] = res.data.list.map(mapOptionDaoToEntity);
    return purposes;
  });

export const fetchUnitTypes = (params?: OptionParams) =>
  request.get('units', { params }).then((res) => {
    const unitTypes: Option[] = res.data.content.map(mapOptionDaoToEntity);
    return unitTypes;
  });

export const fetchUnitById = (id: number) =>
  request.get(`units/${id}`).then((res) => mapOptionDaoToEntity(res.data));

export const fetchCurrencies = (params?: OptionParams) =>
  request.get('currencies', { params }).then((res) => {
    const currencies: Option[] = res.data.content.map(mapOptionDaoToEntity);
    return currencies;
  });

export const fetchCurrencyById = (id: number) =>
  request.get(`currencies/${id}`).then((res) => mapOptionDaoToEntity(res.data));

export const fetchColors = (params?: OptionParams) =>
  request.get('colors', { params }).then((res) => {
    const colors: Option[] = res.data.content.map(mapOptionDaoToEntity);
    return colors;
  });

export const fetchColorById = (id: number) =>
  request.get(`colors/${id}`).then((res) => mapOptionDaoToEntity(res.data));

export const fetchMaterials = (params?: OptionParams) =>
  request.get('materials', { params }).then((res) => {
    const materials: Option[] = res.data.content.map(mapOptionDaoToEntity);
    return materials;
  });

export const fetchMaterialById = (id: number) =>
  request.get(`materials/${id}`).then((res) => mapOptionDaoToEntity(res.data));

export const fetchCountries = (params?: OptionParams) =>
  request.get('countries', { params }).then((res) => {
    const countries: CountryOption[] = res.data.content.map(mapCountryOptionToEntity);
    return countries;
  });

export const fetchCountryById = (id: number) =>
  request.get(`countries/${id}`).then((res) => mapCountryOptionToEntity(res.data));

export const fetchCities = (params?: OptionParams) =>
  request.get('cities', { params }).then((res) => {
    const cities: CityOption[] = res.data.content.map(mapCityOptionToEntity);
    return cities;
  });

export const fetchCityById = (id: number) =>
  request.get(`cities/${id}`).then((res) => mapCountryOptionToEntity(res.data));

export const fetchFaq = () =>
  request.get('handbook/faq').then((res) => {
    const questions: Faq[] = res.data.questions.map(mapFaqDaoToEntity);
    return questions;
  });

export const fetchBranchTypes = (params?: OptionParams) =>
  request.get('branch-types', { params }).then((res) => {
    const cities: TradeOption[] = res.data.content.map(mapTradeOptionToEntity);
    return cities;
  });
