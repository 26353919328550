import React, { useMemo } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { ReactComponent as CloseEyeIcon } from 'assets/icons/close-eye.svg';
import { ReactComponent as OpenEyeIcon } from 'assets/icons/open-eye.svg';
import PRButton from 'components/PRButton';
import PRInput from 'components/PRInput';
import useHandleError from 'hooks/handleError';
import { useNotification } from 'hooks/notification';
import { usePartialState } from 'hooks/partial-state';
import { resetPassword } from 'api/repositories/users.repository';
import { ErrorResponse } from 'entities/Error';

import cl from './third.module.scss';

interface Props {
  email: string;
  code: string;
}

const ResetThirdStep: React.FC<Props> = (props) => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const notify = useNotification();
  const { handleCommonError } = useHandleError();

  const [visible, setVisible] = usePartialState({ password: false, confirm: false });
  const {
    control,
    formState: { isSubmitting },
    watch,
    handleSubmit,
  } = useForm({
    defaultValues: {
      password: '',
      confirmPassword: '',
    },
  });

  const passwordSuffix = useMemo(() => {
    if (visible.password) {
      return (
        <CloseEyeIcon
          className={cl['third__eye']}
          onClick={() => setVisible({ password: false })}
        />
      );
    }
    return (
      <OpenEyeIcon className={cl['third__eye']} onClick={() => setVisible({ password: true })} />
    );
  }, [visible.password]);

  const confirmSuffix = useMemo(() => {
    if (visible.confirm) {
      return (
        <CloseEyeIcon className={cl['third__eye']} onClick={() => setVisible({ confirm: false })} />
      );
    }
    return (
      <OpenEyeIcon className={cl['third__eye']} onClick={() => setVisible({ confirm: true })} />
    );
  }, [visible.confirm]);

  const onSubmit = async ({ password }: { password: string }) => {
    try {
      await resetPassword({
        ...props,
        password,
      });
      notify.success('Пароль удачно изменен!');
      navigate('/auth/login');
    } catch (err: unknown) {
      const error = err as ErrorResponse;
      handleCommonError(error);
    }
  };

  return (
    <form className={cl['third']} onSubmit={handleSubmit(onSubmit)}>
      <p className={cl['third__text']}>{t('message.createStrongPassword')}</p>

      <div className={cl['third__inputs']}>
        <Controller
          control={control}
          name="password"
          rules={{
            required: { message: t('error.required'), value: true },
            minLength: { message: t('error.minLength', { len: 8 }), value: 8 },
          }}
          render={({ field: { onChange, onBlur, value, name, ref }, fieldState: { error } }) => (
            <PRInput
              label={t('form.password')}
              type={visible.password ? 'text' : 'password'}
              ref={ref}
              name={name}
              value={value}
              suffix={passwordSuffix}
              onChange={onChange}
              onBlur={onBlur}
              error={error?.message}
              helperText={t('message.passwordHelper')}
            />
          )}
        />
        <Controller
          control={control}
          name="confirmPassword"
          rules={{
            required: { message: t('error.required'), value: true },
            validate: (value: string) => {
              if (watch('password') !== value) {
                return t('error.passwordsNotMatch');
              }
            },
          }}
          render={({ field: { onChange, onBlur, value, name, ref }, fieldState: { error } }) => (
            <PRInput
              label={t('form.confirmPassword')}
              type={visible.confirm ? 'text' : 'password'}
              ref={ref}
              name={name}
              value={value}
              suffix={confirmSuffix}
              onChange={onChange}
              onBlur={onBlur}
              error={error?.message}
            />
          )}
        />
      </div>

      <div className={cl['third__button']}>
        <PRButton loading={isSubmitting} type="submit">
          {t('action.changePassword')}
        </PRButton>
      </div>
    </form>
  );
};

export default ResetThirdStep;
