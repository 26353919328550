import React, { useEffect, useState } from 'react';
import { NavLink } from 'react-router-dom';
import { ReactComponent as VerifiedIcon } from 'assets/icons/verified.svg';
import PRPagination from 'components/PRPagination';
import PRTitle from 'components/PRTitle';
import { fetchManufacturers } from 'api/repositories/manufacturer.repository';
import { Manufacturer as ManufacturerEntity } from 'entities/Manufacturer';

import { FilterParamsDto } from '../../dto/filter-params.dto';

import cl from './manufacturer.module.scss';

interface Props {
  params: FilterParamsDto;
  onPageChange: (page: number) => void;
}

const Manufacturer: React.FC<Props> = ({ params, onPageChange }) => {
  const [lastPage, setLastPage] = useState(1);
  const [manufacturers, setManufacturers] = useState<ManufacturerEntity[]>([]);

  useEffect(() => {
    fetchManufacturers({
      countryId: params.region,
      industryId: params.area,
      name: params.name,
      page: params.page,
      page_size: 20,
    }).then((res) => {
      setManufacturers(res.manufacturers);
      setLastPage(res.meta.lastPage);
    });
  }, [params]);

  return (
    <div className={cl.manufacturer}>
      <div className={cl['manufacturer__list']}>
        {manufacturers.map((manufacturer) => (
          <NavLink
            to={`/manufacturers/${manufacturer.id}`}
            key={manufacturer.id}
            className={cl['manufacturer__item']}
          >
            <div className={cl['manufacturer__logo']}>
              {manufacturer.logo ? (
                <img src={manufacturer.logo} alt={manufacturer.title} />
              ) : (
                <img src={require('assets/images/empty.png')} alt="" />
              )}
            </div>
            <div className={cl['manufacturer__body']}>
              <div className={cl['manufacturer__header']}>
                <div className={cl['manufacturer__title']}>
                  <PRTitle tag="h3">{manufacturer.title}</PRTitle>
                  {manufacturer.verified ? (
                    <VerifiedIcon className={cl['manufacturer__verified']} />
                  ) : null}
                </div>

                <p className={cl['manufacturer__address']}>
                  {manufacturer.country.title}, {manufacturer.address}
                </p>
              </div>
              <p className={cl['manufacturer__description']}>{manufacturer.description}</p>
            </div>
          </NavLink>
        ))}
      </div>

      <PRPagination current={params.page} lastPage={lastPage} onChange={onPageChange} />
    </div>
  );
};

export default Manufacturer;
