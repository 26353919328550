import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { CountryOption, Option } from 'entities/Option';

export interface StartStep {
  type: 'user' | 'manufacturer';
}

export interface FirstStep {
  companyName: string;
  workingArea: Option | null;
  country: CountryOption | null;
  legalAddress: string;
  bin: string;
  email: string;
  phone: string;
  isAgree: boolean;
}

export interface SecondStep {
  firstName: string;
  lastName: string;
  country: Option | null;
  email: string;
  phone: string;
  isAgree: boolean;
}

export interface ThirdStep {
  password: string;
  confirmPassword: string;
}

interface RegistrationState {
  start: StartStep;
  first: FirstStep;
  second: SecondStep;
  third: ThirdStep;
  step: number;
}

const getInitState = (): RegistrationState => ({
  start: {
    type: 'manufacturer',
  },
  first: {
    companyName: '',
    workingArea: null,
    country: null,
    legalAddress: '',
    bin: '',
    email: '',
    phone: '',
    isAgree: false,
  },
  second: {
    firstName: '',
    lastName: '',
    country: null,
    email: '',
    phone: '',
    isAgree: false,
  },
  third: {
    password: '',
    confirmPassword: '',
  },
  step: 0,
});

const initialState: RegistrationState = getInitState();

export const registrationSlice = createSlice({
  name: 'registration',
  initialState,
  reducers: {
    setStartStep: (state, action: PayloadAction<StartStep>) => {
      state.start = action.payload;
      state.step = 1;
    },
    setFirstStep: (state, action: PayloadAction<FirstStep>) => {
      state.first = action.payload;
      state.step = 2;
    },
    setSecondStep: (state, action: PayloadAction<SecondStep>) => {
      state.second = action.payload;
      state.step = 2;
    },
    setThirdStep: (state, action: PayloadAction<ThirdStep>) => {
      state.third = action.payload;
      state.step = 3;
    },
    resetState: (state) => {
      const newState = getInitState();
      state.first = newState.first;
      state.second = newState.second;
      state.step = 0;
    },
  },
});

export const registrationReducer = registrationSlice.reducer;
