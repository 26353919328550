export default {
  main: 'Головна',
  loading: 'Завантаження...',
  listEmpty: 'Список порожній',
  attention: 'Увага',
  services: 'Послуги',
  applications: 'Заявки',
  partnership: 'Партнерство',
  branches: 'Філії',
  catalog: 'Каталог',
  faq: 'Питання і відповіді',
  companyData: 'Дані компанії',
  employeeData: 'Дані представника',
  accountActivation: 'Активація облікового запису',
  aboutPlatform: 'Про платформу',
  manageCompany: 'Управління компанією',
  tradingAgency: 'Торгове представництво',
  saleOfPoint: 'Точка продажу',
  tradingHouse: 'Торговий дім',
  manufacturerName: 'Назва виробника',
  productName: 'Назва товару',
  priceFrom: 'Ціна від {{from}}',
  aboutManufacturer: 'Про виробника',
  contacts: 'Контакти',
  manufacturers: 'Виробники',
  summary: 'Звіт',
  certificates: 'Сертифікати',
  yearsAgo: '{{num}} років тому',
  title: 'Назва',
  brandName: 'Назва бренду',
  legalAddress: 'Юридична адреса',
  jurisdiction: 'Юрисдикція',
  regNumberBin: 'Реєстраційний номер',
  registrationDate: 'Дата реєстрації',
  sinceOnSite: 'На сайті з',
  product: 'Товар',
  vendorCode: 'Артикул',
  date: 'Дата',
  display: 'Відображення',
  inStock: 'В наявності',
  actions: 'Дії',
  required: "Обов'язкові",
  optional: 'Опціональні',
  productList: 'Список товарів',
  barcode: 'Штрих-код або UPC/EAN',
  color: 'Колір',
  material: 'Матеріал',
  currency: 'Валюта',
  unitType: 'Одиниця вимірювання',
  status: 'Статус',
  wholesalePrice: 'Оптова ціна',
  from: 'від',
  mainInfo: 'Основна інформація',
  productDescription: 'Опис товару',
  dimensions: 'Габаритні розміри',
  characteristics: 'Характеристики',
  price: 'Ціна',
  createAndUpdate: 'Створення і оновлення',
  created: 'Створено',
  updated: 'Оновлено',
  searchTerms: 'Пошукові запити',
  supportedFormats: 'Підтримувані формати: {{formats}}',
  maxSize: 'Максимальний розмір: {{size}}',
  images: 'Зображення',
  agency: 'Представництво',
  password: 'Пароль',
  addingBranch: 'Додавання філії',
  questionAndAnswer: 'Питання і відповіді',
  profile: 'Профіль',
  settings: 'Налаштування',
  requirementsForVerification: 'Вимоги для верифікації',
  submitFollowingDocuments: 'Надайте наступні типи документів',
  companyRegCertificate: 'Свідоцтво про реєстрацію компанії',
  companyCharter: 'Устав компанії',
  certificateTaxReg: 'Свідоцтво про податкову реєстрацію',
  productionDoc: 'Документація про виробництво',
  qualityCertificate: 'Свідоцтва про якість і відповідність',
  licensesAndCertificates: 'Ліцензії та сертифікати',
  suppliersAndCustomersContracts: 'Договори з постачальниками і клієнтами',
  accountDeleting: 'Видалення облікового запису',
  passwordChanging: 'Зміна пароля',
  today: 'сьогодні',
  weight: 'Вага',
  availableOnOrder: 'Товар доступний під замовлення',
  manufacturerOtherProducts: 'Інші товари виробника',
  lastActivity: 'Остання активність',
  passwordRecovery: 'Відновлення пароля',
  forgotPassword: 'Забули пароль?',
  haveNotAccount: 'Немає облікового запису?',
  createPassword: 'Створення пароля',
  registration: 'Реєстрація',
  haveAnAccount: 'Є обліковий запис?',
  iAmManufacturer: 'Я виробник',
  iAmUser: 'Я користувач',
  aboutCompany: 'Про компанію',
  logo: 'Логотип',
  personalData: 'Особисті дані',
  notifications: 'Сповіщення',
  todayAt: 'Сьогодні о {{date}}',
  yesterdayAt: 'Вчора о {{date}}',
  all: 'Все',
  system: 'Системні',
  notSpecified: 'не вказано',
};
