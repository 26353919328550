export default {
  signTo: 'Уваход у сістэму',
  signUp: 'Стварэнне асабістага кабінета',
  passwordHelper: 'Мінімум 8 сімвалаў, 1 вялікая літара, 1 лічба.',
  lastUpdate: 'Апошняе абнаўленне: {{date}}',
  tradingAgency:
    'Прадстаўнік, які бярэ на сябе абавязак арганізацыі дыстрыбуцыі ў канкрэтным рэгіёне па раней вызначаным правілах.',
  saleOfPoint: 'Тэрытарыяльнае прысутнасць тавару ў канкрэтнага продавца.',
  tradingHouse:
    'Упаўнамочаная кампанія ад вытворцы па прадстаўленні тавару і арганізацыі правілаў дыстрыбуцыі ў канкрэтным рэгіёне.',
  uploadPresentationOrFile: 'Пры неабходнасці загрузіце дадатковыя дакументы, прэзентацыю або файл',
  usingForSEO:
    'Выкарыстоўваюцца для SEO і дапамагаюць павысіць бачнасць тавару ў пошукавых сістэмах',
  noBranchesYet: 'У вас яшчэ няма дададзеных філіялаў',
  branchBenefits:
    'Спіс філіялаў дазволіць павысіць давер і палепшыць бачнасць на сайце, а таксама прадастаўляе іншым кампаніям магчымасць бачыць і кантактаваць з філіяламі.',
  activationCodeSend:
    'На вашу пошту адпраўлены код, для завяршэння рэгістрацыі ўвядзіце код у поле',
  resendCodeAfter: 'Адпраўце код паўторна праз {{seconds}} секунд',
  codeSent: 'Код адпраўлены',
  allDataWillBeDeleted: 'Усе вашыя дадзеныя будуць выдаленыя разам з уліковай запісам',
  changePasswordImproveAccount:
    'Абнаўленне коду доступу да ўліковай запісы з мэтай павышэння бяспекі акаўнта',
  writeEmailForRecovery:
    'Калі ласка, увядзіце электронную пошту, якая прывязана да вашага акаўнта.',
  codeForRecovery: 'Код для скідання пароля быў адпраўлены на вашу пошту',
  enterCodeToAccessAccount: 'Увядзіце код ніжэй для аднаўлення доступу да акаўнта',
  createStrongPassword: 'Падумайце надзейны пароль для вашага акаўнта',
  infoAboutYourCompany: 'Пакажыце інфармацыю аб вашай кампаніі',
  iWantCatalogAndMakeSale: 'Я хачу размяшчаць свой каталог і прадаваць тавары',
  iWantSearchManufacturers: 'Я шукаю вытворцу для партнёрства',
  tradingAgencyDescription:
    'Тарговыя прадстаўніцтвы: гэта арганізацыі, якія прадстаўляюць інтарэсы і прадукцыю іншых кампаній на вызначанай тэрыторыі. Яны могуць выконваць пошук кліентаў, прамаўку тавараў, правядзенне перагавораў і заключэнне угодаў ад імя прадстаўлямай кампаніі. Прыклады тарговых прадстаўніцтваў: агенцтвы па прадажы нерухомасці, агенцтвы па прадажы аўтамабіляў і г. д.',
  tradingHouseDescription:
    "Тарговыя дамы: гэта вялікія тарговыя арганізацыі, якія аб'ядноўваюць некалькі тарговых тачак або магазінаў пад аднай кіравальнай кампаніяй. Тарговы дом можа ўключаць у сябе розныя аддзяленні і спецыялізаваныя секцыі, якія прапаноўваюць шырокі асартымент тавараў або паслуг. Прыклады тарговых дамоў: універмагі, тарговыя цэнтры і г.д.",
  tradingPointDescription:
    'Тарговыя кропкі: гэта месцы, дзе тавары або паслугі прадаюцца прама спажыўцам. Гэта могуць быць магазіны, супермаркеты, рынкі, тарговыя павільёны і г. д. Тарговыя кропкі звычайна маюць фізічнае прастора, дзе кліенты могуць набыць тавары або атрымаць паслугі.',
};
