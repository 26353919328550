export default {
  notConnection: 'Проверьте соединение с интернетом',
  unknown: 'Что то пошло не так',
  server: 'Ошибка сервера. Нужно обратиться к тех. поддержке.',
  wrongAuth: 'Не верный email или пароль',
  required: 'Обязательное поле для заполнения',
  invalidEmail: 'Некорректный email',
  minLength: 'Должно быть длинее {{len}} символов',
  maxLength: 'Должно быть не длинее {{len}} символов',
  passwordsNotMatch: 'Пароли не совподают',
  notCorrectPrice: 'Некорректная цена',
  notCorrectLink: 'Некорректная ссылка',
  forbidden: 'У вас недостаточно прав для данной действии',
  phone: 'Некорректный номер телефона',
  incorrectFormat: 'Файл должен быть в формате {{format}}',
  selectRequiredColumns: 'Не выбраны обязательные столбцы',
  readFile: 'Ошибка при чтении файла',
  emailNotAvailable: 'Email уже зарегистрирован в системе',
};
