import React, { useMemo } from 'react';
import { ReactComponent as SpinnerIcon } from 'assets/icons/spinner.svg';

import cl from './button.module.scss';

export type ButtonType = 'button' | 'submit' | 'reset';
export type ButtonColorType = 'main' | 'danger';
export type ButtonVariant = 'solid' | 'outline' | 'tertiary' | 'text';

type ButtonProps = {
  children: React.ReactNode;
  type?: ButtonType;
  onClick?: () => void;
  variant?: ButtonVariant;
  colorType?: ButtonColorType;
  disabled?: boolean;
  loading?: boolean;
  suffix?: React.ReactNode;
  prefix?: React.ReactNode;
  styles?: Record<string, string>;
};

const PRButton: React.FC<ButtonProps> = ({
  type = 'button',
  onClick = () => {},
  variant = 'solid',
  colorType = 'main',
  disabled = false,
  loading = false,
  children,
  suffix,
  prefix,
  styles,
}) => {
  const btnClass = useMemo(
    () =>
      `
        ${cl.btn} 
        ${disabled ? cl[`btn__disable`] : cl[`btn__${colorType}`]} 
        ${disabled ? cl[`btn__disable--${variant}`] : cl[`btn__${colorType}--${variant}`]} 
        ${loading ? cl[`btn--loading`] : ''}
      `,
    [variant, colorType, loading, disabled]
  );
  return (
    <button
      className={btnClass}
      style={styles}
      type={type}
      disabled={disabled || loading}
      onClick={onClick}
    >
      {loading ? (
        <span className={cl.loader}>
          <SpinnerIcon />
        </span>
      ) : null}
      {prefix ? prefix : null}
      {children}
      {suffix ? suffix : null}
    </button>
  );
};

export default PRButton;
