import React from 'react';
import { useTranslation } from 'react-i18next';
import PRButton from 'components/PRButton';
import PRTitle from 'components/PRTitle';
import PRToggle from 'components/PRToggle';
import { authSlice } from 'store/auth';
import { useConfirmBox } from 'hooks/confirm-box';
import useHandleError from 'hooks/handleError';
import { usePartialState } from 'hooks/partial-state';
import { useAppDispatch, useAppSelector } from 'hooks/redux';
import { deleteAccount } from 'api/repositories/users.repository';
import { ErrorResponse } from 'entities/Error';
import { Organization } from 'entities/User';

import cl from './settings.module.scss';

const OrganizationSettings = () => {
  const { t } = useTranslation();
  const { showConfirmBox } = useConfirmBox();
  const dispatch = useAppDispatch();
  const user = useAppSelector((state) => state.auth.user) as Organization;
  const { handleCommonError } = useHandleError();

  const [notifications, setNotifications] = usePartialState({
    privateMessages: true,
    partnership: false,
    productOrder: false,
    updates: true,
  });

  const onDeleteAccount = () => {
    showConfirmBox({
      message: 'Подтвердите удаление аккаунта',
      okText: t('action.delete'),
      okVariant: 'danger',
      cancelText: t('action.cancel'),
      onAccept: () => {
        deleteAccount(user.id)
          .then(() => {
            dispatch(authSlice.actions.logout());
          })
          .catch((err: unknown) => {
            const error = err as ErrorResponse;
            handleCommonError(error);
          });
      },
    });
  };

  return (
    <div className={cl['settings']}>
      <div className={`${cl['card']} ${cl['card--notifications']}`}>
        <PRTitle tag="h3">{t('common.notifications')}</PRTitle>
        <div className={cl['notification']}>
          <div className={cl['notification__text']}>
            <p>Личные сообщения</p>
            <p>Будьте в курсе новых личных сообщений от других пользователей.</p>
          </div>
          <PRToggle
            value={notifications.privateMessages}
            onChange={(val) => setNotifications({ privateMessages: val })}
          />
        </div>
        <div className={cl['notification']}>
          <div className={cl['notification__text']}>
            <p>Заявки на партнёрство</p>
            <p>Новые заявки на сотрудничество от желающих стать партнерами.</p>
          </div>
          <PRToggle
            value={notifications.partnership}
            onChange={(val) => setNotifications({ partnership: val })}
          />
        </div>
        <div className={cl['notification']}>
          <div className={cl['notification__text']}>
            <p>Заявки на товары</p>
            <p>Новые заявки на товары с отметкой &quot;Доступно под заказ&quot;.</p>
          </div>
          <PRToggle
            value={notifications.productOrder}
            onChange={(val) => setNotifications({ productOrder: val })}
          />
        </div>
        <div className={cl['notification']}>
          <div className={cl['notification__text']}>
            <p>Обновления</p>
            <p>Получайте уведомления о последних обновлениях и улучшениях на сайте.</p>
          </div>
          <PRToggle
            value={notifications.updates}
            onChange={(val) => setNotifications({ updates: val })}
          />
        </div>
      </div>
      <div className={cl['card']}>
        <PRTitle tag="h3">{t('common.accountDeleting')}</PRTitle>
        <p className={cl['card__text']}>{t('message.allDataWillBeDeleted')}</p>
        <PRButton variant="text" colorType="danger" onClick={onDeleteAccount}>
          {t('action.deleteAccount')}
        </PRButton>
      </div>

      <div className={cl['card']}>
        <PRTitle tag="h3">{t('common.passwordChanging')}</PRTitle>
        <p className={cl['card__text']}>{t('message.changePasswordImproveAccount')}</p>
        <PRButton variant="text">{t('action.changePassword')}</PRButton>
      </div>
    </div>
  );
};

export default OrganizationSettings;
