import React, { useEffect, useState } from 'react';
import { ReactComponent as ArrowRight } from 'assets/icons/chevron-right.svg';
import { ReactComponent as HomeIcon } from 'assets/icons/home-full.svg';
import { ReactComponent as PinIcon } from 'assets/icons/pin.svg';
import { ReactComponent as SortIcon } from 'assets/icons/sort.svg';
import { ReactComponent as UserIcon } from 'assets/icons/user.svg';
import PRPagination from 'components/PRPagination';

import cl from './partnership.module.scss';

const PartnershipRequests = () => {
  const [sortBy, setSortBy] = useState<string | null>(null);
  const [lastPage] = useState(5);

  useEffect(() => {
    console.log('refetch list', sortBy);
  }, [sortBy]);

  const list = [
    {
      id: 1,
      title: 'Tech Solutions',
      applicant: 'Каскынбеков Дархан Самарканович ',
      type: 'Представительство',
      icon: 'user',
      new: true,
      date: '27 декабря',
    },
    {
      id: 2,
      title: 'Tech Solutions',
      applicant: 'Каскынбеков Дархан Самарканович ',
      type: 'Торговый дом',
      icon: 'home',
      new: false,
      date: '27 декабря',
    },
    {
      id: 3,
      title: 'Tech Solutions',
      applicant: 'Каскынбеков Дархан Самарканович ',
      type: 'Точка продажи',
      icon: 'pin',
      new: false,
      date: '03.07.2022',
    },
  ];

  return (
    <div className={cl['partnership']}>
      <div className={cl['table']}>
        <div className={cl['table__header']}>
          <span className={`${cl['table__cell']} ${cl['table__cell--number']}`}>№</span>
          <span className={cl['table__cell']}>Компания</span>
          <span className={cl['table__cell']}>Заявитель</span>
          <span
            className={`${cl['table__cell']} ${cl['table__cell--sort']}`}
            onClick={() => setSortBy('partnership')}
          >
            Тип партнёрства <SortIcon />
          </span>
          <span
            className={`${cl['table__cell']} ${cl['table__cell--sort']} ${cl['table__cell--date']}`}
            onClick={() => setSortBy('createdAt')}
          >
            Дата <SortIcon />
          </span>
          <span className={cl['table__cell']}>Действие</span>
        </div>

        {list.map((request, index) => (
          <div key={request.id} className={cl['table__row']}>
            <span className={`${cl['table__cell']} ${cl['table__cell--number']}`}>{index + 1}</span>
            <span className={cl['table__cell']}>
              {request.title} {request.new ? <span className={cl['table__new-item']} /> : ''}
            </span>
            <span className={cl['table__cell']}>{request.applicant}</span>
            <span className={`${cl['table__cell']} ${cl['table__cell--type']}`}>
              {request.icon === 'user' ? (
                <UserIcon />
              ) : request.icon === 'home' ? (
                <HomeIcon />
              ) : (
                <PinIcon />
              )}
              {request.type}
            </span>
            <span className={`${cl['table__cell']} ${cl['table__cell--date']}`}>
              {request.date}
            </span>
            <div className={cl['table__cell']}>
              <button className={cl['table__more']}>
                Подробнее <ArrowRight />
              </button>
            </div>
          </div>
        ))}
      </div>

      {lastPage > 1 ? (
        <div className={cl['partnership__pagination']}>
          <PRPagination current={1} lastPage={lastPage} onChange={(page) => console.log(page)} />
        </div>
      ) : null}
    </div>
  );
};

export default PartnershipRequests;
