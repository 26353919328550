import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { ReactComponent as ArrowRight } from 'assets/icons/chevron-right.svg';
import PRButton from 'components/PRButton';
import PRPagination from 'components/PRPagination';
import PRProduct from 'components/PRProduct';
import PRTitle from 'components/PRTitle';
import { usePartialState } from 'hooks/partial-state';
import { fetchProducts } from 'api/repositories/products.repository';
import { Product } from 'entities/Product';

import cl from './other-products.module.scss';

interface Props {
  manufacturerId: number;
}

const OtherProducts: React.FC<Props> = ({ manufacturerId }) => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [products, setProducts] = useState<Product[]>([]);
  const [pagination, setPagination] = usePartialState({ page: 1, lastPage: 1 });

  useEffect(() => {
    fetchProducts(pagination.page, { manufacturer: { id: manufacturerId } }).then((res) => {
      setProducts(res.products);
      setPagination({ lastPage: res.meta.lastPage });
    });
  }, [manufacturerId]);
  const toggleBookmark = (id: number) => {
    const updatedProducts = products.map((p) => {
      if (p.id === id) {
        p.bookmarked = !p.bookmarked;
      }
      return p;
    });
    setProducts(updatedProducts);
  };

  const goToCatalog = () => {
    navigate(`/manufacturers/${manufacturerId}`);
  };

  return (
    <div className={cl['other']}>
      <div className={cl['other__header']}>
        <PRTitle tag="h2">{t('common.manufacturerOtherProducts')}</PRTitle>
        <PRButton variant="text" suffix={<ArrowRight />} onClick={goToCatalog}>
          {t('action.goToCatalog')}
        </PRButton>
      </div>

      <div className={cl['other__list']}>
        {products.map((product) => (
          <PRProduct key={product.id} product={product} toggleBookmark={toggleBookmark} />
        ))}
      </div>

      <PRPagination
        current={pagination.page}
        lastPage={pagination.lastPage}
        onChange={(page: number) => setPagination({ page })}
      />
    </div>
  );
};

export default OtherProducts;
