export default {
  notConnection: 'Интернет байланишини текширинг',
  unknown: 'Нимадир нотоъгри кетди',
  server: 'Сервер хатоси. Техник дастуркорга мурожаат қилинг.',
  wrongAuth: 'Электрон почта ёки парол нотўғри',
  required: 'Тўлдирилиши зарур майдон',
  invalidEmail: 'Нотўғри электрон почта',
  minLength: '{{len}} та белгидан узун бўлиши керак',
  maxLength: '{{len}} та белгидан қисми бўлиши керак',
  passwordsNotMatch: 'Пароллар мос келмайди',
  notCorrectPrice: 'Нотўғри нарх',
  notCorrectLink: 'Нотўғри ҳавола',
  forbidden: 'Ушбу амални бажариш учун сизга кифоят ҳуқуқлар берилмаган',
  phone: 'Нотўғри телефон рақами',
  incorrectFormat: 'Файл {{format}} форматида бўлиши керак',
  selectRequiredColumns: 'Зарурбўлган столбцалар танланмаган',
  readFile: 'Файлни ўқиб бўлмади',
  emailNotAvailable: 'Электрон почта аллақачон тизимда рўйхатдан ўтган',
};
