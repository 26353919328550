export default {
  change: 'Cambiar',
  delete: 'Eliminar',
  find: 'Encontrar',
  reset: 'Restablecer',
  save: 'Guardar',
  edit: 'Editar',
  cancel: 'Cancelar',
  accept: 'Aceptar',
  login: 'Iniciar sesión',
  logout: 'Cerrar sesión',
  signup: 'Crear cuenta',
  signin: 'Iniciar sesión',
  next: 'Siguiente',
  seekManufacturer: 'Busco un fabricante',
  seekProduct: 'Busco un producto',
  back: 'Atrás',
  forward: 'Adelante',
  becomeAPartner: 'Convertirse en socio',
  contactWithManufacturer: 'Contactar con el fabricante',
  uploadFile: 'Cargar archivo',
  sendRequest: 'Enviar solicitud',
  importProducts: 'Importar productos',
  addProduct: 'Agregar producto',
  continue: 'Continuar',
  addPrice: 'Agregar precio',
  removePrice: 'Eliminar precio',
  saveChanges: 'Guardar cambios',
  leaveAsDraft: 'Dejar como borrador',
  close: 'Cerrar',
  ready: 'Listo',
  addBranches: 'Agregar sucursales',
  resendActivationCode: 'Reenviar código de activación',
  sendForVerification: 'Enviar para verificación',
  deleteAccount: 'Eliminar cuenta',
  changePassword: 'Cambiar contraseña',
  hide: 'Ocultar',
  expand: 'Expandir',
  orderProduct: 'Ordenar producto',
  writeToManufacturer: 'Escribir al fabricante',
  goToCatalog: 'Ir al catálogo',
  confirm: 'Confirmar',
  yes: 'Sí',
  no: 'No',
  selectChat: 'Seleccionar chat',
  signIn: 'Iniciar sesión',
  recover: 'Recuperar',
  create: 'Crear',
  uploadNewPhoto: 'Cargar nueva foto',
  verifyCompany: 'Verificar la empresa',
};
