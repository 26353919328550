import React, { forwardRef, useMemo, useState } from 'react';
import { clearNonNumericSymbols } from 'utils/formatter.util';

import cl from './input.module.scss';

interface Props {
  name: string;
  label?: string;
  value: string;
  error?: string;
  placeholder?: string;
  type?: 'text' | 'tel' | 'number' | 'password';
  disabled?: boolean;
  required?: boolean;
  helperText?: string;
  maxLength?: number;
  onChange: (value: string) => void;
  onBlur?: () => void;
  prefix?: React.ReactNode;
  suffix?: React.ReactNode;
}

const PRInput = forwardRef<HTMLInputElement, Props>(
  (
    {
      name,
      label = '',
      value,
      error,
      type = 'text',
      placeholder = '',
      disabled = false,
      required = false,
      helperText = '',
      maxLength = 0,
      onChange,
      onBlur = () => {},
      prefix,
      suffix,
    },
    ref
  ) => {
    const [isFocused, setFocus] = useState(false);

    const onInputBlur = () => {
      setFocus(() => false);
      onBlur();
    };

    const onInputChange = (val: string) => {
      if (type === 'number') {
        val = clearNonNumericSymbols(val);
      }

      if (!!maxLength && val.length >= maxLength) {
        onChange(val.substring(0, maxLength));
      } else {
        onChange(val);
      }
    };

    const wrapper = useMemo(() => {
      let className = cl['pr-input__wrapper'];
      if (isFocused || value) {
        className += ` ${cl['pr-input__wrapper--focus']}`;
      }
      if (disabled) {
        className += ` ${cl['pr-input__wrapper--disable']}`;
      }
      if (error) {
        className += ` ${cl['pr-input__wrapper--error']}`;
      }

      return className;
    }, [isFocused, error, disabled, value]);

    return (
      <div className={`${cl['pr-input']} ${label ? cl['pr-input--space'] : ''}`}>
        <div className={wrapper}>
          {label ? (
            <p className={cl['pr-input__label']}>
              {label}
              {required ? <span>*</span> : null}
            </p>
          ) : null}

          <div className={cl['pr-input__body']}>
            {prefix ? prefix : null}
            <input
              ref={ref}
              id={name}
              type={type === 'number' ? 'text' : type}
              disabled={disabled}
              value={value}
              className={cl['pr-input__input']}
              placeholder={label ? '' : placeholder}
              onChange={(event) => onInputChange(event.target.value)}
              onBlur={onInputBlur}
              onFocus={() => setFocus(true)}
            />
            {suffix ? suffix : null}
          </div>
        </div>

        {!!error || helperText ? (
          <div className={cl['pr-input__helper']}>
            {!!error ? (
              <p className={cl['pr-input__error']}>{error}</p>
            ) : (
              <p className={cl['pr-input__text']}>{helperText}</p>
            )}
            {!!maxLength && helperText ? (
              <p className={cl['pr-input__text']}>{`${value.length}/${maxLength}`}</p>
            ) : null}
          </div>
        ) : null}
      </div>
    );
  }
);

export default PRInput;
