import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ReactComponent as ArrowLeft } from 'assets/icons/chevron-left.svg';
import { ReactComponent as ArrowRight } from 'assets/icons/chevron-right.svg';
// import { ReactComponent as DoubleArrowLeft } from 'assets/icons/double-arrow-left.svg';
// import { ReactComponent as DoubleArrowRight } from 'assets/icons/double-arrow-right.svg';
import { useWindowSize } from 'hooks/window-size';

import cl from './pagination.module.scss';

interface PaginationProps {
  lastPage?: number;
  current: number;
  onChange: (page: number) => void;
}

const getRange = (start: number, end: number) =>
  [...Array.from(Array(end - start).keys())].map((num) => num + start);

const getPagesWindow = ({ total = 1, cutPages = 3, current = 1 }) => {
  const ceiling = Math.ceil(cutPages / 2);
  const floor = Math.floor(cutPages / 2);

  if (total < cutPages) {
    return [1, total + 1];
  } else if (current >= 1 && current <= ceiling) {
    return [1, cutPages + 1];
  } else if (current + floor >= total) {
    return [total - cutPages + 1, total + 1];
  } else {
    return [current - ceiling + 1, current + floor + 1];
  }
};

const PRPagination: React.FC<PaginationProps> = ({ current, lastPage = 1, onChange }) => {
  const { t } = useTranslation();
  const windowSize = useWindowSize();
  const [cutPages, setCutPages] = useState(5);

  useEffect(() => {
    if (windowSize === 'phone') {
      setCutPages(3);
    } else if (windowSize === 'tablet') {
      setCutPages(5);
    } else {
      setCutPages(8);
    }
  }, [windowSize]);

  const prevClass = useMemo(
    () =>
      `
        ${cl['pagination__button']}
        ${cl['pagination__button--arrow']}
        ${current === 1 ? cl['pagination__button--disabled'] : ''}
      `,
    [current]
  );
  const nextClass = useMemo(
    () =>
      `
        ${cl['pagination__button']}
        ${cl['pagination__button--arrow']}
        ${current >= lastPage ? cl['pagination__button--disabled'] : ''}
      `,
    [current, lastPage]
  );

  const [start, end] = getPagesWindow({ total: lastPage, cutPages, current });
  const pages: number[] = getRange(start, end);

  if (lastPage <= 1) return <div></div>;
  return (
    <div className={cl.pagination}>
      {/*<button className={prevClass} onClick={() => onChange(1)}>*/}
      {/*  <DoubleArrowLeft />*/}
      {/*</button>*/}

      <button className={prevClass} onClick={() => onChange(current - 1)}>
        <ArrowLeft />
        <span>{t('action.back')}</span>
      </button>

      {pages.map((p) => (
        <button
          className={`${cl['pagination__button']} ${
            p === current ? cl['pagination__button--active'] : ''
          }`}
          key={p}
          onClick={() => onChange(p)}
        >
          {p}
        </button>
      ))}

      <button className={nextClass} onClick={() => onChange(current + 1)}>
        <span>{t('action.forward')}</span>
        <ArrowRight />
      </button>

      {/*<button className={nextClass} onClick={() => onChange(lastPage)}>*/}
      {/*  <DoubleArrowRight />*/}
      {/*</button>*/}
    </div>
  );
};

export default PRPagination;
