export default {
  change: 'Змяніць',
  delete: 'Выдаліць',
  find: 'Знайсці',
  reset: 'Скінуць',
  save: 'Захаваць',
  edit: 'Рэдагаваць',
  cancel: 'Адмяніць',
  accept: 'Прыняць',
  login: 'Уваход',
  logout: 'Выйсці',
  signup: 'Стварыць рахунак',
  signin: 'Аўтарызавацца',
  next: 'Далей',
  seekManufacturer: 'Шукаю вытворца',
  seekProduct: 'Шукаю тавар',
  back: 'Назад',
  forward: 'Уперад',
  becomeAPartner: 'Стаць партнёрам',
  contactWithManufacturer: 'Звярнуцца да вытворца',
  uploadFile: 'Загрузіць файл',
  sendRequest: 'Адправіць запыт',
  importProducts: 'Імпартаваць тавары',
  addProduct: 'Дадаць тавар',
  continue: 'Працягнуць',
  addPrice: 'Дадаць цану',
  removePrice: 'Выдаліць цану',
  saveChanges: 'Захаваць змены',
  leaveAsDraft: 'Пакінуць як скіц',
  close: 'Зачыніць',
  ready: 'Гатова',
  addBranches: 'Дадаць філіялы',
  resendActivationCode: 'Адправіць код актывацыі зноў',
  sendForVerification: 'Адправіць на праверку',
  deleteAccount: 'Выдаліць рахунак',
  changePassword: 'Змяніць пароль',
  hide: 'Схаваць',
  expand: 'Разгарнуць',
  orderProduct: 'Замоўце тавар',
  writeToManufacturer: 'Напісаць вытворцу',
  goToCatalog: 'Перайсці ў каталог',
  confirm: 'Пацвердзіць',
  yes: 'Так',
  no: 'Не',
  selectChat: 'Выберыце чат',
  signIn: 'Увайсці',
  recover: 'Аднавіць',
  create: 'Стварыць',
  uploadNewPhoto: 'Загрузіць новую фотаграфію',
  verifyCompany: 'Праверыць кампанію',
};
