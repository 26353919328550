import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { GroupBase } from 'react-select';
import ReactSelect, { AsyncProps } from 'react-select/async';
import { Option } from 'entities/Option';

import cl from './async-select.module.scss';

type AsyncSelectProps = AsyncProps<Option, boolean, GroupBase<Option>>;

interface CustomProps {
  label?: string;
  error?: string;
  searchParams?: Record<string, string | number | null | undefined>;
  fetchList: (params: Record<string, string | number | null>) => Promise<Option[]>;
}

const PRAsyncSelect: React.FC<AsyncSelectProps & CustomProps> = (props) => {
  const { t } = useTranslation();
  const [timer, setTimer] = useState<number>();
  const [isFocused, setFocus] = useState(false);

  const loadOptions = (inputValue: string, callback: (options: Option[]) => void) => {
    window.clearTimeout(timer);

    setTimer(
      window.setTimeout(() => {
        props
          .fetchList({
            page: 1,
            page_size: 100,
            ...(inputValue && { name: inputValue }),
            ...(props.searchParams && props.searchParams),
          })
          .then((res) => callback(res));
      }, 500)
    );
  };

  const onSelectFocus = () => {
    setFocus(true);
  };

  const onSelectBlur = () => {
    setFocus(false);
  };

  return (
    <div
      className={`${cl['async-select']} ${
        isFocused || props.value ? cl['async-select--focus'] : ''
      }`}
    >
      <ReactSelect
        loadingMessage={() => t('common.loading')}
        noOptionsMessage={() => t('common.listEmpty')}
        loadOptions={loadOptions}
        cacheOptions
        styles={{
          control: (base, { isDisabled }) => ({
            ...base,
            borderColor: props.error ? '#FF675E' : isDisabled ? '#A9B5BB' : '#C5DCFF',
            paddingTop: '4px',
            paddingBottom: '4px',
            fontSize: '14px',
            boxShadow: 'none',
            backgroundColor: 'transparent',
            '&:hover': {
              borderWidth: '1px',
            },
          }),
          singleValue: (base, { isDisabled }) => ({
            ...base,
            color: isDisabled ? '#A9B5BB' : '#030D2E',
          }),
        }}
        {...props}
        placeholder={props.placeholder ? props.placeholder : ''}
        onFocus={onSelectFocus}
        onBlur={onSelectBlur}
      />

      {props.error ? <p className={cl['async-select__error']}>{props.error}</p> : null}

      {props.label ? (
        <p
          className={`${cl['async-select__label']} ${
            props.error ? cl['async-select__label--error'] : ''
          }`}
        >
          {props.label}
          {props.required ? <span>*</span> : ''}
        </p>
      ) : null}
    </div>
  );
};

export default PRAsyncSelect;
