import React from 'react';
import { useTranslation } from 'react-i18next';
import { NavLink, useNavigate } from 'react-router-dom';
import { ReactComponent as PenIcon } from 'assets/icons/pen.svg';
import { ReactComponent as TrashIcon } from 'assets/icons/trash.svg';
import PRIconButton from 'components/PRIconButton';
import { useConfirmBox } from 'hooks/confirm-box';
import { Product } from 'entities/Product';

import cl from './table.module.scss';

interface Props {
  products: Product[];
  onDelete: (id: number) => void;
}

const CatalogTable: React.FC<Props> = ({ products, onDelete }) => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { showConfirmBox } = useConfirmBox();

  const onEditProduct = (id: number) => {
    navigate(`/company/catalog/${id}/edit`);
  };

  const onDeleteProduct = (product: Product) => {
    showConfirmBox({
      message: `Вы действительно хотите удалить товар "${product.title}"?`,
      okText: t('action.yes'),
      cancelText: t('action.no'),
      onAccept: () => {
        onDelete(product.id);
      },
    });
  };

  return (
    <div className={cl['table']}>
      <div className={cl['table__header']}>
        <span>№</span>
        <span>{t('common.product')}</span>
        <span>{t('common.vendorCode')}</span>
        <span>{t('common.date')}</span>
        <span>{t('common.display')}</span>
        <span>{t('common.actions')}</span>
      </div>
      {products.map((product, index) => (
        <div key={product.id} className={cl['table__row']}>
          <span>{index + 1}</span>
          <div className={cl['table__product-title']}>
            <div className={cl['table__image']}>
              {product.images.length ? (
                <img src={product.images[0].link} alt={product.title} />
              ) : null}
            </div>
            <NavLink to={`/products/${product.id}`} className={cl['table__product-link']}>
              {product.title}
            </NavLink>
          </div>
          <span>{product.vendorCode}</span>
          <span>{product.createdAt.toLocaleDateString()}</span>
          <p>
            <span className={product.createdAt ? cl['table__published'] : cl['table__draft']}>
              Опубликован
            </span>
          </p>
          <span className={cl['table__buttons']}>
            <PRIconButton width="32px" height="32px" onClick={() => onEditProduct(product.id)}>
              <PenIcon />
            </PRIconButton>
            <PRIconButton
              colorType="danger"
              width="32px"
              height="32px"
              onClick={() => onDeleteProduct(product)}
            >
              <TrashIcon />
            </PRIconButton>
          </span>
        </div>
      ))}
    </div>
  );
};

export default CatalogTable;
