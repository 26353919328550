import React, { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import PRTitle from 'components/PRTitle';
import { usePartialState } from 'hooks/partial-state';

import AuthSteps from '../../components/AuthSteps';
import ResetFirstStep from '../../components/ResetFirstStep';
import ResetSecondStep from '../../components/ResetSecondStep';
import ResetThirdStep from '../../components/ResetThirdStep';

import cl from './reset-password.module.scss';

const ResetPassword = () => {
  const { t } = useTranslation();
  const [form, setForm] = usePartialState({
    email: '',
    code: '',
  });
  const [step, setStep] = useState(1);

  const onEmailSet = (email: string) => {
    setForm({ email });
    setStep(2);
  };

  const onCodeSet = (code: string) => {
    setForm({ code });
    setStep(3);
  };

  const currentStep = useMemo(() => {
    if (step === 1) {
      return <ResetFirstStep onSubmit={onEmailSet} />;
    } else if (step === 2) {
      return <ResetSecondStep email={form.email} onSubmit={onCodeSet} />;
    } else if (step === 3) {
      return <ResetThirdStep {...form} />;
    }
    return null;
  }, [step]);

  return (
    <div className={cl['reset']}>
      <div className={cl['reset__forms']}>
        <PRTitle tag="h2">{t('common.passwordRecovery')}</PRTitle>
        {currentStep}
      </div>
      <AuthSteps current={step} total={3} />
    </div>
  );
};

export default ResetPassword;
