export default {
  notConnection: "Перевірте з'єднання з Інтернетом",
  unknown: 'Щось пішло не так',
  server: 'Помилка сервера. Зверніться до служби підтримки.',
  wrongAuth: 'Невірний email або пароль',
  required: "Обов'язкове поле для заповнення",
  invalidEmail: 'Некоректний email',
  minLength: 'Має бути довше {{len}} символів',
  maxLength: 'Має бути не довше {{len}} символів',
  passwordsNotMatch: 'Паролі не збігаються',
  notCorrectPrice: 'Некоректна ціна',
  notCorrectLink: 'Некоректне посилання',
  forbidden: 'У вас недостатньо прав для даної дії',
  phone: 'Некоректний номер телефону',
  incorrectFormat: 'Файл має бути у форматі {{format}}',
  selectRequiredColumns: "Не вибрані обов'язкові стовпці",
  readFile: 'Помилка при читанні файлу',
  emailNotAvailable: 'Email вже зареєстрований у системі',
};
