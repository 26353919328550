import React from 'react';
import { Outlet } from 'react-router-dom';
import PRTabs from 'components/PRTabs';

import cl from './requests.module.scss';

const RequestsLayout = () => {
  const tabs = [
    {
      id: 1,
      title: 'Партнёрство',
      badge: '1',
      action: '/company/requests',
    },
    {
      id: 2,
      title: 'Товары',
      badge: '2',
      action: '/company/requests/product',
    },
  ];

  return (
    <div className={cl['requests']}>
      <div className={cl['requests__tabs']}>
        <PRTabs tabs={tabs} badgeColor="green" />
      </div>
      <Outlet />
    </div>
  );
};

export default RequestsLayout;
