import React, { useMemo } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { ReactComponent as CloseEyeIcon } from 'assets/icons/close-eye.svg';
import { ReactComponent as OpenEyeIcon } from 'assets/icons/open-eye.svg';
import PRButton from 'components/PRButton';
import PRInput from 'components/PRInput';
import PRTitle from 'components/PRTitle';
import { registrationSlice, ThirdStep } from 'store/registration';
import { clearNonNumericSymbols } from 'utils/formatter.util';
import useHandleError from 'hooks/handleError';
import { usePartialState } from 'hooks/partial-state';
import { useAppDispatch, useAppSelector } from 'hooks/redux';
import { registrationCompany, registrationUser } from 'api/repositories/users.repository';
import { ErrorResponse } from 'entities/Error';

import cl from './third-step.module.scss';

const RegistrationThirdStep = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const { start, first, second, third } = useAppSelector((state) => state.registration);
  const { handleCommonError } = useHandleError();

  const [visible, setVisible] = usePartialState({ password: false, confirm: false });

  const {
    control,
    formState: { isSubmitting },
    watch,
    handleSubmit,
  } = useForm({
    defaultValues: {
      ...third,
    },
  });

  const onSubmit = (step: ThirdStep) => {
    if (start.type === 'manufacturer') {
      onCompanyRegistration(step);
    } else {
      onUserRegistration(step);
    }
  };

  const onCompanyRegistration = (step: ThirdStep) => {
    const data = {
      name: first.companyName,
      countryId: first.country?.value || 0,
      taxId: first.bin,
      phoneNumber: first.country?.phoneCode + clearNonNumericSymbols(first.phone),
      industryId: first.workingArea?.value || 0,
      legalAddress: first.legalAddress,
      email: first.email,
      dataProcessingConsent: first.isAgree,
      password: step.password,
      confirmPassword: step.confirmPassword,
    };

    registrationCompany(data)
      .then(() => {
        dispatch(registrationSlice.actions.setThirdStep(step));
      })
      .catch((err: unknown) => {
        const error = err as ErrorResponse;
        handleCommonError(error);
      });
  };

  const onUserRegistration = (step: ThirdStep) => {
    const data = {
      firstname: second.firstName,
      lastname: second.lastName,
      countryId: second.country?.value || 0,
      email: second.email,
      ...(second.phone && { phoneNumber: second.phone }),
      password: step.password,
      confirmPassword: step.confirmPassword,
    };

    registrationUser(data)
      .then(() => {
        dispatch(registrationSlice.actions.setThirdStep(step));
      })
      .catch((err: unknown) => {
        const error = err as ErrorResponse;
        handleCommonError(error);
      });
  };

  const passwordSuffix = useMemo(() => {
    if (visible.password) {
      return (
        <CloseEyeIcon
          className={cl['third-step__eye']}
          onClick={() => setVisible({ password: false })}
        />
      );
    }
    return (
      <OpenEyeIcon
        className={cl['third-step__eye']}
        onClick={() => setVisible({ password: true })}
      />
    );
  }, [visible.password]);

  const confirmSuffix = useMemo(() => {
    if (visible.confirm) {
      return (
        <CloseEyeIcon
          className={cl['third-step__eye']}
          onClick={() => setVisible({ confirm: false })}
        />
      );
    }
    return (
      <OpenEyeIcon
        className={cl['third-step__eye']}
        onClick={() => setVisible({ confirm: true })}
      />
    );
  }, [visible.confirm]);

  return (
    <form className={cl['third-step']} onSubmit={handleSubmit(onSubmit)}>
      <PRTitle tag="h1">{t('common.createPassword')}</PRTitle>

      <p className={cl['third-step__text']}>{t('message.createStrongPassword')}</p>

      <div className={cl['third-step__inputs']}>
        <Controller
          control={control}
          name="password"
          rules={{
            required: { message: t('error.required'), value: true },
            minLength: { message: t('error.minLength', { len: 8 }), value: 8 },
          }}
          render={({ field: { onChange, onBlur, value, name, ref }, fieldState: { error } }) => (
            <PRInput
              label={t('form.password')}
              type={visible.password ? 'text' : 'password'}
              ref={ref}
              name={name}
              value={value}
              suffix={passwordSuffix}
              onChange={onChange}
              onBlur={onBlur}
              error={error?.message}
              helperText={t('message.passwordHelper')}
            />
          )}
        />
        <Controller
          control={control}
          name="confirmPassword"
          rules={{
            required: { message: t('error.required'), value: true },
            validate: (value: string) => {
              if (watch('password') !== value) {
                return t('error.passwordsNotMatch');
              }
            },
          }}
          render={({ field: { onChange, onBlur, value, name, ref }, fieldState: { error } }) => (
            <PRInput
              label={t('form.confirmPassword')}
              type={visible.confirm ? 'text' : 'password'}
              ref={ref}
              name={name}
              value={value}
              suffix={confirmSuffix}
              onChange={onChange}
              onBlur={onBlur}
              error={error?.message}
            />
          )}
        />
      </div>

      <div className={cl['third-step__button']}>
        <PRButton loading={isSubmitting} type="submit">
          {t('action.continue')}
        </PRButton>
      </div>
    </form>
  );
};

export default RegistrationThirdStep;
