export default {
  change: 'Өзгөртүү',
  delete: 'Жок кылуу',
  find: 'Табуу',
  reset: 'Кайра орнотуу',
  save: 'Сактоо',
  edit: 'Түзөтүү',
  cancel: 'Жокко чыгаруу',
  accept: 'Макулмуу',
  login: 'Кириңиз',
  logout: 'Чыгуу',
  signup: 'Аккаунт түзүү',
  signin: 'Кирүү',
  next: 'Кийинки',
  seekManufacturer: 'Издөө производителя',
  seekProduct: 'Издөө товар',
  back: 'Артка',
  forward: 'Убакты',
  becomeAPartner: 'Коомдукчу болуу',
  contactWithManufacturer: 'Производител менен байланышуу',
  uploadFile: 'Файл жүктөө',
  sendRequest: 'Сурануу жөнөтүү',
  importProducts: 'Товарды импорттоо',
  addProduct: 'Товар кошуу',
  continue: 'Улантуу',
  addPrice: 'Баа кошуу',
  removePrice: 'Бааны алуу',
  saveChanges: 'Өзгөртүүлөрдү сактоо',
  leaveAsDraft: 'Чорточуру жолдоо',
  close: 'Жабуу',
  ready: 'Даяр',
  addBranches: 'Филиалдарды кошуу',
  resendActivationCode: 'Иштетүү кодун ылдоо',
  sendForVerification: 'Тексерүү үчүн жөнөтүү',
  deleteAccount: 'Аккаунтту жок кылуу',
  changePassword: 'Парольду өзгөртүү',
  hide: 'Жашыруу',
  expand: 'Кайтуу',
  orderProduct: 'Товар сара тапшыруу',
  writeToManufacturer: 'Производителге жазуу',
  goToCatalog: 'Каталогга кароо',
  confirm: 'Макулдауу',
  yes: 'Жашоо',
  no: 'Жок',
  selectChat: 'Сүйлөшүү тандоо',
  signIn: 'Кириңиз',
  recover: 'Кайра кабыл алуу',
  create: 'Түзүү',
  uploadNewPhoto: 'Жаңы сүрөт жүктөө',
  verifyCompany: 'Компанияны текшерүү',
};
