export default {
  main: 'Principal',
  loading: 'Cargando...',
  listEmpty: 'Lista vacía',
  attention: 'Atención',
  services: 'Servicios',
  applications: 'Solicitudes',
  partnership: 'Asociación',
  branches: 'Sucursales',
  catalog: 'Catálogo',
  faq: 'Preguntas frecuentes',
  companyData: 'Datos de la empresa',
  employeeData: 'Datos del empleado',
  accountActivation: 'Activación de la cuenta',
  aboutPlatform: 'Acerca de la plataforma',
  manageCompany: 'Gestión de la empresa',
  tradingAgency: 'Agencia de comercio',
  saleOfPoint: 'Venta de puntos',
  tradingHouse: 'Casa de comercio',
  manufacturerName: 'Nombre del fabricante',
  productName: 'Nombre del producto',
  priceFrom: 'Precio desde {{from}}',
  aboutManufacturer: 'Acerca del fabricante',
  contacts: 'Contactos',
  manufacturers: 'Fabricantes',
  summary: 'Resumen',
  certificates: 'Certificados',
  yearsAgo: '{{num}} años atrás',
  title: 'Título',
  brandName: 'Nombre de la marca',
  legalAddress: 'Dirección legal',
  jurisdiction: 'Jurisdicción',
  regNumberBin: 'Número de registro',
  registrationDate: 'Fecha de registro',
  sinceOnSite: 'Desde el sitio',
  product: 'Producto',
  vendorCode: 'Código del proveedor',
  date: 'Fecha',
  display: 'Mostrar',
  inStock: 'En stock',
  actions: 'Acciones',
  required: 'Requerido',
  optional: 'Opcional',
  productList: 'Lista de productos',
  barcode: 'Código de barras o UPC/EAN',
  color: 'Color',
  material: 'Material',
  currency: 'Moneda',
  unitType: 'Tipo de unidad',
  status: 'Estado',
  wholesalePrice: 'Precio al por mayor',
  from: 'desde',
  mainInfo: 'Información principal',
  productDescription: 'Descripción del producto',
  dimensions: 'Dimensiones',
  characteristics: 'Características',
  price: 'Precio',
  createAndUpdate: 'Crear y actualizar',
  created: 'Creado',
  updated: 'Actualizado',
  searchTerms: 'Términos de búsqueda',
  supportedFormats: 'Formatos admitidos: {{formats}}',
  maxSize: 'Tamaño máximo: {{size}}',
  images: 'Imágenes',
  agency: 'Agencia',
  password: 'Contraseña',
  addingBranch: 'Agregar sucursal',
  questionAndAnswer: 'Preguntas y respuestas',
  profile: 'Perfil',
  settings: 'Configuraciones',
  requirementsForVerification: 'Requisitos para la verificación',
  submitFollowingDocuments: 'Proporcionar los siguientes documentos',
  companyRegCertificate: 'Certificado de registro de la empresa',
  companyCharter: 'Estatutos de la empresa',
  certificateTaxReg: 'Certificado de registro fiscal',
  productionDoc: 'Documentación de producción',
  qualityCertificate: 'Certificados de calidad y conformidad',
  licensesAndCertificates: 'Licencias y certificados',
  suppliersAndCustomersContracts: 'Contratos con proveedores y clientes',
  accountDeleting: 'Eliminar cuenta',
  passwordChanging: 'Cambiar contraseña',
  today: 'hoy',
  weight: 'Peso',
  availableOnOrder: 'Disponible bajo pedido',
  manufacturerOtherProducts: 'Otros productos del fabricante',
  lastActivity: 'Última actividad',
  passwordRecovery: 'Recuperación de contraseña',
  forgotPassword: '¿Olvidaste tu contraseña?',
  haveNotAccount: '¿No tienes cuenta?',
  createPassword: 'Crear contraseña',
  registration: 'Registro',
  haveAnAccount: '¿Tienes una cuenta?',
  iAmManufacturer: 'Soy fabricante',
  iAmUser: 'Soy usuario',
  aboutCompany: 'Acerca de la empresa',
  logo: 'Logotipo',
  personalData: 'Datos personales',
  notifications: 'Notificaciones',
  todayAt: 'Hoy a las {{date}}',
  yesterdayAt: 'Ayer a las {{date}}',
  all: 'Todos',
  system: 'Sistema',
  notSpecified: 'no especificado',
};
