export default {
  change: 'تغيير',
  delete: 'حذف',
  find: 'البحث',
  reset: 'إعادة تعيين',
  save: 'حفظ',
  edit: 'تحرير',
  cancel: 'إلغاء',
  accept: 'موافق',
  login: 'تسجيل الدخول',
  logout: 'تسجيل الخروج',
  signup: 'إنشاء حساب',
  signin: 'تسجيل الدخول',
  next: 'التالي',
  seekManufacturer: 'بحث عن الشركة المصنعة',
  seekProduct: 'بحث عن المنتج',
  back: 'رجوع',
  forward: 'إلى الأمام',
  becomeAPartner: 'كن شريكًا',
  contactWithManufacturer: 'التواصل مع الشركة المصنعة',
  uploadFile: 'تحميل ملف',
  sendRequest: 'إرسال طلب',
  importProducts: 'استيراد المنتجات',
  addProduct: 'إضافة منتج',
  continue: 'متابعة',
  addPrice: 'إضافة السعر',
  removePrice: 'إزالة السعر',
  saveChanges: 'حفظ التغييرات',
  leaveAsDraft: 'ترك كمسودة',
  close: 'إغلاق',
  ready: 'جاهز',
  addBranches: 'إضافة فروع',
  resendActivationCode: 'إعادة إرسال رمز التنشيط',
  sendForVerification: 'إرسال للتحقق',
  deleteAccount: 'حذف الحساب',
  changePassword: 'تغيير كلمة المرور',
  hide: 'إخفاء',
  expand: 'توسيع',
  orderProduct: 'طلب المنتج',
  writeToManufacturer: 'الكتابة إلى الشركة المصنعة',
  goToCatalog: 'الانتقال إلى الكتالوج',
  confirm: 'تأكيد',
  yes: 'نعم',
  no: 'لا',
  selectChat: 'اختر الدردشة',
  signIn: 'تسجيل الدخول',
  recover: 'استعادة',
  create: 'إنشاء',
  uploadNewPhoto: 'تحميل صورة جديدة',
  verifyCompany: 'التحقق من الشركة',
};
