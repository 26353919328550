export default {
  signTo: 'Sisteme Giriş',
  signUp: 'Kişisel Hesap Oluşturma',
  passwordHelper: 'En az 8 karakter, 1 büyük harf, 1 rakam.',
  lastUpdate: 'Son Güncelleme: {{date}}',
  tradingAgency:
    'Ticaret Ajansı: Belirli bir bölgede dağıtımı kurma taahhüdünü üstlenen temsilci, önceden belirlenmiş kurallara göre.',
  saleOfPoint: 'Belirli bir satıcıda ürünün bölgesel varlığı.',
  tradingHouse:
    'Ticaret Evi: Üretici tarafından yetkilendirilen, belirli bir bölgede ürün temsil etme ve dağıtım kuralları oluşturma görevini üstlenen büyük bir ticaret organizasyonu.',
  uploadPresentationOrFile: 'Gerektiğinde ek belgeleri, sunumu veya dosyayı yükleyin',
  usingForSEO:
    'SEO için kullanılır ve ürünün arama motorlarında görünürlüğünü artırmaya yardımcı olur',
  noBranchesYet: 'Henüz eklenmiş şube yok',
  branchBenefits:
    'Şube listesi, güveni artırmanıza ve web sitesinde görünürlüğü iyileştirmenize yardımcı olur; ayrıca diğer şirketlerin şubeleri görmesine ve iletişim kurmasına olanak tanır.',
  activationCodeSend:
    'E-posta adresinize kayıt tamamlamak için bir kod gönderildi, lütfen kodu aşağıya girin',
  resendCodeAfter: '{{seconds}} saniye sonra kodu yeniden gönder',
  codeSent: 'Kod gönderildi',
  allDataWillBeDeleted: 'Tüm verileriniz hesapla birlikte silinecektir',
  changePasswordImproveAccount:
    'Hesap güvenliğini artırmak amacıyla hesap erişim kodunu güncelleme',
  writeEmailForRecovery: 'Lütfen hesabınıza bağlı olan e-posta adresinizi girin.',
  codeForRecovery: 'Şifre sıfırlama kodu e-postanıza gönderildi',
  enterCodeToAccessAccount: 'Hesaba erişimi yeniden sağlamak için aşağıdaki kodu girin',
  createStrongPassword: 'Hesabınız için güçlü bir şifre oluşturun',
  infoAboutYourCompany: 'Şirketinizle ilgili bilgileri girin',
  iWantCatalogAndMakeSale: 'Kataloğumu sergilemek ve ürün satmak istiyorum',
  iWantSearchManufacturers: 'Üretici arayışındayım ve işbirliği yapmak istiyorum',
  tradingAgencyDescription:
    'Ticaret Ajansları: Diğer şirketlerin ürünlerini belirli bir bölgede temsil eden ve satan organizasyonlardır. Müşteri arama, ürün tanıtımı, müzakereler yapma ve temsil edilen şirket adına işlem yapma gibi işlevleri yerine getirebilirler. Ticaret ajanslarının örnekleri: emlak ajansları, otomobil satış ajansları vb.',
  tradingHouseDescription:
    'Ticaret Evleri: Birden fazla mağazayı veya dükkanı tek bir yönetim şirketi altında birleştiren büyük ticaret organizasyonlarıdır. Ticaret evleri, geniş bir ürün yelpazesi veya hizmetler sunabilen farklı departmanları ve uzmanlaşmış bölümleri içerebilir. Ticaret evlerinin örnekleri: büyük mağazalar, alışveriş merkezleri vb.',
  tradingPointDescription:
    'Ticaret Noktaları: Ürünlerin veya hizmetlerin doğrudan tüketiciye satıldığı yerlerdir. Bunlar genellikle fiziksel bir alanı olan mağazalar, süpermarketler, pazarlar, ticaret stantları vb. olabilir.',
};
