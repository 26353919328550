import React, { useEffect } from 'react';
import { ReactComponent as CloseIcon } from 'assets/icons/close.svg';

import PRTitle from '../PRTitle';

import cl from './base-modal.module.scss';

interface Props {
  title: string;
  children: React.ReactNode;
  onClose?: () => void;
}

const BaseModal: React.FC<Props> = ({ title, children, onClose }) => {
  useEffect(() => {
    document.body.style.overflow = 'hidden';
    return () => {
      document.body.style.overflow = 'auto';
    };
  });
  return (
    <div className={cl.modal} onClick={() => onClose && onClose()}>
      <div className={cl['modal__wrapper']} onClick={(e) => e.stopPropagation()}>
        <div className={cl['modal__header']}>
          <PRTitle tag="h3" className={cl['modal__title']}>
            {title}
          </PRTitle>
          <button className={cl['modal__close']} onClick={() => onClose && onClose()}>
            <CloseIcon />
          </button>
        </div>
        {children}
      </div>
    </div>
  );
};

export default BaseModal;
