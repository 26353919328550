import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { NavLink, Outlet, useParams } from 'react-router-dom';
import { ReactComponent as LeftIcon } from 'assets/icons/arrow-left.svg';
import { ReactComponent as VerifiedIcon } from 'assets/icons/verified.svg';
import PRButton from 'components/PRButton';
import PRTitle from 'components/PRTitle';
import { fetchManufacturerById } from 'api/repositories/manufacturer.repository';
import { Manufacturer } from 'entities/Manufacturer';

import BecomePartnerModal from '../../components/BecomePartnerModal';

import cl from './manufacturer.module.scss';

const ManufacturerLayout = () => {
  const { t } = useTranslation();
  const { id } = useParams();
  const [manufacturer, setManufacturer] = useState<Manufacturer | null>();
  const [showPartnerModal, setShowPartnerModal] = useState(false);

  useEffect(() => {
    if (!id) return;

    fetchManufacturerById(+id).then((res) => {
      setManufacturer(res);
    });
  }, [id]);

  const tabs = [
    {
      id: 1,
      path: `/manufacturers/${id}`,
      text: t('common.catalog'),
    },
    {
      id: 2,
      path: `/manufacturers/${id}/about`,
      text: t('common.aboutManufacturer'),
    },
    {
      id: 3,
      path: `/manufacturers/${id}/branches`,
      text: t('common.branches'),
    },
  ];

  if (!manufacturer) {
    return <div>Loading</div>;
  }

  return (
    <div className={cl.manufacturer}>
      <NavLink to="/?section=manufacturer&page=1" className={cl['manufacturer__back']}>
        <LeftIcon />
        <span>{t('common.manufacturers')}</span>
      </NavLink>
      <div className={cl['manufacturer__card']}>
        <div className={cl['manufacturer__image']}>
          {manufacturer.logo ? (
            <img src={manufacturer.logo} alt={manufacturer.title} />
          ) : (
            <img src={require('assets/images/empty.png')} alt="" />
          )}
        </div>

        <div>
          <div className={cl['manufacturer__title']}>
            <PRTitle tag="h1">{manufacturer.title}</PRTitle>
            {manufacturer.verified ? <VerifiedIcon /> : null}
          </div>
          <p className={cl['manufacturer__description']}>{manufacturer.description}</p>
        </div>

        <div className={cl['manufacturer__buttons']}>
          <PRButton onClick={() => setShowPartnerModal(true)}>
            {t('action.becomeAPartner')}
          </PRButton>
          <PRButton variant="outline">{t('action.contactWithManufacturer')}</PRButton>
        </div>

        <div className={cl['manufacturer__tabs']}>
          {tabs.map((tab) => (
            <NavLink
              key={tab.id}
              to={tab.path}
              className={({ isActive }) => (isActive ? cl.active : '')}
              end
            >
              {tab.text}
            </NavLink>
          ))}
        </div>
      </div>
      <Outlet context={manufacturer} />

      <BecomePartnerModal isVisible={showPartnerModal} changeVisible={setShowPartnerModal} />
    </div>
  );
};

export default ManufacturerLayout;
