import request from 'api/axios-instance';
import { User } from 'entities/User';

import { UserDao } from '../dao/User.dao';
import { mapUserDaoToEntity } from '../mappers/user.mappers';

interface AuthToken {
  accessToken: string;
}

export interface CompanyCreateDto {
  name: string;
  countryId: number;
  taxId: string;
  phoneNumber: string;
  industryId: number;
  legalAddress: string;
  email: string;
  website?: string;
  dataProcessingConsent: boolean;
  password: string;
  confirmPassword: string;
}

export interface UserCreateDto {
  firstname: string;
  lastname: string;
  countryId: number;
  email: string;
  phoneNumber?: string;
  password: string;
  confirmPassword: string;
}

export interface UserUpdateDto {
  firstName?: string;
  lastName?: string;
  patronymic?: string;
}

export interface PasswordUpdateDto {
  password: string;
  newPassword: string;
  confirmNewPassword: string;
}

export const login = (email: string, password: string) =>
  request
    .post<AuthToken>('/auth/login', {
      username: email,
      password,
    })
    .then((response) => ({ token: response?.data.accessToken }));

export const registrationCompany = (company: CompanyCreateDto) =>
  request.post('/registration/organization', company);

export const registrationUser = (user: UserCreateDto) => request.post('/registration/user', user);

export const verifyEmail = (data: { email: string; code: string }) =>
  request.post('registration/verify-email', data);

export const resendActivationCode = (email: string) =>
  request.post(`/registration/resend?email=${email}`, {});

export const fetchMe = () =>
  request.get('/accounts/me').then((response) => {
    const user: User = mapUserDaoToEntity(response.data);
    return user;
  });

export const deleteAccount = (id: string) => request.delete(`accounts/${id}`);

export const checkEmailAvailability = (email: string) =>
  request
    .get('accounts/check-email', { params: { email } })
    .then((res) => res.data.isAccountExists);

export const updateUser = (data: UserUpdateDto) =>
  request
    .put<UserDao>('users/me', {
      first_name: data.firstName,
      last_name: data.lastName,
      patronymic: data.patronymic,
    })
    .then((response) => mapUserDaoToEntity(response.data));

export const changePassword = (data: PasswordUpdateDto) =>
  request.put<void>('/users/me/change-password', {
    password: data.password,
    new_password: data.newPassword,
    confirm_password: data.confirmNewPassword,
  });

export const sendResetPasswordCode = (email: string) =>
  request.post('auth/password-reset-code', { email });

export const resendResetPasswordCode = (email: string) =>
  request.post('auth/resend-password-reset-code', { email });

export const verifyResetPasswordCode = (email: string, code: string) =>
  request.post('auth/verify-password-reset-code', { email, code });

interface ResetPasswordDto {
  email: string;
  code: string;
  password: string;
}

export const resetPassword = (data: ResetPasswordDto) => request.post('auth/reset-password', data);

export const verifyOrganization = (data: string[]) =>
  request.post('organizations/verification', { attachmentIds: data });

export interface UpdateOrganizationDto {
  name: string;
  countryId: number;
  taxId?: string;
  legalAddress: string;
  website?: string;
  industryId: number;
  latitude?: number;
  longitude?: number;
  logoId?: string;
  description?: string;
  phoneNumber?: string;
}

export const updateOrganization = (data: UpdateOrganizationDto) =>
  request.patch('organizations', data);

export const saveCertificates = (data: string[]) =>
  request.post('organizations/certificates', { fileIds: data });
