import React, { useEffect, useRef } from 'react';
import { useYMaps } from '@pbe/react-yandex-maps';

import { ClusterPointDto, Point } from '../../types/dto/cluster-point.dto';

interface Props {
  center: [number, number];
  zoom: number;
  clusterOptions: ClusterPointDto[];
  changeZoom: (val: number) => void;
}

const PRHomeMap: React.FC<Props> = ({ center, zoom, clusterOptions }) => {
  const ymaps = useYMaps(['Map', 'Placemark', 'Clusterer', 'geoObject.addon.balloon']);
  const map = useRef<ymaps.Map | null>(null);

  useEffect(() => {
    if (!ymaps) return;
    map.current = new ymaps.Map('home-map', { center, zoom });
  }, [ymaps]);

  useEffect(() => {
    onInit();
  }, [clusterOptions]);

  const onInit = () => {
    if (map.current && ymaps) {
      map.current.geoObjects.removeAll();

      const points: ymaps.Placemark[] = [];

      if (clusterOptions.length) {
        clusterOptions.forEach((c) => {
          const placeMark = new ymaps.Placemark(
            c.cords,
            { balloonContent: getBalloonContent(c.point) },
            {
              iconLayout: 'default#image',
              iconImageHref: c.icon,
              iconImageSize: [32, 32],
              iconImageOffset: [-16, -28],
            }
          );
          points.push(placeMark);
        });

        const clusterer = new ymaps.Clusterer({
          preset: 'islands#blueClusterIcons',
          groupByCoordinates: false,
        });

        clusterer.add(points);
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        map.current.geoObjects.add(clusterer);
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        map.current.setBounds(map.current.geoObjects.getBounds(), {
          checkZoomRange: true,
          zoomMargin: 35,
          preciseZoom: true,
        });
      }
    }
  };

  const getBalloonContent = (point: Point) => {
    return [
      '<div class="map__balloon">',
      `<a href="${point.link}">${point.title}</a>`,
      `<p>${point.address}</p>`,
      '</div>',
    ].join('');
  };

  return (
    <div id="home-map" style={{ position: 'absolute', top: 0, right: 0, bottom: 0, left: 0 }} />
  );
};

export default PRHomeMap;
