export default {
  main: 'الرئيسية',
  loading: 'جاري التحميل...',
  listEmpty: 'القائمة فارغة',
  attention: 'تنبيه',
  services: 'الخدمات',
  applications: 'الطلبات',
  partnership: 'الشراكة',
  branches: 'الفروع',
  catalog: 'الكتالوج',
  faq: 'الأسئلة الشائعة',
  companyData: 'بيانات الشركة',
  employeeData: 'بيانات الموظف',
  accountActivation: 'تفعيل الحساب',
  aboutPlatform: 'حول المنصة',
  manageCompany: 'إدارة الشركة',
  tradingAgency: 'وكالة تجارية',
  saleOfPoint: 'نقطة البيع',
  tradingHouse: 'بيت التجارة',
  manufacturerName: 'اسم الشركة المصنعة',
  productName: 'اسم المنتج',
  priceFrom: 'السعر من {{from}}',
  aboutManufacturer: 'حول الشركة المصنعة',
  contacts: 'جهات الاتصال',
  manufacturers: 'المصنعين',
  summary: 'ملخص',
  certificates: 'الشهادات',
  yearsAgo: 'قبل {{num}} سنوات',
  title: 'العنوان',
  brandName: 'اسم العلامة التجارية',
  legalAddress: 'العنوان القانوني',
  jurisdiction: 'الاختصاص القانوني',
  regNumberBin: 'الرقم التسجيلي',
  registrationDate: 'تاريخ التسجيل',
  sinceOnSite: 'منذ تاريخ الانضمام',
  product: 'المنتج',
  vendorCode: 'الرمز التعريفي',
  date: 'التاريخ',
  display: 'العرض',
  inStock: 'متوفر في المخزن',
  actions: 'الإجراءات',
  required: 'مطلوب',
  optional: 'اختياري',
  productList: 'قائمة المنتجات',
  barcode: 'الباركود أو UPC/EAN',
  color: 'اللون',
  material: 'المادة',
  currency: 'العملة',
  unitType: 'نوع الوحدة',
  status: 'الحالة',
  wholesalePrice: 'السعر بالجملة',
  from: 'من',
  mainInfo: 'معلومات أساسية',
  productDescription: 'وصف المنتج',
  dimensions: 'الأبعاد',
  characteristics: 'الخصائص',
  price: 'السعر',
  createAndUpdate: 'إنشاء وتحديث',
  created: 'تم الإنشاء',
  updated: 'تم التحديث',
  searchTerms: 'مصطلحات البحث',
  supportedFormats: 'الصيغ المدعومة: {{formats}}',
  maxSize: 'الحجم الأقصى: {{size}}',
  images: 'الصور',
  agency: 'الوكالة',
  password: 'كلمة المرور',
  addingBranch: 'إضافة فرع',
  questionAndAnswer: 'الأسئلة والإجابات',
  profile: 'الملف الشخصي',
  settings: 'الإعدادات',
  requirementsForVerification: 'متطلبات التحقق',
  submitFollowingDocuments: 'يرجى تقديم الوثائق التالية',
  companyRegCertificate: 'شهادة تسجيل الشركة',
  companyCharter: 'نظام الشركة',
  certificateTaxReg: 'شهادة تسجيل الضرائب',
  productionDoc: 'وثائق الإنتاج',
  qualityCertificate: 'شهادات الجودة والامتثال',
  licensesAndCertificates: 'التراخيص والشهادات',
  suppliersAndCustomersContracts: 'عقود مع الموردين والعملاء',
  accountDeleting: 'حذف الحساب',
  passwordChanging: 'تغيير كلمة المرور',
  today: 'اليوم',
  weight: 'الوزن',
  availableOnOrder: 'المنتج متوفر بناءً على الطلب',
  manufacturerOtherProducts: 'منتجات أخرى من الشركة المصنعة',
  lastActivity: 'آخر نشاط',
  passwordRecovery: 'استعادة كلمة المرور',
  forgotPassword: 'نسيت كلمة المرور؟',
  haveNotAccount: 'ليس لديك حساب؟',
  createPassword: 'إنشاء كلمة مرور',
  registration: 'التسجيل',
  haveAnAccount: 'لديك حساب؟',
  iAmManufacturer: 'أنا مصنع',
  iAmUser: 'أنا مستخدم',
  aboutCompany: 'عن الشركة',
  logo: 'شعار الشركة',
  personalData: 'البيانات الشخصية',
  notifications: 'الإشعارات',
  todayAt: 'اليوم في {{date}}',
  yesterdayAt: 'أمس في {{date}}',
  all: 'الكل',
  system: 'نظامي',
  notSpecified: 'غير محدد',
};
