export default {
  submit: 'إرسال',
  email: 'البريد الإلكتروني',
  password: 'كلمة المرور',
  title: 'العنوان',
  workingArea: 'نوع النشاط',
  country: 'البلد',
  legalAddress: 'العنوان القانوني',
  bin: 'الرقم الوطني للمعرفة (BIN)',
  website: 'الموقع الإلكتروني',
  phoneNumber: 'رقم الهاتف',
  fullName: 'الاسم الكامل',
  position: 'المنصب',
  firstName: 'الاسم الأول',
  lastName: 'اسم العائلة',
  patronymic: 'الأب',
  confirmPassword: 'تأكيد كلمة المرور',
  newPassword: 'كلمة المرور الجديدة',
  createAccount: 'إنشاء حساب',
  forgotPassword: 'نسيت كلمة المرور',
  city: 'المدينة',
  description: 'الوصف',
  category: 'الفئة',
  subcategory: 'الفئة الفرعية',
  region: 'المنطقة',
  industry: 'الصناعة',
  showOtherCompanies: 'عرض الشركات الأخرى غير المتواجدة في منطقتي',
  companyName: 'اسم الشركة',
  cooperationPurpose: 'هدف التعاون',
  offer: 'العرض',
  expectedBenefits: 'الفوائد المتوقعة',
  searchByIdTitle: 'البحث بواسطة الاسم، الرقم التعريفي، أو الرمز للمنتج',
  selectColumnName: 'اختر اسم العمود',
  positionName: 'اسم المنصب',
  width: 'العرض, {{unit}}',
  height: 'الارتفاع, {{unit}}',
  length: 'الطول, {{unit}}',
  weight: 'الوزن, {{unit}}',
  orderFrom: 'عند الطلب من',
  keyPhrases: 'أدخل عبارات مفتاحية يبحث عنها المشترين للعثور على منتجك',
  contactPerson: 'الشخص الاتصال',
  code: 'الكود',
  street: 'الشارع',
  writeMessage: 'أكتب رسالتك',
  branchType: 'نوع الفرع',
};
