import React, { useEffect, useLayoutEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ReactComponent as SmileIcon } from 'assets/icons/happy-face.svg';
import { ReactComponent as ClipIcon } from 'assets/icons/paper-clip.svg';
import { ReactComponent as TranslateIcon } from 'assets/icons/translate.svg';
import PRButton from 'components/PRButton';
import { usePartialState } from 'hooks/partial-state';
import { useAppSelector } from 'hooks/redux';
import { fetchConversationMessages, sendMessage } from 'api/repositories/conversations.repository';
import { Conversation } from 'entities/Conversation';
import { Message } from 'entities/Message';

import cl from './conversation-detail.module.scss';

interface Props {
  conversation: Conversation;
}

interface LocalMessage extends Message {
  type: 'message' | 'date';
  time: string;
}

const ConversationDetail: React.FC<Props> = ({ conversation }) => {
  const { t } = useTranslation();
  const { user } = useAppSelector((state) => state.auth);
  const [form, setForm] = usePartialState({
    text: '',
    file: null as File | null,
  });
  const [messages, setMessages] = useState<Message[]>([]);
  const messageContainer = useRef<HTMLDivElement | null>(null);

  const reverseMessages = useMemo(() => {
    const res: LocalMessage[] = [];
    let prevDate: Date | null = null;
    const today = new Date();

    for (let i = messages.length - 1; i >= 0; i--) {
      const currentDate = new Date(messages[i].createdAt);

      if (!prevDate || prevDate.toLocaleDateString() !== currentDate.toLocaleDateString()) {
        res.push({
          id: Date.now() + Math.random(),
          text:
            today.toLocaleDateString() === currentDate.toLocaleDateString()
              ? t('common.today')
              : currentDate.toLocaleDateString(),
          ownerId: Date.now(),
          type: 'date',
          time: '',
          createdAt: new Date(),
          updatedAt: new Date(),
        });
      }

      res.push({
        ...messages[i],
        type: 'message',
        time: currentDate.toLocaleTimeString([], {
          hour: '2-digit',
          minute: '2-digit',
        }),
      });

      prevDate = currentDate;
    }
    return res;
  }, [messages]);

  useEffect(() => {
    setMessages(() => []);
    fetchConversationMessages(conversation.id).then((res) => {
      setMessages(() => res.messages);
    });
  }, [conversation]);

  useLayoutEffect(() => {
    scrollToBottom();
  }, [messages]);

  const scrollToBottom = () => {
    if (messageContainer.current) {
      messageContainer.current.scrollTop = messageContainer.current.scrollHeight;
    }
  };

  const getActiveDateTime = (date: Date) => {
    date = new Date(date);
    const today = new Date();
    if (today.toLocaleDateString() === date.toLocaleDateString()) {
      return (
        t('common.today') +
        ', ' +
        date.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' })
      );
    } else {
      return date.toLocaleDateString();
    }
  };

  const onSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    if (!form.text) return;

    sendMessage(conversation.id, form.text).then((res) => {
      setMessages([res, ...messages]);
      setForm({ text: '' });
    });
  };

  return (
    <div className={cl['detail']}>
      <div className={cl['detail__header']}>
        <div className={cl['detail__image']}>
          <img src={conversation.avatar} alt={conversation.title} />
        </div>
        <div className={cl['detail__info']}>
          <p className={cl['detail__name']}>{conversation.title}</p>
          <p className={cl['detail__date']}>
            {t('common.lastActivity')}: {getActiveDateTime(conversation.createdAt)}
          </p>
        </div>
        <PRButton variant="text" prefix={<TranslateIcon />}>
          Показать оригинал
        </PRButton>
      </div>
      <div ref={messageContainer} className={cl['detail__body']}>
        {reverseMessages.map((message) =>
          message.type === 'date' ? (
            <p key={message.id} className={cl['detail__date-divider']}>
              <span>{message.text}</span>
            </p>
          ) : (
            <div
              key={message.id}
              className={`${cl['message']} ${
                message.ownerId.toString() === user!.id ? cl['message--blue'] : ''
              }`}
            >
              <p className={cl['message__text']}>{message.text}</p>
              {message.time ? <p className={cl['message__date']}>{message.time}</p> : null}
            </div>
          )
        )}
      </div>
      <form className={cl['detail__footer']} onSubmit={onSubmit}>
        <SmileIcon />
        <ClipIcon />
        <input
          value={form.text}
          type="text"
          placeholder={`${t('form.writeMessage')}...`}
          onChange={(e) => setForm({ text: e.target.value })}
        />
      </form>
    </div>
  );
};

export default ConversationDetail;
