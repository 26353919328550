import React, { useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { ReactComponent as PenIcon } from 'assets/icons/pen.svg';
import { ReactComponent as UploadIcon } from 'assets/icons/upload.svg';
import { ReactComponent as VerifyIcon } from 'assets/icons/verified.svg';
import PRAsyncSelect from 'components/PRAsyncSelect';
import PRButton from 'components/PRButton';
import PRInput from 'components/PRInput';
import PRTextarea from 'components/PRTextarea';
import PRTitle from 'components/PRTitle';
import { EMAIL_REGEX } from 'types/constants/email.regex';
import useHandleError from 'hooks/handleError';
import { useAppSelector } from 'hooks/redux';
import { uploadFile } from 'api/repositories/files.repository';
import { fetchCountries, fetchWorkingAreas } from 'api/repositories/handbooks.repository';
import { updateOrganization } from 'api/repositories/users.repository';
import { Document } from 'entities/Document';
import { ErrorResponse } from 'entities/Error';
import { CountryOption, Option } from 'entities/Option';
import { Organization } from 'entities/User';

import CertificatesCard from '../../components/CertificatesCard';
import VerificationDocsModal from '../../components/VerificationDocsModal';

import cl from './info.module.scss';

interface OrganizationForm {
  title: string;
  activityType: Option;
  description: string;
  country: CountryOption;
  bin: string;
  legalAddress: string;
  email: string;
  phoneNumber: string;
  avatar: string;
}

const OrganizationInfo = () => {
  const { t } = useTranslation();
  const { handleCommonError } = useHandleError();
  const user = useAppSelector((state) => state.auth.user) as Organization;
  const [isVerificationVisible, setVerificationVisible] = useState(false);
  const [isDisabled, setIsDisabled] = useState(true);
  const [avatarFile, setAvatarFile] = useState<Document | null>(null);

  const {
    control,
    formState: { isSubmitting },
    handleSubmit,
    reset,
    setValue,
    watch,
  } = useForm<OrganizationForm>({
    defaultValues: {
      title: user.title,
      activityType: user.activityType,
      description: user.description || '',
      country: user.country,
      bin: user.bin || '',
      legalAddress: user.legalAddress,
      email: user.email,
      phoneNumber: user.phoneNumber || '',
      avatar: user.avatar || '',
    },
  });

  const avatar = watch('avatar');

  const onCancel = () => {
    setIsDisabled(true);
    reset();
  };

  const uploadAvatar = (files: FileList | null) => {
    if (!files?.length) return;
    const fd = new FormData();
    fd.append('file', files[0]);
    uploadFile(fd).then((res) => {
      setAvatarFile(res);
      setValue('avatar', res.link);
    });
  };

  const onSubmit = async (form: OrganizationForm) => {
    try {
      await updateOrganization({
        name: form.title,
        countryId: form.country.value,
        legalAddress: form.legalAddress,
        industryId: form.activityType.value,
        ...(form.bin && { taxId: form.bin }),
        ...(avatarFile && { logoId: avatarFile.id }),
        ...(form.description && { description: form.description }),
        ...(form.phoneNumber && { phoneNumber: form.phoneNumber }),
        ...(user.website && { website: user.website }),
      });
      setIsDisabled(true);
    } catch (err: unknown) {
      const error = err as ErrorResponse;
      handleCommonError(error);
    }
  };

  return (
    <div className={cl['info']}>
      {!user.verified ? (
        <div className={`${cl['card']} ${cl['verification']}`}>
          <div>
            <PRTitle tag="h3" className={cl['verification__title']}>
              Получите верификацию компании <VerifyIcon />
            </PRTitle>
            <p className={cl['verification__text']}>
              Повысьте лояльность пользователей к вашей компании путём документального подтверждения
              данных.
            </p>
          </div>
          <PRButton onClick={() => setVerificationVisible(true)}>
            {t('action.verifyCompany')}
          </PRButton>
        </div>
      ) : null}

      <div className={cl['card']}>
        <div className={cl['card__header']}>
          <PRTitle tag="h3">{t('common.companyData')}</PRTitle>

          {isDisabled ? (
            <PRButton
              variant="text"
              prefix={<PenIcon className={cl['card__pen-icon']} />}
              onClick={() => setIsDisabled(false)}
            >
              {t('action.edit')}
            </PRButton>
          ) : null}
        </div>

        <div className={cl['card__block']}>
          <div className={cl['card__sub-title']}>
            <p>{t('common.aboutCompany')}</p>
            <p>Базовые данные о вашей организации, которые будут отображены на сайте</p>
          </div>
          <div className={cl['card__about']}>
            <Controller
              control={control}
              name="title"
              rules={{ required: { message: t('error.required'), value: true } }}
              render={({ field, fieldState: { error } }) => (
                <PRInput
                  {...field}
                  label={t('form.title')}
                  disabled={isDisabled}
                  error={error?.message}
                  required
                />
              )}
            />
            <Controller
              control={control}
              name="activityType"
              rules={{ required: { message: t('error.required'), value: true } }}
              render={({ field: { onChange, onBlur, value }, fieldState: { error } }) => (
                <PRAsyncSelect
                  value={value}
                  label={t('form.industry')}
                  fetchList={fetchWorkingAreas}
                  isDisabled={isDisabled}
                  error={error?.message}
                  isClearable
                  required
                  onChange={(val: unknown) => onChange(val as Option)}
                  onBlur={onBlur}
                />
              )}
            />
            <Controller
              control={control}
              name="description"
              render={({ field }) => (
                <PRTextarea {...field} label={t('form.description')} disabled={isDisabled} />
              )}
            />
          </div>
        </div>

        <div className={cl['card__block']}>
          <div className={cl['card__sub-title']}>
            <p>{t('common.contacts')}</p>
            <p>
              Обеспечьте актуальность и точность информации, чтобы ваши клиенты и партнеры всегда
              могли легко связаться с вами.
            </p>
          </div>
          <div className={cl['card__contact']}>
            <Controller
              control={control}
              name="country"
              rules={{ required: { message: t('error.required'), value: true } }}
              render={({ field: { onChange, onBlur, value }, fieldState: { error } }) => (
                <PRAsyncSelect
                  value={value}
                  label={t('form.country')}
                  fetchList={fetchCountries}
                  isDisabled={isDisabled}
                  error={error?.message}
                  isClearable
                  required
                  onChange={(val: unknown) => onChange(val as CountryOption)}
                  onBlur={onBlur}
                />
              )}
            />
            <Controller
              control={control}
              name="bin"
              rules={{
                required: { message: t('error.required'), value: true },
                minLength: { message: t('error.minLength', { len: 12 }), value: 12 },
              }}
              render={({ field, fieldState: { error } }) => (
                <PRInput
                  {...field}
                  label={t('common.regNumberBin')}
                  disabled={isDisabled}
                  error={error?.message}
                  maxLength={12}
                  type="number"
                  required
                />
              )}
            />
            <div className={cl['card__legal-address']}>
              <Controller
                control={control}
                name="legalAddress"
                rules={{ required: { message: t('error.required'), value: true } }}
                render={({ field, fieldState: { error } }) => (
                  <PRInput
                    {...field}
                    label={t('form.legalAddress')}
                    disabled={isDisabled}
                    error={error?.message}
                    required
                  />
                )}
              />
            </div>
            <Controller
              control={control}
              name="email"
              rules={{
                required: { message: t('error.required'), value: true },
                pattern: {
                  message: t('error.invalidEmail'),
                  value: EMAIL_REGEX,
                },
              }}
              render={({ field, fieldState: { error } }) => (
                <PRInput
                  {...field}
                  label={t('form.email')}
                  disabled={isDisabled}
                  error={error?.message}
                  required
                />
              )}
            />
            <Controller
              control={control}
              name="phoneNumber"
              rules={{
                minLength: { message: t('error.phone'), value: 12 },
              }}
              render={({ field, fieldState: { error } }) => (
                <PRInput
                  {...field}
                  label={t('form.phoneNumber')}
                  disabled={isDisabled}
                  error={error?.message}
                />
              )}
            />
          </div>
        </div>

        <div className={cl['card__block']}>
          <div className={cl['card__sub-title']}>
            <p>{t('common.logo')}</p>
            <p>
              Загрузите и редактируйте логотип, который наилучшим образом представит вашу компанию.
            </p>
          </div>
          <div className={cl['card__logo']}>
            <div className={cl['card__logo-image']}>
              {avatar ? <img src={avatar} alt={user.title} /> : null}
            </div>
            {isDisabled ? null : (
              <label className={cl['card__upload']} htmlFor="companyLogo">
                <input
                  id="companyLogo"
                  type="file"
                  accept=".png,.jpg,.jpeg,.svg"
                  onChange={(event) => uploadAvatar(event.target.files)}
                />
                <p>
                  <UploadIcon /> {t('action.uploadNewPhoto')}
                </p>
                <span>SVG, JPG или PNG (макс. 800х800px)</span>
              </label>
            )}
          </div>
        </div>

        {isDisabled ? null : (
          <div className={cl['card__footer']}>
            <PRButton loading={isSubmitting} onClick={handleSubmit(onSubmit)}>
              {t('action.saveChanges')}
            </PRButton>
            <PRButton variant="text" onClick={onCancel}>
              {t('action.cancel')}
            </PRButton>
          </div>
        )}
      </div>

      <CertificatesCard />

      <VerificationDocsModal
        isVisible={isVerificationVisible}
        changeVisible={setVerificationVisible}
      />
    </div>
  );
};

export default OrganizationInfo;
