import React, { useState } from 'react';

import ImportProductsFirst from '../../components/ImportProductsFirst';
import ImportProductsSecond from '../../components/ImportProductsSecond';

import cl from './import.module.scss';

const ImportProducts = () => {
  const [file, setFile] = useState<File | null>(null);

  return (
    <div className={cl['import-products']}>
      {file ? <ImportProductsSecond file={file} /> : <ImportProductsFirst onNext={setFile} />}
    </div>
  );
};

export default ImportProducts;
