export default {
  main: 'Main',
  loading: 'Loading...',
  listEmpty: 'List is empty',
  attention: 'Attention',
  services: 'Services',
  applications: 'Applications',
  partnership: 'Partnership',
  branches: 'Branches',
  catalog: 'Catalog',
  faq: 'Frequently Asked Questions',
  companyData: 'Company Data',
  employeeData: 'Employee Data',
  accountActivation: 'Account Activation',
  aboutPlatform: 'About the Platform',
  manageCompany: 'Manage Company',
  tradingAgency: 'Trading Agency',
  saleOfPoint: 'Sale Point',
  tradingHouse: 'Trading House',
  manufacturerName: 'Manufacturer Name',
  productName: 'Product Name',
  priceFrom: 'Price from {{from}}',
  aboutManufacturer: 'About Manufacturer',
  contacts: 'Contacts',
  manufacturers: 'Manufacturers',
  summary: 'Summary',
  certificates: 'Certificates',
  yearsAgo: '{{num}} years ago',
  title: 'Title',
  brandName: 'Brand Name',
  legalAddress: 'Legal Address',
  jurisdiction: 'Jurisdiction',
  regNumberBin: 'Registration Number',
  registrationDate: 'Registration Date',
  sinceOnSite: 'On Site Since',
  product: 'Product',
  vendorCode: 'Vendor Code',
  date: 'Date',
  display: 'Display',
  inStock: 'In Stock',
  actions: 'Actions',
  required: 'Required',
  optional: 'Optional',
  productList: 'Product List',
  barcode: 'Barcode or UPC/EAN',
  color: 'Color',
  material: 'Material',
  currency: 'Currency',
  unitType: 'Unit of Measurement',
  status: 'Status',
  wholesalePrice: 'Wholesale Price',
  from: 'from',
  mainInfo: 'Main Information',
  productDescription: 'Product Description',
  dimensions: 'Dimensions',
  characteristics: 'Characteristics',
  price: 'Price',
  createAndUpdate: 'Create and Update',
  created: 'Created',
  updated: 'Updated',
  searchTerms: 'Search Terms',
  supportedFormats: 'Supported Formats: {{formats}}',
  maxSize: 'Maximum Size: {{size}}',
  images: 'Images',
  agency: 'Agency',
  password: 'Password',
  addingBranch: 'Adding Branch',
  questionAndAnswer: 'Questions and Answers',
  profile: 'Profile',
  settings: 'Settings',
  requirementsForVerification: 'Requirements for Verification',
  submitFollowingDocuments: 'Submit the Following Documents',
  companyRegCertificate: 'Company Registration Certificate',
  companyCharter: 'Company Charter',
  certificateTaxReg: 'Certificate of Tax Registration',
  productionDoc: 'Production Documentation',
  qualityCertificate: 'Quality and Compliance Certificates',
  licensesAndCertificates: 'Licenses and Certificates',
  suppliersAndCustomersContracts: 'Suppliers and Customers Contracts',
  accountDeleting: 'Account Deletion',
  passwordChanging: 'Password Change',
  today: 'today',
  weight: 'Weight',
  availableOnOrder: 'Available on Order',
  manufacturerOtherProducts: 'Other Products by the Manufacturer',
  lastActivity: 'Last Activity',
  passwordRecovery: 'Password Recovery',
  forgotPassword: 'Forgot Password?',
  haveNotAccount: "Don't have an account?",
  createPassword: 'Create Password',
  registration: 'Registration',
  haveAnAccount: 'Have an account?',
  iAmManufacturer: 'I am a Manufacturer',
  iAmUser: 'I am a User',
  aboutCompany: 'About Company',
  logo: 'Logo',
  personalData: 'Personal Data',
  notifications: 'Notifications',
  todayAt: 'Today at {{date}}',
  yesterdayAt: 'Yesterday at {{date}}',
  all: 'All',
  system: 'System',
  notSpecified: 'Not Specified',
};
