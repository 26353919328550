import { User } from 'entities/User';

import { OrganizationDao, PersonDao, UserDao } from '../dao/User.dao';

import { mapDocumentDaoToEntity } from './document.mappers';

export function mapUserDaoToEntity(user: UserDao): User {
  const commonProps = {
    id: user.id,
    email: user.email,
    username: user.username,
    country: {
      value: user.details.country.id,
      label: user.details.country.name,
      phoneCode: user.details.country.phoneCode,
    },
  };

  if (user.type === 'USER') {
    const personProps: PersonDao = user;
    return {
      ...commonProps,
      type: 'USER',
      entityId: personProps.details.id,
      firstName: personProps.details.firstname,
      lastName: personProps.details.lastname,
    };
  } else if (user.type === 'ORGANIZATION') {
    const organizationProps: OrganizationDao = user;
    return {
      ...commonProps,
      type: 'ORGANIZATION',
      entityId: organizationProps.details.id,
      title: organizationProps.details.name,
      activityType: {
        value: organizationProps.details.industry?.id,
        label: organizationProps.details.industry?.name,
      },
      legalAddress: organizationProps.details.legalAddress,
      description: organizationProps.details.description,
      phoneNumber: organizationProps.details.phoneNumber,
      bin: organizationProps.details.taxId,
      website: organizationProps.details.website,
      avatar: organizationProps.details.logo?.viewLink,
      verified: organizationProps.details.isVerified,
      certificates: organizationProps.details.certificates.map(mapDocumentDaoToEntity),
    };
  }

  throw new Error('Не известный тип пользователя');
}
