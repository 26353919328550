import React from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import PRButton from 'components/PRButton';
import PRInput from 'components/PRInput';
import useHandleError from 'hooks/handleError';
import {
  resendResetPasswordCode,
  verifyResetPasswordCode,
} from 'api/repositories/users.repository';
import { ErrorResponse } from 'entities/Error';

import { useTimer } from '../../hooks/timer';

import cl from './second.module.scss';

interface Props {
  email: string;
  onSubmit: (val: string) => void;
}

const ResetSecondStep: React.FC<Props> = (props) => {
  const { t } = useTranslation();
  const { handleCommonError } = useHandleError();

  const { seconds, restartTimer } = useTimer();

  const {
    control,
    formState: { isSubmitting },
    handleSubmit,
  } = useForm({
    defaultValues: {
      code: '',
    },
  });

  const resendCode = () => {
    resendResetPasswordCode(props.email)
      .then(() => {
        restartTimer();
      })
      .catch((err: unknown) => {
        const error = err as ErrorResponse;
        handleCommonError(error);
      });
  };

  const onSubmit = async ({ code }: { code: string }) => {
    try {
      await verifyResetPasswordCode(props.email, code);
      props.onSubmit(code);
    } catch (err: unknown) {
      const error = err as ErrorResponse;
      handleCommonError(error);
    }
  };

  return (
    <form className={cl['second']} onSubmit={handleSubmit(onSubmit)}>
      <p className={cl['second__text']}>
        {t('message.codeForRecovery')} <span>{props.email}</span>.
      </p>
      <p className={cl['second__text']}>{t('message.enterCodeToAccessAccount')}:</p>

      <Controller
        control={control}
        name="code"
        rules={{
          required: { message: t('error.required'), value: true },
          minLength: { message: t('error.minLength', { len: 6 }), value: 6 },
        }}
        render={({ field: { onChange, onBlur, value, name, ref }, fieldState: { error } }) => (
          <PRInput
            label={t('form.code')}
            ref={ref}
            name={name}
            value={value}
            error={error?.message}
            required
            onChange={onChange}
            onBlur={onBlur}
          />
        )}
      />

      {seconds > 0 ? (
        <p className={cl['second__resend']}>{t('message.resendCodeAfter', { seconds })}</p>
      ) : (
        <PRButton variant="text" onClick={resendCode}>
          {t('action.resendActivationCode')}
        </PRButton>
      )}

      <div className={cl['second__button']}>
        <PRButton loading={isSubmitting} type="submit">
          {t('action.continue')}
        </PRButton>
      </div>
    </form>
  );
};

export default ResetSecondStep;
