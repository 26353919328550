export default {
  signTo: 'Кіру',
  signUp: 'Жеке кабинет құру',
  passwordHelper: 'Кемінде 8 таңбадан, 1 көп жазба, 1 санды құпия сөз.',
  lastUpdate: 'Соңғы жаңарту: {{date}}',
  tradingAgency: 'Жалпы жетекшілік шарттарына сәйкесті дайындайтын аймақтық қойымдасушы.',
  saleOfPoint: 'Тауардың белгіленген сатушының аймағы.',
  tradingHouse: 'Өндірушінің бағдарламасына сәйкесті тауарды жалпы аймаққа жасау жөніндегі атау.',
  uploadPresentationOrFile:
    'Қажет болса, қосымша құжаттарды, презентацияны немесе файлдарды жүктеу.',
  usingForSEO: 'SEO үшін пайдаланылады және іздеу машинасында тауарды қосуға көмектеседі.',
  noBranchesYet: 'Сізде әлі де филиалдар жоқ',
  branchBenefits:
    'Филиалдар тізімі сайтта деректерді көбейту мен қарау мақсатында ықтималдарды көбейтеді және басқа компаниялардың филиалдарын көру мен байланыстыру мүмкіндігін береді.',
  activationCodeSend:
    'Сіздің поштаңызға тіркелу үшін код жіберілді, тіркелуді аяқтау үшін кодты жазыңыз.',
  resendCodeAfter: '{{seconds}} секундтан кейін кодты қайта жіберу',
  codeSent: 'Код жіберілді',
  allDataWillBeDeleted: 'Аккаунттың барлық деректері алаңдылдынымен жойылады',
  changePasswordImproveAccount: 'Аккаунттың қауіпсіздігін арттыру мақсатында парольді жаңарту',
  writeEmailForRecovery: 'Парақшаңызга тіркелген электрондық пошта мекен-жайын енгізіңіз.',
  codeForRecovery: 'Құпия сөзді қалпына келтіру коды поштаңызға жіберілді',
  enterCodeToAccessAccount: 'Аккаунтқа қабілетті кіру үшін кодты жазыңыз',
  createStrongPassword: 'Аккаунттың үшін құатты құпия сөз жасаңыз',
  infoAboutYourCompany: 'Сіздің компанияңыз туралы мәліметтерді енгізіңіз',
  iWantCatalogAndMakeSale: 'Менің каталогымды жариялау және тауарларды сату мүмкіндігім бар',
  iWantSearchManufacturers:
    'Мен партнёрлік келісімін жасау үшін өндірушілерді іздеу мүмкіндігім бар',
  tradingAgencyDescription:
    'Сауда агенттіктер: бұл басқа компаниялардың тікелей ұйымдарында басымды қойымдасушылық ісін орындау мақсатында құрылған ұйымдар. Олар қызметкерлерді табу, тауарларды қамтамасыз ету, договорлау жасау және іс-шараларды жасау мақсатында күнделікті жүргізе алады. Сауда агенттіктерінің мысалдары: қорытындыларды сату агенттіктері, автомобильдерді сату агенттіктері және т.б.',
  tradingHouseDescription:
    'Сауда үйлері: бұл бір жеткілікті тіркеуші компанияның жеткілікті басқа бір бұйрықты қолдана отырып, олардың өнімдерінің алдында бір жерге жиынтық түрде жасау мақсатында біріктіретін кәпір. Сауда үйі көп бөлімдер мен мамандандырылған бөлімдерді қосуы мүмкін, олар толық көлемді тауарлар немесе қызметтерді ұсынады. Сауда үйлерінің мысалдары: универмагтар, сауда моллары және т.б.',
  tradingPointDescription:
    'Сауда нүктелері: бұл тауарларды немесе қызметтерді тез тапсырудың орны. Бұл адамдардың тауарларды немесе қызметтерді сатуға болатын орнылар болуы мүмкін. Олар өтініштерді жасау немесе қызметтерді алу мүмкіндігіні көрсетеді. Сауда нүктелері өзгерістің барлықдарын жазуы мүмкін: мазарлар, супермаркеттер, базарлар, сауда боп майданчылары және т.б.',
};
