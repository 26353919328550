export default {
  main: 'Басты бет',
  loading: 'Жүктеу...',
  listEmpty: 'Тізім бос',
  attention: 'Ескерту',
  services: 'Қызметтер',
  applications: 'Қолжетімділіктер',
  partnership: 'Серіктестік',
  branches: 'Филиалдар',
  catalog: 'Каталог',
  faq: 'Жиі қойылатын сұраулар',
  companyData: 'Компания мәліметтері',
  employeeData: 'Тіркелгенді мәліметтері',
  accountActivation: 'Аккаунтты белсендіру',
  aboutPlatform: 'Платформа туралы',
  manageCompany: 'Компанияны басқару',
  tradingAgency: 'Сауда агенттігі',
  saleOfPoint: 'Сатылым нүктесі',
  tradingHouse: 'Сауда үйі',
  manufacturerName: 'Өндірушінің атауы',
  productName: 'Товар атауы',
  priceFrom: 'Баға: {{from}} теңге',
  aboutManufacturer: 'Өндіруші туралы',
  contacts: 'Байланыстар',
  manufacturers: 'Өндірушілер',
  summary: 'Күйік',
  certificates: 'Сертификаттар',
  yearsAgo: '{{num}} жыл бұрын',
  title: 'Тақырып',
  brandName: 'Бренд атауы',
  legalAddress: 'Қанағаттандыру қойымы',
  jurisdiction: 'Журисдикция',
  regNumberBin: 'Тіркеу нөмірі (БИН)',
  registrationDate: 'Тіркеу күні',
  sinceOnSite: 'Сайтта',
  product: 'Товар',
  vendorCode: 'Мамандық коды',
  date: 'Күні',
  display: 'Көрсету',
  inStock: 'Сатылымда',
  actions: 'Әрекеттер',
  required: 'Міндетті',
  optional: 'Қосымша',
  productList: 'Товар тізімі',
  barcode: 'Баркод немесе UPC/EAN',
  color: 'Түс',
  material: 'Материал',
  currency: 'Валюта',
  unitType: 'Үлгі бірлігі',
  status: 'Мәрекелістік',
  wholesalePrice: 'Опт таңбалау',
  from: 'дейін',
  mainInfo: 'Негізгі мәліметтер',
  productDescription: 'Товар сипаттамасы',
  dimensions: 'Өлшемдері',
  characteristics: 'Сипаттамалар',
  price: 'Баға',
  createAndUpdate: 'Жасау және жаңарту',
  created: 'Жасалды',
  updated: 'Жаңартылды',
  searchTerms: 'Іздеу сұраулары',
  supportedFormats: 'Қолдануға рұқсат етілген пішіндер: {{formats}}',
  maxSize: 'Ең көп өлшемі: {{size}}',
  images: 'Суреттер',
  agency: 'Агенттік',
  password: 'Құпия сөз',
  addingBranch: 'Филиалды қосу',
  questionAndAnswer: 'Сұраулар және жауаптар',
  profile: 'Профиль',
  settings: 'Параметрлер',
  requirementsForVerification: 'Тексеру үшін қойылуы керек шарттар',
  submitFollowingDocuments: 'Келесі жұмыстарды ұсыну',
  companyRegCertificate: 'Компания тіркеу сертификаты',
  companyCharter: 'Компания уставы',
  certificateTaxReg: 'Салық тіркеу сертификаты',
  productionDoc: 'Өнім документі',
  qualityCertificate: 'Сапа және сәйкестік сертификаттары',
  licensesAndCertificates: 'Лицензиялар және сертификаттар',
  suppliersAndCustomersContracts: 'Көлемі және клиенттермен келісімшарттар',
  accountDeleting: 'Аккаунтты жою',
  passwordChanging: 'Құпия сөзді өзгерту',
  today: 'бүгін',
  weight: 'Салмақ',
  availableOnOrder: 'Товар заказ арқылы жеткізіледі',
  manufacturerOtherProducts: 'Өндірушінің басқа товарлары',
  lastActivity: 'Соңғы әрекет',
  passwordRecovery: 'Құпия сөзді қалпына келтіру',
  forgotPassword: 'Құпия сөзді ұмыттыңыз ба?',
  haveNotAccount: 'Аккаунт жоқ па?',
  createPassword: 'Құпия сөзді жасау',
  registration: 'Тіркеу',
  haveAnAccount: 'Аккаунт бар ма?',
  iAmManufacturer: 'Мен өндірушімін',
  iAmUser: 'Мен пайдаланушымын',
  aboutCompany: 'Компания туралы',
  logo: 'Логотип',
  personalData: 'Жеке мәліметтер',
  notifications: 'Хабарландырулар',
  todayAt: 'Бүгін {{date}} күні',
  yesterdayAt: 'Кеше {{date}} күні',
  all: 'Барлық',
  system: 'Жүйелік',
  notSpecified: 'Анықталмаған',
};
