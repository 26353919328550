import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ReactComponent as CheckIcon } from 'assets/icons/check.svg';
import { ReactComponent as DoubleCheckIcon } from 'assets/icons/double-check.svg';
import { MESSAGE_STATUS } from 'types/constants/message-status.enum';
import { fetchConversations } from 'api/repositories/conversations.repository';
import { Conversation } from 'entities/Conversation';

import ConversationDetail from '../../components/ConversationDetail';

import cl from './messages.module.scss';

const Messages = () => {
  const { t } = useTranslation();
  const [conversations, setConversations] = useState<Conversation[]>([]);
  const [selectedConversation, setSelectedConversation] = useState<Conversation | null>(null);

  useEffect(() => {
    fetchConversations().then((res) => {
      setConversations(res.conversations);
    });
  }, []);

  const getDateTime = (date: Date) => {
    date = new Date(date);
    const today = new Date();

    if (today.toLocaleDateString() === date.toLocaleDateString()) {
      return date.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' });
    } else {
      return date.toLocaleDateString();
    }
  };

  return (
    <div className={cl['conversations']}>
      <div className={cl['conversations__list']}>
        {conversations.map((conversation) => (
          <div
            key={conversation.id}
            className={`${cl['contact']} ${
              selectedConversation?.id === conversation.id ? cl['contact--active'] : ''
            }`}
            onClick={() => setSelectedConversation(conversation)}
          >
            <div className={cl['contact__image']}>
              <img src={conversation.avatar} alt={conversation.title} />
            </div>
            <div className={cl['contact__body']}>
              <div className={cl['contact__header']}>
                <p className={cl['contact__name']}>{conversation.title}</p>
                <p className={cl['contact__date']}>
                  {getDateTime(conversation.lastMessage.createdAt)}
                </p>
              </div>
              <div className={cl['contact__footer']}>
                <p className={cl['contact__message']}>{conversation.lastMessage.message}</p>
                {conversation.newMessages === 0 ? (
                  conversation.lastMessage.status === MESSAGE_STATUS.SENT ? (
                    <CheckIcon />
                  ) : (
                    <DoubleCheckIcon
                      className={
                        conversation.lastMessage.status === MESSAGE_STATUS.READ ? cl['read'] : ''
                      }
                    />
                  )
                ) : (
                  <span className={cl['contact__new-messages']}>{conversation.newMessages}</span>
                )}
              </div>
            </div>
          </div>
        ))}
      </div>
      {selectedConversation ? (
        <ConversationDetail conversation={selectedConversation} />
      ) : (
        <div className={cl['conversations__empty']}>
          <div>{t('action.selectChat')}</div>
        </div>
      )}
    </div>
  );
};

export default Messages;
