import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { useNotification } from '../../hooks/notification';

import cl from './file-input.module.scss';

interface Props {
  format?: string;
  children: React.ReactNode;
  setFile: (file: File) => void;
}

const PRFileInput: React.FC<Props> = ({ format = '', children, setFile }) => {
  const { t } = useTranslation();
  const notify = useNotification();
  const [isDragOver, setDragOver] = useState(false);

  const onFileSelect = (e: React.ChangeEvent<HTMLInputElement>) => {
    const fs = Array.from(e.target.files || []);
    if (fs.length) {
      setFile(fs[0]);
    }
  };

  const onDrop = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    const fs = Array.from(e.dataTransfer.files);

    if (format) {
      const file = fs[0];
      const fileExtension = getFileExtension(file.name);
      const allowedExtensions = format.split(',').map((s) => s.trim());
      if (allowedExtensions.includes(fileExtension)) {
        setFile(file);
      } else {
        notify.error(t('error.incorrectFormat', { format }));
      }
    } else {
      setFile(fs[0]);
    }

    setDragOver(false);
  };

  const getFileExtension = (fileName: string) => {
    return fileName.slice(fileName.lastIndexOf('.')).toLowerCase();
  };

  const onDragOver = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    setDragOver(true);
  };

  const onDragLeave = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    setDragOver(false);
  };

  return (
    <div
      className={`${cl['file-input']} ${isDragOver ? cl['file-input--over'] : ''}`}
      onDrop={onDrop}
      onDragOver={onDragOver}
      onDragLeave={onDragLeave}
    >
      {children}
      <input type="file" accept={format} onChange={onFileSelect} />
    </div>
  );
};

export default PRFileInput;
