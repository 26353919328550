export default {
  submit: 'Юбориш',
  email: 'Эл. почта',
  password: 'Пароль',
  title: 'Номи',
  workingArea: 'Фаолият тури',
  country: 'Давлат',
  legalAddress: 'Ҳуқуқий манзил',
  bin: 'БИН',
  website: 'Веб-сайт',
  phoneNumber: 'Телефон рақами',
  fullName: 'Тўлиқ исм',
  position: 'Лавозим',
  firstName: 'Исм',
  lastName: 'Фамилия',
  patronymic: 'Отчество',
  confirmPassword: 'Тасдиқлаш',
  newPassword: 'Янги пароль',
  createAccount: 'Аккаунт яратиш',
  forgotPassword: 'Паролни унутдингизми?',
  city: 'Шаҳар',
  description: 'Тавсиф',
  category: 'Категория',
  subcategory: 'Подкатегория',
  region: 'Вилоят',
  industry: 'Санъат',
  showOtherCompanies: 'Менинг вилоятимда бўлмаган компанияларни кўрсатиш',
  companyName: 'Компания номи',
  cooperationPurpose: 'Ишбилармонлик мақсади',
  offer: 'Таклиф',
  expectedBenefits: 'Кутилаётган фойда',
  searchByIdTitle: 'Маҳсулот номи, ID ёки артикули бўйича қидириш',
  selectColumnName: 'Устун номини танланг',
  positionName: 'Позиция номи',
  width: 'Кенглиги, {{unit}}',
  height: ' Баландлиги, {{unit}}',
  length: 'Узунлиги, {{unit}}',
  weight: 'Вазни, {{unit}}',
  orderFrom: 'Буюртма қилинган миқдори',
  keyPhrases: 'Сизнинг маҳсулотингизни топувчи калит сўзларни киритинг',
  contactPerson: 'Боғланиш учун шахс',
  code: 'Код',
  street: 'Кўча',
  writeMessage: 'Хабарни киритинг',
  branchType: 'Ташкилот тури',
};
