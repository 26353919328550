import React from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import PRAsyncSelect from 'components/PRAsyncSelect';
import PRButton from 'components/PRButton';
import PRCheckbox from 'components/PRCheckbox';
import PRInput from 'components/PRInput';
import PRTitle from 'components/PRTitle';
import { FirstStep, registrationSlice } from 'store/registration';
import { EMAIL_REGEX } from 'types/constants/email.regex';
import { formatPhoneNumber } from 'utils/formatter.util';
import { useAppDispatch, useAppSelector } from 'hooks/redux';
import { fetchCountries, fetchWorkingAreas } from 'api/repositories/handbooks.repository';
import { checkEmailAvailability } from 'api/repositories/users.repository';
import { CountryOption, Option } from 'entities/Option';

import cl from './first-step.module.scss';

const RegistrationFirstStep = () => {
  const { t } = useTranslation();
  const { first } = useAppSelector((state) => state.registration);
  const dispatch = useAppDispatch();

  const {
    control,
    formState: { isSubmitting },
    handleSubmit,
    watch,
  } = useForm({ defaultValues: { ...first } });

  const country = watch('country');

  const onSubmit = (data: FirstStep) => {
    dispatch(registrationSlice.actions.setFirstStep(data));
  };

  return (
    <form className={cl['first-step']} onSubmit={handleSubmit(onSubmit)} noValidate>
      <PRTitle tag="h1">{t('message.signUp')}</PRTitle>

      <p className={cl['first-step__text']}>{t('message.infoAboutYourCompany')}</p>

      <div className={cl['first-step__two']}>
        <Controller
          control={control}
          name="companyName"
          rules={{ required: { message: t('error.required'), value: true } }}
          render={({ field: { onChange, onBlur, value, name, ref }, fieldState: { error } }) => (
            <PRInput
              ref={ref}
              label={t('form.title')}
              name={name}
              value={value}
              error={error?.message}
              required
              onChange={onChange}
              onBlur={onBlur}
            />
          )}
        />
        <Controller
          control={control}
          name="workingArea"
          rules={{ required: { message: t('error.required'), value: true } }}
          render={({ field: { onChange, onBlur, value }, fieldState: { error } }) => (
            <PRAsyncSelect
              value={value}
              label={t('form.industry')}
              fetchList={fetchWorkingAreas}
              error={error?.message}
              defaultOptions
              isClearable
              required
              onChange={(val) => onChange(val as Option)}
              onBlur={onBlur}
            />
          )}
        />
      </div>

      <div className={cl['first-step__two']}>
        <Controller
          control={control}
          name="country"
          rules={{ required: { message: t('error.required'), value: true } }}
          render={({ field: { onChange, onBlur, value }, fieldState: { error } }) => (
            <PRAsyncSelect
              value={value}
              label={t('form.country')}
              fetchList={fetchCountries}
              error={error?.message}
              searchParams={{ page_size: 250 }}
              defaultOptions
              isClearable
              required
              onChange={(val) => onChange(val as CountryOption)}
              onBlur={onBlur}
            />
          )}
        />

        <Controller
          control={control}
          name="bin"
          rules={{
            required: { message: t('error.required'), value: true },
            minLength: { message: t('error.minLength', { len: 12 }), value: 12 },
          }}
          render={({ field: { onChange, onBlur, value, name, ref }, fieldState: { error } }) => (
            <PRInput
              label={t('common.regNumberBin')}
              ref={ref}
              name={name}
              value={value}
              error={error?.message}
              maxLength={12}
              type="number"
              required
              onChange={onChange}
              onBlur={onBlur}
            />
          )}
        />
      </div>

      <Controller
        control={control}
        name="legalAddress"
        rules={{ required: { message: t('error.required'), value: true } }}
        render={({ field: { onChange, onBlur, value, name, ref }, fieldState: { error } }) => (
          <PRInput
            label={t('form.legalAddress')}
            ref={ref}
            name={name}
            value={value}
            error={error?.message}
            required
            onChange={onChange}
            onBlur={onBlur}
          />
        )}
      />

      <div className={cl['first-step__two']}>
        <Controller
          control={control}
          name="email"
          rules={{
            required: { message: t('error.required'), value: true },
            pattern: {
              message: t('error.invalidEmail'),
              value: EMAIL_REGEX,
            },
            validate: async (value) => {
              const isAccountExists = await checkEmailAvailability(value);
              if (isAccountExists) {
                return t('error.emailNotAvailable');
              }
            },
          }}
          render={({ field: { onChange, onBlur, value, name, ref }, fieldState: { error } }) => (
            <PRInput
              label={t('form.email')}
              ref={ref}
              name={name}
              value={value}
              error={error?.message}
              required
              onChange={onChange}
              onBlur={onBlur}
            />
          )}
        />
        <Controller
          control={control}
          name="phone"
          rules={{
            minLength: { message: t('error.phone'), value: 13 },
          }}
          render={({ field: { onChange, onBlur, value, name, ref }, fieldState: { error } }) => (
            <PRInput
              label={t('form.phoneNumber')}
              ref={ref}
              name={name}
              value={value}
              error={error?.message}
              prefix={
                <span className={cl['first-step__prefix']}>
                  {country ? country.phoneCode : '+7'}
                </span>
              }
              onChange={(val) => onChange(formatPhoneNumber(val))}
              onBlur={onBlur}
            />
          )}
        />
      </div>
      <div className={cl['first-step__agreement']}>
        <Controller
          control={control}
          name="isAgree"
          rules={{ required: { message: t('error.required'), value: true } }}
          render={({ field: { onChange, value, name, ref }, fieldState: { error } }) => (
            <PRCheckbox ref={ref} name={name} value={value} onChange={onChange}>
              <span className={error?.message ? cl['first-step__error'] : ''}>
                Я ознакомлен(а) и принимаю <Link to="#">условия обработки данных</Link>
              </span>
            </PRCheckbox>
          )}
        />
      </div>
      <div className={cl['first-step__submit']}>
        <PRButton loading={isSubmitting} type="submit">
          {t('action.next')}
        </PRButton>
      </div>
    </form>
  );
};

export default RegistrationFirstStep;
