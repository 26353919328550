import React, { useMemo } from 'react';

import cl from './auth-steps.module.scss';

interface Props {
  current: number;
  total: number;
}

const AuthSteps: React.FC<Props> = ({ current, total }) => {
  const items = useMemo(() => {
    const elements: JSX.Element[] = [];

    for (let i = 1; i <= total; i++) {
      elements.push(
        <div
          key={i}
          className={`${cl['auth-steps__item']} ${
            current === i ? cl['auth-steps__item--active'] : ''
          }`}
        />
      );
    }

    return elements;
  }, [current, total]);

  return (
    <div
      className={cl['auth-steps']}
      style={{ gridTemplateColumns: `repeat(${total}, minmax(0, 1fr)` }}
    >
      {items}
    </div>
  );
};

export default AuthSteps;
